import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  attachmentIdToThumbnail:{},
  folderIdToData:{},
  sheetVersionSetActiveFilters:{},
  sheetTypeActiveFilters:{},
  sheetDisciplineActiveFilters:{},
  sheetReviewStatusActiveFilters:{},
  sheetUpdatedByActiveFilters:{},
  savedAllIssuesTypeActiveFilters:{},
  savedAllIssuesStatusActiveFilters:{},
  savedAllIssuesBallInCourtActiveFilters:{},
  savedAllIssuesRootCauseActiveFilters:{},
  savedAllIssuesCreatedByActiveFilters:{},
  savedAssignedIssuesTypeActiveFilters:{},
  savedAssignedIssuesStatusActiveFilters:{},
  savedAssignedIssuesRootCauseActiveFilters:{},
  savedAssignedIssuesCreatedByActiveFilters:{},
  savedMyIssuesTypeActiveFilters:{},
  savedMyIssuesStatusActiveFilters:{},
  savedMyIssuesBallInCourtActiveFilters:{},
  savedMyIssuesRootCauseActiveFilters:{},
  savedAllFormsStatusActiveFilters:{},
  savedAllFormsCategoryActiveFilters:{},
  savedAllFormsTypeActiveFilters:{},
  savedAllFormsApprovedByActiveFilters:{},
  savedAllFormsProjectTemplateActiveFilters:{},
  savedAllFormsCreatedByActiveFilters:{},
  savedAssignedFormsStatusActiveFilters:{},
  savedAssignedFormsCategoryActiveFilters:{},
  savedAssignedFormsTypeActiveFilters:{},
  savedAssignedFormsProjectTemplateActiveFilters:{},
  savedAssignedFormsCreatedByActiveFilters:{},
}

export const appStateSlice = createSlice({
  name: "appState", 
  initialState,
  reducers: {
    setFolderIdToData:(state , action)=>{
      const {folderId , data } = action.payload;
      //console.log('setFolderIdToData ',folderId , data?.length);
      if(!state.folderIdToData){
        state.folderIdToData = {};
      }
      state.folderIdToData[folderId] = data;
    },
    setAttachmentIdToThumbnail:(state , action)=>{
      const {projectId , data } = action.payload;
      if(!state.attachmentIdToThumbnail){
        state.attachmentIdToThumbnail = {};
      }
      state.attachmentIdToThumbnail[projectId] = data ? data : {};
    },
    setSheetVersionSetActiveFilters:(state , action)=>{
      const {projectId , data } = action.payload;
      if(!state.sheetVersionSetActiveFilters){
        state.sheetVersionSetActiveFilters = {};
      }
      state.sheetVersionSetActiveFilters[projectId] = data;
    },
    setSheetTypeActiveFilters:(state , action)=>{
      const {projectId , data } = action.payload;
      if(!state.sheetTypeActiveFilters){
        state.sheetTypeActiveFilters = {};
      }
      state.sheetTypeActiveFilters[projectId] = data;
    },
    setSheetDisciplineActiveFilters:(state , action)=>{
      const {projectId , data } = action.payload;
      if(!state.sheetDisciplineActiveFilters){
        state.sheetDisciplineActiveFilters = {};
      }
      state.sheetDisciplineActiveFilters[projectId] = data;
    },
    setSheetReviewStatusActiveFilters:(state , action)=>{
      const {projectId , data } = action.payload;
      if(!state.sheetReviewStatusActiveFilters){
        state.sheetReviewStatusActiveFilters = {};
      }
      state.sheetReviewStatusActiveFilters[projectId] = data;
    },
    setSheetUpdatedByActiveFilters:(state , action)=>{
      const {projectId , data } = action.payload;
      if(!state.sheetUpdatedByActiveFilters){
        state.sheetUpdatedByActiveFilters = {};
      }
      state.sheetUpdatedByActiveFilters[projectId] = data;
    },
    setSavedAllIssuesTypeActiveFilters:(state , action)=>{
      const {projectId , data } = action.payload;
      if(!state.savedAllIssuesTypeActiveFilters){
        state.savedAllIssuesTypeActiveFilters = {};
      }
      state.savedAllIssuesTypeActiveFilters[projectId] = data;
    },
    setSavedAllIssuesStatusActiveFilters:(state , action)=>{
      const {projectId , data } = action.payload;
      if(!state.savedAllIssuesStatusActiveFilters){
        state.savedAllIssuesStatusActiveFilters = {};
      }
      state.savedAllIssuesStatusActiveFilters[projectId] = data;
    },
    setSavedAllIssuesBallInCourtActiveFilters:(state , action)=>{
      const {projectId , data } = action.payload;
      if(!state.savedAllIssuesBallInCourtActiveFilters){
        state.savedAllIssuesBallInCourtActiveFilters = {};
      }
      state.savedAllIssuesBallInCourtActiveFilters[projectId] = data;
    },
    setSavedAllIssuesRootCauseActiveFilters:(state , action)=>{
      const {projectId , data } = action.payload;
      if(!state.savedAllIssuesRootCauseActiveFilters){
        state.savedAllIssuesRootCauseActiveFilters = {};
      }
      state.savedAllIssuesRootCauseActiveFilters[projectId] = data;
    },
    setSavedAllIssuesCreatedByActiveFilters:(state , action)=>{
      const {projectId , data } = action.payload;
      if(!state.savedAllIssuesCreatedByActiveFilters){
        state.savedAllIssuesCreatedByActiveFilters = {};
      }
      state.savedAllIssuesCreatedByActiveFilters[projectId] = data;
    },


    setSavedAssignedIssuesTypeActiveFilters:(state , action)=>{
      const {projectId , data } = action.payload;
      if(!state.savedAssignedIssuesTypeActiveFilters){
        state.savedAssignedIssuesTypeActiveFilters = {};
      }
      state.savedAssignedIssuesTypeActiveFilters[projectId] = data;
    },
    setSavedAssignedIssuesStatusActiveFilters:(state , action)=>{
      const {projectId , data } = action.payload;
      if(!state.savedAssignedIssuesStatusActiveFilters){
        state.savedAssignedIssuesStatusActiveFilters = {};
      }
      state.savedAssignedIssuesStatusActiveFilters[projectId] = data;
    },
    setSavedAssignedIssuesRootCauseActiveFilters:(state , action)=>{
      const {projectId , data } = action.payload;
      if(!state.savedAssignedIssuesRootCauseActiveFilters){
        state.savedAssignedIssuesRootCauseActiveFilters = {};
      }
      state.savedAssignedIssuesRootCauseActiveFilters[projectId] = data;
    },
    setSavedAssignedIssuesCreatedByActiveFilters:(state , action)=>{
      const {projectId , data } = action.payload;
      if(!state.savedAssignedIssuesCreatedByActiveFilters){
        state.savedAssignedIssuesCreatedByActiveFilters = {};
      }
      state.savedAssignedIssuesCreatedByActiveFilters[projectId] = data;
    },
    setSavedMyIssuesTypeActiveFilters:(state , action)=>{
      const {projectId , data } = action.payload;
      if(!state.savedMyIssuesTypeActiveFilters){
        state.savedMyIssuesTypeActiveFilters = {};
      }
      state.savedMyIssuesTypeActiveFilters[projectId] = data;
    },
    setSavedMyIssuesStatusActiveFilters:(state , action)=>{
      const {projectId , data } = action.payload;
      if(!state.savedMyIssuesStatusActiveFilters){
        state.savedMyIssuesStatusActiveFilters = {};
      }
      state.savedMyIssuesStatusActiveFilters[projectId] = data;
    },
    setSavedMyIssuesBallInCourtActiveFilters:(state , action)=>{
      const {projectId , data } = action.payload;
      if(!state.savedMyIssuesBallInCourtActiveFilters){
        state.savedMyIssuesBallInCourtActiveFilters = {};
      }
      state.savedMyIssuesBallInCourtActiveFilters[projectId] = data;
    },
    setSavedMyIssuesRootCauseActiveFilters:(state , action)=>{
      const {projectId , data } = action.payload;
      if(!state.savedMyIssuesRootCauseActiveFilters){
        state.savedMyIssuesRootCauseActiveFilters = {};
      }
      state.savedMyIssuesRootCauseActiveFilters[projectId] = data;
    },
    setSavedAllFormsCreatedByActiveFilters:(state , action)=>{
      const {projectId , data } = action.payload;
      if(!state.savedAllFormsCreatedByActiveFilters){
        state.savedAllFormsCreatedByActiveFilters = {};
      }
      state.savedAllFormsCreatedByActiveFilters[projectId] = data;
    },
    setSavedAllFormsProjectTemplateActiveFilters:(state , action)=>{
      const {projectId , data } = action.payload;
      if(!state.savedAllFormsProjectTemplateActiveFilters){
        state.savedAllFormsProjectTemplateActiveFilters = {};
      }
      state.savedAllFormsProjectTemplateActiveFilters[projectId] = data;
    },
    setSavedAllFormsApprovedByActiveFilters:(state , action)=>{
      const {projectId , data } = action.payload;
      if(!state.savedAllFormsApprovedByActiveFilters){
        state.savedAllFormsApprovedByActiveFilters = {};
      }
      state.savedAllFormsApprovedByActiveFilters[projectId] = data;
    },
    setSavedAllFormsTypeActiveFilters:(state , action)=>{
      const {projectId , data } = action.payload;
      if(!state.savedAllFormsTypeActiveFilters){
        state.savedAllFormsTypeActiveFilters = {};
      }
      state.savedAllFormsTypeActiveFilters[projectId] = data;
    },
    setSavedAllFormsCategoryActiveFilters:(state , action)=>{
      const {projectId , data } = action.payload;
      if(!state.savedAllFormsCategoryActiveFilters){
        state.savedAllFormsCategoryActiveFilters = {};
      }
      state.savedAllFormsCategoryActiveFilters[projectId] = data;
    },
    setSavedAllFormsStatusActiveFilters:(state , action)=>{
      const {projectId , data } = action.payload;
      if(!state.savedAllFormsStatusActiveFilters){
        state.savedAllFormsStatusActiveFilters = {};
      }
      state.savedAllFormsStatusActiveFilters[projectId] = data;
    },
    setSavedAssignedFormsCreatedByActiveFilters:(state , action)=>{
      const {projectId , data } = action.payload;
      if(!state.savedAssignedFormsCreatedByActiveFilters){
        state.savedAssignedFormsCreatedByActiveFilters = {};
      }
      state.savedAssignedFormsCreatedByActiveFilters[projectId] = data;
    },
    setSavedAssignedFormsProjectTemplateActiveFilters:(state , action)=>{
      const {projectId , data } = action.payload;
      if(!state.savedAssignedFormsProjectTemplateActiveFilters){
        state.savedAssignedFormsProjectTemplateActiveFilters = {};
      }
      state.savedAssignedFormsProjectTemplateActiveFilters[projectId] = data;
    },
    setSavedAssignedFormsTypeActiveFilters:(state , action)=>{
      const {projectId , data } = action.payload;
      if(!state.savedAssignedFormsTypeActiveFilters){
        state.savedAssignedFormsTypeActiveFilters = {};
      }
      state.savedAssignedFormsTypeActiveFilters[projectId] = data;
    },
    setSavedAssignedFormsCategoryActiveFilters:(state , action)=>{
      const {projectId , data } = action.payload;
      if(!state.savedAssignedFormsCategoryActiveFilters){
        state.savedAssignedFormsCategoryActiveFilters = {};
      }
      state.savedAssignedFormsCategoryActiveFilters[projectId] = data;
    },
    setSavedAssignedFormsStatusActiveFilters:(state , action)=>{
      const {projectId , data } = action.payload;
      if(!state.savedAssignedFormsStatusActiveFilters){
        state.savedAssignedFormsStatusActiveFilters = {};
      }
      state.savedAssignedFormsStatusActiveFilters[projectId] = data;
    },
  }
})

export const {  setFolderIdToData,
                setAttachmentIdToThumbnail,
                setSheetVersionSetActiveFilters,
                setSheetTypeActiveFilters,
                setSheetDisciplineActiveFilters,
                setSheetReviewStatusActiveFilters,
                setSheetUpdatedByActiveFilters,
                setSavedAllIssuesTypeActiveFilters,
                setSavedAllIssuesStatusActiveFilters,
                setSavedAllIssuesBallInCourtActiveFilters,
                setSavedAllIssuesRootCauseActiveFilters,
                setSavedAllIssuesCreatedByActiveFilters,
                setSavedAssignedIssuesTypeActiveFilters,
                setSavedAssignedIssuesStatusActiveFilters,
                setSavedAssignedIssuesRootCauseActiveFilters,
                setSavedAssignedIssuesCreatedByActiveFilters,
                setSavedMyIssuesTypeActiveFilters,
                setSavedMyIssuesStatusActiveFilters,
                setSavedMyIssuesBallInCourtActiveFilters,
                setSavedMyIssuesRootCauseActiveFilters,
                setSavedAllFormsStatusActiveFilters,
                setSavedAllFormsCategoryActiveFilters,
                setSavedAllFormsTypeActiveFilters,
                setSavedAllFormsApprovedByActiveFilters,
                setSavedAllFormsProjectTemplateActiveFilters,
                setSavedAllFormsCreatedByActiveFilters,
                setSavedAssignedFormsStatusActiveFilters,
                setSavedAssignedFormsCategoryActiveFilters,
                setSavedAssignedFormsTypeActiveFilters,
                setSavedAssignedFormsProjectTemplateActiveFilters,
                setSavedAssignedFormsCreatedByActiveFilters
               } = appStateSlice.actions

export default appStateSlice.reducer
