import React, { useEffect, useState } from "react";
import { TreeView } from '@mui/x-tree-view/TreeView';
import { ArrowDropDown, ArrowRight } from "@mui/icons-material";
import { Button, Tooltip } from "@mui/material";
import StyledTreeItem from "./StyledTreeItem";
import newFolder from "assets/images/newFolder.svg";
import Add from "assets/images/add.svg";
import global from "./global.js";

export default function FolderView(props) {
  const {setConfirmDialog} = props;
  const {_setChildrenData,data,_getNodeChildren,folderLoader,expandedFolderIds,} = props?.sectionHook;

  const renderTree = (nodes, section = "") => (
    <div key={nodes.value.Id} className="main-wrapp-sidebar">
        <StyledTreeItem
          key={nodes.value.Id}
          nodeId={nodes.value.Id}
          className={section}
          labelText={nodes?.value?.Name}
          bgColor="#e8f0fe"
          classStyle={ props?.currentFolderName?.id === nodes.value.Id ? "folder_list-LastSelect" : "folder_list" }
        >
        {Array.isArray(nodes.children) && nodes.children.map((node) => renderTree(node, "children")) }
      </StyledTreeItem>
    </div>
  );
  const handleAddNewFolder = () => {
    setConfirmDialog("New Folder");
  };
  return (
    <>
      <div className="Folders">
        <div className="Folders_head">
          <div>Folders</div>
          <Tooltip title="Add New Folder" placement="top" PopperProps={{ className: "version-action-tooltips" }}>
          <div className="img" onClick={()=> handleAddNewFolder()} style={{cursor: "pointer"}}>
            <img src={Add} alt="addICon" />
          </div>
          </Tooltip>
        </div>
        {props?.currentFolderName?.name && !folderLoader ? (
          <div className="Folders_body">
            <TreeView
              aria-label="gmail"
              defaultExpanded={expandedFolderIds}
              defaultSelected={[props?.currentFolderName?.id]}
              defaultCollapseIcon={<ArrowDropDown />}
              defaultExpandIcon={<ArrowRight />}
              defaultEndIcon={<div style={{ width: 24 }} />}
              sx={{ maxWidth: 400, overflowY: "auto" }}
              onNodeSelect={(e, val) => {
                //console.log("val ::", val);
                const DATA = _getNodeChildren(data, val);
                props?.findFolderPath(data, DATA?.value?.Id);
                global.folderId = DATA?.value?.Id;
                _setChildrenData(DATA?.children);
                props?.setCurrentFolderName({
                  parentId: DATA?.value?.Parent_Folder__c,
                  id: DATA?.value?.Id,
                  name: DATA?.value?.Name,
                  type: DATA?.value?.Folder_Type__c,
                }); 
              }}
            >
              {data?.length > 0 &&
                data?.map((val, index) => {
                  return renderTree(val);
                })
              }
            </TreeView>
          </div>
        ) : (
          <div className="Folders_body_noFolder_head">
            <div className="Folders_body_NoFolder">No folders created</div>
            <div className="Folders_body_NoFolder_1">
              Please click the button below to create a folder
            </div>
            <div className="Folders_body_NoFolder_2">
              <Button className="Folders_body_NoFolder_Button">
                <img
                  className="Folders_body_NoFolder_Button-img"
                  src={newFolder}
                  alt="newFolder_Icon"
                />{" "}
                Create Folder
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
