import React, { useState, useEffect, useRef } from "react";
import CloseIcon from "../../assets/images/close.svg";
import doubleNext from "../../assets/images/double_arrow_right.svg";
import doublePrevious from "../../assets/images/double_arrow_left.svg";

const DocumentNavigation = (props) => {
    const { currentDoc, currentViewFiles, thumbnailMap, onSelect, isSync} = props;
    const [fileInd, setFileIndState] = useState(-1);
    const fileIndRef = useRef(-1);
    const setFileInd = (state) => {
        fileIndRef.current = state;
        setFileIndState(state);
    };
    const [showThumbnailView, setShowThumbnailView] = useState(false);
    const showThumbnailRef = useRef(showThumbnailView);
    const [isNextEnabled, setNextEnabled] = useState(false);
    const [isPreviousEnabled, setPreviousEnabled] = useState(false);
    const [viewList, setViewList] = useState([]);

    useEffect(() => {
        if (currentViewFiles?.length > 0 && currentDoc) {
            let ind = currentViewFiles.findIndex(t => t.id === currentDoc.id);
            setFileInd(ind);
        } else {
            setFileInd(-1);
        }
    }, [currentDoc, currentViewFiles]);

    useEffect(() => {
        let nextEna = false;
        let prevEna = false;
        if (fileInd > -1 && currentViewFiles?.length > 0) {
            nextEna = fileInd < (currentViewFiles?.length - 1);
            prevEna = fileInd > 0;
        }
        setNextEnabled(nextEna);
        setPreviousEnabled(prevEna);
    }, [fileInd]);

    useEffect(() => {
        let oldValue = showThumbnailRef.current;
        showThumbnailRef.current = showThumbnailView;
        if (showThumbnailView) {
            if (currentDoc?.id) {
                let ind = currentViewFiles.findIndex(t => t.id === currentDoc.id);
                if (oldValue === false) {
                    prepareCurrentViewList(ind);
                }
                setTimeout(() => {
                    let eleId = `navItem_${currentDoc.id}`;
                    let foundElement = document.getElementById(eleId);
                    if (foundElement) {
                        foundElement.scrollIntoView({ behavior: oldValue ? 'smooth' : 'auto', inline: 'center' });
                    }
                }, 30);
            }
        }else{
            setViewList([]);
            setFirstLoadedIndex(0);
            setLastLoadedIndex(0);
        }
    }, [currentDoc?.id, showThumbnailView]);

    const prepareCurrentViewList = (fileInd = fileIndRef.current) => {
        if (fileInd > -1) {
            const totalItems = currentViewFiles?.length;
            const maxViewItems = 50;
            let start = Math.max(fileInd - 24, 0);
            let end = Math.min(fileInd + 26, totalItems);
            //console.log( '---1 ::' , fileInd, start , end );
            if (totalItems <= maxViewItems) {
                setViewList(currentViewFiles);
                setFirstLoadedIndex(0);
                setLastLoadedIndex(currentViewFiles?.length -1);
                //console.log( '---2 ::' , fileInd, start , currentViewFiles?.length -1 );
            } else if (fileInd <= 24) {
                start = 0;
                end = Math.min(maxViewItems, totalItems);
                setViewList(currentViewFiles.slice(start, end));
                setFirstLoadedIndex(start);
                setLastLoadedIndex(end-1);
                //console.log( '---3 ::' , fileInd, start , end );
            } else if (fileInd >= (totalItems - 26)) {
                start = Math.max(totalItems - maxViewItems, 0);
                end = totalItems;
                setViewList(currentViewFiles.slice(start, end));
                setFirstLoadedIndex(start);
                setLastLoadedIndex(end-1);
                //console.log( '---4 ::' , fileInd, start , end );
            } else {
                setViewList(currentViewFiles.slice(start, end));
                setFirstLoadedIndex(start);
                setLastLoadedIndex(end-1);
            }
        } else {
            setViewList([]);
        }
    };
    const prevObserverRef = useRef(null);
    const [firstLoadedIndex, setFirstLoadedIndex] = useState(0);
    const nextObserverRef = useRef(null);
    const [lastLoadedIndex, setLastLoadedIndex] = useState(0);


    useEffect(() => {
        if(false){
            if (nextObserverRef.current) nextObserverRef.current.disconnect();
            nextObserverRef.current = new IntersectionObserver(entries => {
                if (entries[0].isIntersecting) {
                    loadMoreNextItems();
                }
            });
            if (document.querySelector('.naviThumbnail-item:last-child')) {
                nextObserverRef.current.observe(document.querySelector('.naviThumbnail-item:last-child'));
            }
    
            if (prevObserverRef.current) prevObserverRef.current.disconnect();
            prevObserverRef.current = new IntersectionObserver(entries => {
                if (entries[0].isIntersecting) {
                    loadMorePreviousItems();
                }
            });
            if (document.querySelector('.naviThumbnail-item:first-child')) {
                prevObserverRef.current.observe(document.querySelector('.naviThumbnail-item:first-child'));
            }
        }
        return () => {
            nextObserverRef.current && nextObserverRef.current.disconnect();
            prevObserverRef.current && prevObserverRef.current.disconnect();
        };
    }, [viewList , showThumbnailView]);
    const loadMorePreviousItems = () => {
        if (firstLoadedIndex <= 0) return;
        let start = Math.max(firstLoadedIndex - 25, 0);
        const newItems = currentViewFiles.slice(start, firstLoadedIndex);
        setViewList(prev => [...newItems, ...prev]);
        setFirstLoadedIndex(start);
    };
    const loadMoreNextItems = () => {
        const totalItems = currentViewFiles?.length;
        if (viewList.length >= totalItems || lastLoadedIndex >= totalItems - 1) return;
        let end = Math.min(lastLoadedIndex + 25, totalItems);
        const newItems = currentViewFiles.slice(lastLoadedIndex+1, end+1);
        setViewList(prev => [...prev, ...newItems]);
        console.log('next end ',end);
        setLastLoadedIndex(end-1);
    };
    return (
        <>
            {!showThumbnailView &&
                <div className="navigation-container">
                    <div title="Previous Document" className={`zoom-button${!isPreviousEnabled ? ' click-disabled' : ''}`}
                        onClick={() => {
                            if (!isPreviousEnabled) {
                                return;
                            }
                            onSelect(currentViewFiles[fileIndRef.current - 1]);
                        }}>
                        <img src="/left.svg" alt="Previous Sheet" width={"16px"} height={"16px"} />
                    </div>
                    <div className="zoom-button" onClick={() => { setShowThumbnailView(true) }}>
                        <img src="/thumbnail.svg" alt="Thumbnail View" />
                    </div>
                    <div title="Next Document" className={`zoom-button${!isNextEnabled ? ' click-disabled' : ''}`}
                        onClick={() => {
                            if (!isNextEnabled) {
                                return;
                            }
                            onSelect(currentViewFiles[fileIndRef.current + 1]);
                        }}>
                        <img src="/right.svg" alt="Next Sheet" width={"16px"} height={"16px"} />
                    </div>
                </div>
            }
            {showThumbnailView &&
                <>
                    <div className="naviThumbnail-layout">
                        <div className="sheet-view-navigation">
                            <div className="sheets-selected-and-info body-regular">
                                <p className="sheets-selected-and-info-item-1">{currentDoc?.Folder__r?.Folder_Type__c === 'Plan' ? 'Sheet' : 'File'}</p>
                                <div className="current-sheet-number">{currentDoc?.Folder__r?.Name} {viewList?.length}</div>
                            </div>
                            <div style={{ display: 'flex', gap: '15px' }}>
                               
                                <div title="Previous Document" className={!isPreviousEnabled ? 'click-disabled' : ''}
                                    onClick={() => {
                                        if (!isPreviousEnabled) {
                                            return;
                                        }
                                        onSelect(currentViewFiles[fileIndRef.current - 1]);
                                    }}>
                                    <img src="/left.svg" alt="Previous Sheet" width={"16px"} height={"16px"} />
                                </div>
                                <div title="Next Document" className={!isNextEnabled ? 'click-disabled' : ''}
                                    onClick={() => {
                                        if (!isNextEnabled) {
                                            return;
                                        }
                                        onSelect(currentViewFiles[fileIndRef.current + 1]);
                                    }}>
                                    <img src="/right.svg" alt="Next Sheet" />
                                </div>
                                
                                <div style={{ cursor: 'pointer' }} onClick={() => { setShowThumbnailView(false) }}>
                                    <img src={CloseIcon} alt="close Thumbnail view" />
                                </div>
                            </div>
                        </div>
                        <div className='naviThumbnail-container'>
                            {viewList?.length > 0 &&
                                viewList.map((att,ind )=> (
                                    <div key={att.id + '-'+ind} id={'navItem_' + att.id} className={`naviThumbnail-item${att.id === currentDoc?.id ? ' thumb-item-current' : ''}`} onClick={() => {
                                        if (att.id !== currentDoc?.id) {
                                            onSelect(att);
                                        }
                                    }}>
                                        <div>
                                            <img id={att.id + '_thumb_img'}
                                                className='naviThumbnail-img'
                                                src={att.thumbnail ? att.thumbnail : (thumbnailMap && thumbnailMap[att.id] ? thumbnailMap[att.id] : "icon.svg")}
                                                alt="loading_pdf"
                                                onError={(e) => { e.target.src = "icon.svg" }}
                                            />
                                        </div>
                                        <div className="naviThumbnail-sheetName">
                                            {att.name}
                                        </div>
                                    </div>
                                ))
                            }
                            { !(firstLoadedIndex <= 0) && 
                                <div title="Load Previous" className='nav-side-overlay-button nav-left'onClick={() => {
                                    loadMorePreviousItems();
                                }}>
                                    <img src="/left.svg" alt="loadPrevious" />
                                </div>
                            }
                            { !(viewList.length >= currentViewFiles?.length || lastLoadedIndex >= currentViewFiles?.length - 1) && 
                                <div title="Load Next More" className='nav-side-overlay-button nav-right' onClick={() => {
                                    loadMoreNextItems();
                                }}>
                                    <img src="/right.svg" alt="loadNext"/>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="outer-div" onClick={() => setShowThumbnailView(false)}></div>
                </>
            }
        </>
    );
}

export default DocumentNavigation;