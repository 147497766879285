/* eslint-disable no-loop-func */
import React, { useState, useEffect, useRef } from "react";
import _, { cloneDeep } from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  TextareaAutosize,
  Typography,
  Tooltip,
  InputAdornment
} from "@mui/material";
import Spinner from "components/spinner";
import { Folder, Search } from "@mui/icons-material";
import CheckIconSVG from "../../assets/images/check.svg";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { styled } from "@mui/material/styles";
import { getLocalStorage , saveLocalStorage } from "api";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import DeleteRedIcon from "../../assets/images/deletered.svg";
import LeftArrowIcon from "../../assets/images/left-close.svg";
import UploadIcon from "../../assets/images/upload.svg";
import PdfIcon from "../../assets/images/pdf.svg";
import CloseIcon from "../../assets/images/close.svg";
import DeleteIcon from "../../assets/images/delete.svg";
import ResetIcon from "../../assets/images/reset.svg";
import CheckIcon from "../../assets/images/check.svg";
import ErrorIcon from "../../assets/images/error.svg";
import { useDropzone } from "react-dropzone";
import { DataGrid } from "@mui/x-data-grid";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import { useNavigate, useLocation } from "react-router-dom";
import AddSheetComponentStyles from "./AddSheetStyles";
import ExtractNumber from "./ExtractNumber";
import { useSelector } from "react-redux";
import axios from "axios";
import { fetchUrl, webSocketListener } from "Urls";
import SaveIcon from "../../assets/images/save.svg";
import AddSheetIcon from "../../assets/images/Add_Sheet.svg";
import SettingIcon from "../../assets/images/settings.svg";
import WebViewer from '@pdftron/webviewer';
import Loader from "components/Loader";
import { GridFooterContainer, GridPagination } from '@mui/x-data-grid';
import { convertImageBase64ToBlob, getLowerqualityThumbnailBase64 } from "components/SheetsComponent";
import DisciplineDialog, {
  DefineSheetNumberDialog,
  SaveDraftDialog,
  ConfirmDialog
} from "./DisciplineSettingDialog";
import { APRYSE_L_KEY } from "api";
//export const APRYSE_L_KEY = "demo:1686132071599:7d8c2eda0300000000214f6453551f636df9577d4599254bac5bab67c7";
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 4,
  width: 180,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#346EF5" : "#308fe8",
  },
}));

const steps = [
  {
    label: "Upload Files",
    description: `In Progress`,
  },
  {
    label: "Set Name",
    description: "In Progress",
  },
  {
    label: "Sheet Number",
    description: `In Progress`,
  },
  {
    label: "Title and Discipline",
    description: `In Progress`,
  },
];
const stepToDetail = {
  0: { title: "Upload Files", nextLabel: "Version Set" },
  1: { title: "Version Set", nextLabel: "Sheet Number", backLabel: "Upload Files" },
  2: { title: "Sheet Number", nextLabel: "Title and Discipline", backLabel: "Version Set" },
  3: { title: "Title and Discipline", nextLabel: "Add Sheet", backLabel: "Sheet Number" }
};
const rows = [
  { id: 1, file: 1, image: "Snow", title: "FA-100.00", delete: true },
  { id: 2, file: 2, image: "Lannister", title: "FA-101.00", delete: true },
  { id: 3, file: 3, image: "Lannister", title: "FA-102.00", delete: true },
];
const t_shopdrawingtype = ['Value 1', 'Shop 2', 'House 6'];
const t_set = [{ label: 'First Set', value: 'a4aDb000000VWH9IAO', key: 'a4aDb000000VWH9IAO' },
{ label: 'Sunday Set', value: 'a4aDb000000VYVmIAO', key: 'a4aDb000000VYVmIAO' },
{ label: 'New First Setg', value: 'a4aDb000000VpI8IAK', key: 'a4aDb000000VpI8IAK' },
{ label: 'Set As New', value: 'a4aDb000000VYSiIAO', key: 'a4aDb000000VYSiIAO' }];
const furl = `https://horsepower-dev.s3.amazonaws.com//a0DDb00000H9hzmMAB/a0DDb00000H9hzrMAB/5%20Pages%20document%20Sep%2019%20%2C2023.pdf?AWSAccessKeyId=AKIA6FO5IYHIJDDKUAVC&Expires=1695715323&Signature=u98Vbrnf9%2FfdR%2Fxu8CST9TmBxcQ%3D`;
const s3_url = 'https://horsepower-dev.s3.amazonaws.com//a0DDb00000H9hzmMAB/a0DDb00000H9hzrMAB/5%20Pages%20document%20Sep%2019%20%2C2023.pdf'
//const furl = `https://horsepower-dev.s3.amazonaws.com//a0DDb00000GqVFxMAN/a0DDb00000GqVFyMAN/7%20Pages%20document.pdf?AWSAccessKeyId=AKIA6FO5IYHIJDDKUAVC&Expires=1691822535&Signature=%2FxG1xwiMCyoFWddBq8xP6eXgxFU%3D` ;
const Contract_Folder_Name = 'Contract Drawings';
const Shop_Folder_Name = 'Shop Drawings';
const initialDisciplineRows = [
  { id: 0, Designator__c: "A", Name: "Architectural", isNewRow: false },
  { id: 1, Designator__c: "B", Name: "Architectural 0", isNewRow: false },
  { id: 2, Designator__c: "C", Name: "Architectural 1", isNewRow: false },
  { id: 3, Designator__c: "D", Name: "Architectural 2", isNewRow: false },
  { id: 4, Designator__c: "E", Name: "Architectural 3", isNewRow: false },
  { id: 5, Designator__c: "F", Name: "Architectural 4", isNewRow: false },
];
function AddSheetsComponent() {
  const { userData = {} } = useSelector((state) => state.userData);
  const navigate = useNavigate();
  const location = useLocation();
  const [bState, setBrowserState] = useState(location?.state);
  const [activeStep, setActiveStep] = useState(0);
  const [projectId, setProjectId] = useState(userData?.projectId);
  const [credential, setCredential] = useState(userData);
  const [isLoading, setLoading] = useState(false);
  const [isLoadingTable, setLoadingTable] = useState(false);
  const [isBackgroundProcess, setBackgroundProcess] = useState(false);
  const [sourceFolderDetail, setSourceFolder] = useState(); //{id:'a0DDb00000H9hzrMAB'}
  const [disciplineList, setDisciplineList] = useState([]); // initialDisciplineRows
  const [disciplineOptions, setDisciplineOptions] = useState([]);
  const [disciplineMap, setDisciplineMap] = useState({});
  const [selectedAttach, setSelectedAttach] = useState([]); //[{attachId : 'a0JDb000003W6S8MAK ',url:furl ,s3Url : s3_url }]
  const [activeNextStep, setActiveNextButton] = useState(false);
  const [dateValue, setDateValue] = useState(null);
  const [uploadStatus, setUploadStatus] = useState([]);
  const [setAction, setSetAction] = useState("");
  const [newSet, setNewSet] = useState(null);
  const [stopCheckingError, setCheckingError] = useState(true);
  const [extractNumber, setExtractNumber] = useState(false);
  const [fileSplitRows, setFileSplitRows] = useState([]);//rows
  const selectedAttachRef = useRef(null);
  const fileSplitRowsRef = useRef(null);
  const disciplineIds = useRef(null);
  const [errorFiles, setErrorFiles] = useState([]);//rows
  const [errorDialog, setErrorDialog] = useState(false);
  const [extitingSet, setExtitingSet] = useState(false);
  const [inputId, setInputId] = useState(false);
  const [cropLabel, setCropLabel] = useState({ titleCropBox: {}, numberCropBox: {} });
  const [saveAsDraftDialog, setSaveAsDraftDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [disciplineSettingModal, setDisciplineSettingModal] = useState(false);
  const [defineSheetNumberDialog, setDefineSheetNumberDialog] = useState(false);
  //var newSetRef = useRef();
  const [selectedPDFRowIds, setSetSelectedPDFRowIds] = useState([]);
  const [pageIndexForExtraction, setPageIndexForExtraction] = useState([]);
  const [pdfRowErrors, setPdfRowErrors] = useState(null);
  const [newSetErrors, setNewSetErros] = useState(null);
  const [setId, setSetId] = useState('none'); // 'a4aDb000000VWH9IAO'
  const [shopDrawingValue, setShopDrawingValue] = useState('none');
  const [shopDrawingTypes, setShopDrawingTypes] = useState([]);//t_shopdrawingtype
  const [setOptions, setExistingSetOptions] = useState([]); //t_set 
  const { getRootProps, getInputProps, inputRef } = useDropzone({ onDrop, multiple: false, accept: { 'application/pdf': ['.pdf'] } });
  const [attach, setAttach] = useState(null);
  const [instance, setInstance] = useState();
  const [docViewIns, setViewIns] = useState();
  const [showErrorFiles, setShowErrorFiles] = useState();
  const [totalPageCounts, setPageCount] = useState(0);
  const [totalPageExtract, setTotalPageExtract] = useState(0);
  const [folderNameToIdMap, setSubFolderMap] = useState(null);
  const pdfContainerRef = useRef(null);
  const documentInstanceRef = useRef(null);
  const pdfContainerRef_step1 = useRef(null);
  const totalPageCountsRef = useRef(null);
  const confirmDialogData = {
    title: 'Delete Sheet',
    content: ' Are you sure you want to delete this sheet? This cannot be undone.',
    action1: "Cancel",
    action2: "Delete"
  };
  const [containerWidth, setContainerWidth] = useState(0);

  useEffect(() => {
    const updateWidth = () => {
      const container = document.getElementById('dataGridContainer');
      if (container) {
        setContainerWidth(container.offsetWidth);
      }
    };
    window.addEventListener('resize', updateWidth);
    updateWidth(); // Initial call to set width
    return () => window.removeEventListener('resize', updateWidth);
  }, [fileSplitRows, fileSplitRowsRef]);
  useEffect(() => {
    if (projectId != userData?.projectId && userData?.projectId) {
      setCredential(userData);
      setProjectId(userData?.projectId);
    }
  }, [userData?.projectId]);
  useEffect(() => {
    const storedSkipValue = getLocalStorage('defineSheetTutorialSkip');
    if (storedSkipValue === 'true' || userData?.defineSheetTutorialSkip) {
      setDefineSheetNumberDialog(false);
      saveLocalStorage('defineSheetTutorialSkip', 'true');
    } else {
      setDefineSheetNumberDialog(true);
    }
  }, [userData, extractNumber]);
  useEffect(() => {
    if (projectId) {
      setupInit('direct');
    }
  }, [projectId]);

  useEffect(() => {
    //console.log("!stopCheckingError",!stopCheckingError);
    if (stopCheckingError) {
      checkStepAndErros();
    } else {
      //console.log("setCheckingError3", stopCheckingError);
      setCheckingError(true);
    }
  }, [sourceFolderDetail, fileSplitRows, newSetErrors, setId, setAction, newSet, activeStep, selectedAttach, shopDrawingValue, fileSplitRowsRef]);

  const columns = [
    {
      field: "image",
      headerName: "File",
      width: containerWidth * 0.37,
      sortable: false,
      renderCell: (param) => {
        return (
          <>
            <div style={{ display: "flex", gap: "10px",width: '100%' }} className="file_area">
              {param?.value ? (
                <div >
                  <img src={param?.value} onClick={() => window.open(param?.value, '_blank')}  style={{width: '108px', height: '80px'}} alt=""/>
                </div>
              ) : (
                <div style={{ backgroundColor: "#F8F8F8" }}>
                  <img style={{ height: "50px", marginLeft: "5px" }} src="/icon.svg" alt="ddd" />
                  <div style={{ color: "black" }}>
                    Loading..
                  </div>
                </div>
              )
              }
              <div>
                <b style={{width: containerWidth * 0.32 - 108, overflow: 'hidden', textOverflow: 'ellipsis'}}>{selectedAttach[0]?.fileName}</b>
                Page {Number(param?.row?.pageNumber)}
              </div>
            </div>
          </>
        );
      },
    },
    {
      field: "number",
      headerName: "Sheet Number",
      width: containerWidth * 0.45,
      renderCell: (param) => {
        const errText = () => {
          let ret;
          if (pdfRowErrors && (pdfRowErrors?.empties?.includes(param?.id) || pdfRowErrors?.duplicates?.includes(param?.id))) {
            if (pdfRowErrors?.empties?.includes(param?.id)) {
              ret = 'Number cannot be empty';
            } else if (pdfRowErrors?.duplicates?.includes(param?.id)) {
              ret = 'Number is duplicate';
            }
          }
          if (!pdfRowErrors?.empties && !pdfRowErrors?.duplicates && !pdfRowErrors?.discipline_empties) {
            setShowErrorFiles(false);
          }
          if (ret) {
            return (
              <div style={{ fontSize: "12px" }}>
                <img style={{ marginRight: "5px" }} src="error-red.svg" alt="error" />{ret}
              </div>
            );
          }
        };
        return (
          <div style={{ display: "flex", gap: "12px", width: '100%' }} className={errText() ? "file_field-error" : "file_field"}>
            <div>
              {param?.row?.number_image ? (
                <img id={`numberImage-${param?.id}`}src={param?.row?.number_image} alt="" className='border-img' onClick={() => window.open(param?.row?.number_image, '_blank')} />
              ) : (
                <img src={AddSheetIcon} style={{width: '108px', height: '80px'}} alt=""/>
              )}
            </div>
            <div style={{ position: 'relative' }}>
              <TextareaAutosize
                id={`numberInput-${param?.id}`}
                style={{ height: "37px", width: containerWidth * 0.40 - 108 }}
                type="textarea"
                defaultValue={param?.value}
                onChange={(e) => {
                  setCheckingError(true);
                  let text = e?.target?.value;
                  if (text){
                   text = text.replace(/\s/g, '');
                  }
                  onSplitDataChange(param?.id, "number", text);
                  
                }}
                onKeyDown={(event) => {
                  if (event?.key?.toLowerCase() != "tab") {
                    event.stopPropagation();
                  }
                }}
                onBlur={(e) => {
                  setCheckingError(false);
                  let text = e?.target?.value;
                  if (text){
                   text = text.replace(/\s/g, '');
                  }
                  onSplitDataChange(param?.id, "number", text, true);
                }}
                onFocus={(e) => {
                  setCheckingError(false);
                  onFocusErrorFilter(param?.id, "number");
                }}
              />
              {errText() && (
                <div className="error" style={{ color: "red" }}>
                  {errText()}
                </div>
              )}
            </div>
          </div>
        );
      },
    },
    {
      field: "delete",
      headerName: "",
      width: containerWidth * 0.1,
      sortable: false,
      renderCell: (param) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
            <img onClick={(e) => {
              if (fileSplitRows.length != 1) {
                handleDeleteRow(param?.id)
              }
            }} src={fileSplitRows.length == 1 ? "delete_disable.svg" : "deleted.svg"} alt="delete" />
          </div>
        );

      },
    },
  ];
  const columnsLastStep = [
    {
      field: "image",
      headerName: "File",
      width: setAction == 'shop' ? containerWidth * 0.45 : containerWidth * 0.35,
      sortable: false,
      renderCell: (param) => {
        return (
          <>
            <div style={{ display: "flex", gap: "10px", width: '100%' }} className="file_area">
              {param?.value ? (
                <div >
                  <img src={param?.value} onClick={() => window.open(param?.value, '_blank')} style={{  width: '108px', height: '80px'}}/>
                </div>
              ) : (
                <div style={{ backgroundColor: "#F8F8F8" }}>
                  <img style={{ height: "80px", width: "108px", marginLeft: "5px" }} src="/icon.svg" alt="ddd" />
                  <div style={{ color: "black" , display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    Loading..
                  </div>
                </div>
              )
              }
              <div style={{ marginTop: "10px" }}>
                <b style={{width: (setAction == 'shop' ? containerWidth * 0.40 : containerWidth * 0.30 )- 108, overflow: 'hidden', textOverflow: 'ellipsis'}}>{param.row.number}</b>
                Page {Number(param?.row?.pageNumber)}
              </div>
            </div>
          </>
        );
      },
    },
    {
      field: "title",
      headerName: "Title",
      width: setAction == 'shop' ? containerWidth * 0.4 : containerWidth * 0.3,
      renderCell: (param) => {
        const errText = () => {
          let ret;
          if (pdfRowErrors && (pdfRowErrors?.empties?.includes(param?.id) || pdfRowErrors?.duplicates?.includes(param?.id))) {
            if (pdfRowErrors?.empties?.includes(param?.id)) {
              ret = 'Title cannot be empty';
            }
          }
          if (!pdfRowErrors?.empties && !pdfRowErrors?.duplicates && !pdfRowErrors?.discipline_empties) {
            setShowErrorFiles(false);
          }
          if (ret) {
            return (
              <div style={{ fontSize: "10px" }}>
                <img style={{ marginRight: "5px" }} src="error-red.svg" alt="error" />{ret}
              </div>
            );
          }
        };
        return (
          <div style={{ marginTop: errText() && "20px", gap: "12px", position: 'relative' }} className={errText() ? "file_field-error" : "file_field"}>
            <TextareaAutosize
              id={`titleInput-${param?.id}`}
              style={{ height: "62px", width: setAction == 'shop' ? containerWidth * 0.38 : containerWidth * 0.28, minHeight: '62px' }}
              type="textarea"
              defaultValue={param?.value}
              onChange={(e) => {
                setCheckingError(true);
                onSplitDataChange(param?.id, "title", e?.target?.value);
              }}
              onKeyDown={(event) => {
                if (event?.key?.toLowerCase() != "tab") {
                  event.stopPropagation();
                }
              }}
              onBlur={(e) => {
                setCheckingError(false);
                onSplitDataChange(param?.id, "title", e?.target?.value, true);
              }}
              onFocus={(e) => {
                setCheckingError(false);
                onFocusErrorFilter(param?.id, "title");
              }}
            />
            {errText() && (
              <div className="error" style={{ color: "red" }}>
                {errText()}
              </div>
            )}
          </div>
        );
      },
    },
    {
      field: "disciplineId",
      headerName: "Discipline",
      width: containerWidth * 0.2,
      renderCell: (param) => {
        const filterDiscipline = param?.value && disciplineOptions.find((discipline) => discipline.value == param?.value);
        return (
          <div className="file_field">
            <div>
              {filterDiscipline ? filterDiscipline.label : "Unidentified"}
            </div>
            {!filterDiscipline &&
              <Tooltip
                title="Please go to discipline settings to create a new designator & discipline."
                placement="bottom"
                PopperProps={{ className: "version-action-tooltips" }}
              >
                <div style={{ color: "red" }}>
                  No Match Found <img src="error-red.svg" alt="errorImage" />
                </div>
              </Tooltip>
            }
          </div>
        );
      },
    },
    {
      field: "delete",
      headerName: "",
      width: containerWidth * 0.05,
      sortable: false,
      renderCell: (param) => {
        return (
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
        <img onClick={(e) => {
            if (fileSplitRows.length != 1) {
              handleDeleteRow(param?.id)
            }
          }} src={fileSplitRows.length == 1 ? "delete_disable.svg" : "deleted.svg"}  alt="delete" />
        </div>
        );
      },
    },
  ];
  const setFileSplitRows_ = (values) => {
    let stressValue = JSON.parse(JSON.stringify(values));
    setFileSplitRows(stressValue);
    fileSplitRowsRef.current = values;
  };
  const setNewSet_ = (set) => {
    setNewSet(set);
    //newSetRef.current = set;
  };
  const handleSetChange = (set) => {
    setSetAction(set);
  };
  function onDrop(acceptedFiles) {
    let _dropFiles = [];
    if (acceptedFiles.length > 0) {
      for (let doc of acceptedFiles) {
        _dropFiles.push({ attachId: null, fileName: doc.name, file: doc });
      }
      //simulateProgress();
    }
    //console.log('_dropFiles :: ',_dropFiles);
    setSelectedAttach(_dropFiles);
    selectedAttachRef.current = _dropFiles;
  };
  async function setupInit(callFrom) {
    getSetOptions();
    getTypeOfShopDrawingOptions();
    getDisciplineOptions();
    //console.log('bState?.attachId :: ' , bState?.attachId);
    if (bState?.attachId) {
      getDraftedFileFolder(bState?.attachId); // 'a0JDb000003W5bWMAS'
    } else {
      await getSourceFolder(callFrom);
    }
  };

 

  async function getDraftedFileFolder(atchId) {
    let newSourceFolder;
    setLoading(true);
    setLoadingTable(true)
    setSourceFolder(null);
    removeAll();
    let subFoldermap;
    await axios.get(`${fetchUrl}/get_draft_binder/${atchId}?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`).then((res) => {
      if (res?.data?.status == 200) {
        const { attachment, parentFolder, draftDrawings } = res?.data;
        if (attachment?.Id) {
          const draftDrawingsList = draftDrawings?.length > 0 ? draftDrawings : [];
          setFileSplitRows_(draftDrawingsList);
          let _dropFiles = [];
          let _attach = { attachId: attachment?.Id, fileName: attachment.File_Name__c, file: null };
          _attach.setId = attachment.Set__c;
          _attach.url = attachment.fileurl;
          _attach.s3Url = attachment.File_URL__c;
          _dropFiles.push(_attach);
          //console.log('tmpList :: ',tmpList);
          setSelectedAttach(_dropFiles);
          selectedAttachRef.current = _dropFiles;
          if (attachment.Set__c) {
            setSetAction('existing');
            setSetId(attachment.Set__c);
            setNewSet({ Id: attachment.Set__c, Project__c: attachment.Set__r.Project__c, Name: attachment.Set__r.Name, Issuance_Date__c: attachment.Set__r.Issuance_Date__c });
            setExtitingSet(attachment.Set__r.Name);
          } else {
            setShopDrawingValue(attachment.Shop_Drawing_Type__c ? attachment.Shop_Drawing_Type__c : 'none');
            setSetAction('shop');
          }
          if (parentFolder) {
            newSourceFolder = { id: parentFolder?.folder?.Id, name: parentFolder?.folder?.Name, type: parentFolder?.folder?.Folder_Type__c };
            setSourceFolder(newSourceFolder);
            if (parentFolder?.sub_folders?.length > 0) {
              for (let fol of parentFolder?.sub_folders) {
                if (!subFoldermap) {
                  subFoldermap = {};
                }
                if (!subFoldermap[fol.Name]) {
                  subFoldermap[fol.Name] = fol.Id;
                }
              }
            }
          }
          const aStep = attachment.Processing_Status__c == 'title' ? 3 : 2;
          setActiveStep(aStep);

          proceedToBreakPdfRow(_attach.url, draftDrawingsList);

        }
      }else{
        setLoadingTable(false);
      }
    }).catch((err) => {
      setLoadingTable(false);
      console.log("attachment err", err);
    });
    setSubFolderMap(subFoldermap);
    setLoading(false);
  }
  async function getSourceFolder(callFrom) {
    //console.log(callFrom, 'getSourceFolder ProjectId', projectId, sourceFolderDetail?.id);
    let type = 'Plan';
    //let curSourceFolder = sourceFolderDetail;
    let newSourceFolder;
    setLoading(true);
    setSourceFolder(null);
    let subFoldermap;
    await axios.get(`${fetchUrl}/sourceFolder/${type}/${projectId}`).then((res) => {
      if (res?.data?.status == 200) {
        const { tree } = res?.data;
        if (tree?.sourceFolder) {
          newSourceFolder = { id: tree?.sourceFolder?.Id, name: tree?.sourceFolder?.Name, type: tree?.sourceFolder?.Folder_Type__c };
          setSourceFolder(newSourceFolder);
        }
        if (tree?.sub_folders?.length > 0) {
          for (let fol of tree?.sub_folders) {
            if (!subFoldermap) {
              subFoldermap = {};
            }
            if (!subFoldermap[fol.Name]) {
              subFoldermap[fol.Name] = fol.Id;
            }
          }
        }
      }
    }).catch((err) => {
      console.log("sourceFolder err", err);
    });
    setSubFolderMap(subFoldermap);
    setLoading(false);
    removeAll();
  }

  const CustomFooterContainer = styled(GridFooterContainer)({
    '& .MuiTablePagination-root': {
      // Apply the custom class to hide specific elements
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
  });
  
  const CustomFooter = () => {
    return (
      <CustomFooterContainer className="hide-rows-per-page">
           <div style={{ flexGrow: 1 }} />
        <GridPagination />
      </CustomFooterContainer>
    );
  };
  async function getSetOptions() {
    //setLoading(true);
    const instanceUrl = credential.instanceUrl;
    const token = credential.token;
    const projectId = credential.projectId;
    await axios.get(`${fetchUrl}/sets/${projectId}?token=${token}&instanceUrl=${instanceUrl}`)
      .then((res) => {
        if (res?.status === 200) {
          let roww = [];
          res?.data?.records?.map((val, index) => {
            if (val?.Issuance_Date__c != null) {
              roww.push({ value: val?.Id, label: val?.Name, date: moment(val?.Issuance_Date__c).format("MMM DD YYYY") });
            }
          });
          setExistingSetOptions(roww);
        }
      }).catch((err) => {
        console.log(err);
      });
  }
  const onClickSaveAsDraft = async (goback) => {
    const draftStage = (activeStep == 3 ? 'title' : 'number');
    const reqBody = { splitRows: fileSplitRowsRef.current, draftStage: draftStage, s3Url: selectedAttach[0].s3Url };
    //console.log('_onClickSaveAsDraft_ reqBody :: ',JSON.stringify(reqBody));
    axios.post(`${fetchUrl}/save_draft_binder/${selectedAttach[0].attachId}?projectId=${credential.projectId}`, reqBody).then((res) => {
      if (goback == true) {
        goBackToSheet(2);
      }
    }).catch((err) => {
      console.log(err);
    });
  };
  function goBackToSheet(activeTab) {
    var options;
    if (activeTab != null) {
      options = { replace: true, state: { activeTabId: activeTab } }
    } else if (bState?.activeTabId != null) {
      options = { replace: true, state: { activeTabId: bState?.activeTabId } }
    }
    navigate("/Sheets", options);
  }
  async function getDisciplineOptions() {
    //setLoading(true);
    const instanceUrl = credential.instanceUrl;
    const token = credential.token;
    await axios.get(`${fetchUrl}/disciplineOptions/${projectId}?token=${token}&instanceUrl=${instanceUrl}`)
    .then((res) => {
      if (res?.status === 200) {
        let retData = res.data;
        setDisciplineOptions(retData?.options);
        disciplineIds.current = retData?.map;
        setDisciplineMap(retData?.map);
        populateDisciplineToSplitRows(retData?.map);
        let dis_records = [];
        let i = 0;
        for (let d of retData?.dis_records) {
          dis_records.push({ ...{ id: i }, ...d });
          i++;
        }
        setDisciplineList(dis_records);
      }
    }).catch((err) => {
      console.log(err);
    });
  }
  async function getTypeOfShopDrawingOptions() {
    //setLoading(true);
    const instanceUrl = credential.instanceUrl;
    const token = credential.token;
    var req_body = { fields: ['Shop_Drawing_Type__c'] };
    var valuessss;
    await axios.post(`${fetchUrl}/picklist/valueObj/Amazon_S3_Attachment__c?token=${token}&instanceUrl=${instanceUrl}`, req_body)
      .then((res) => {
        if (res?.status === 200) {
          let retData = res.data;
          if (retData?.pickObj?.Shop_Drawing_Type__c?.valueList) {
            valuessss = retData?.pickObj?.Shop_Drawing_Type__c?.valueList;
          }
        }
      }).catch((err) => {
        console.log(err);
      });
    setShopDrawingTypes(valuessss);
  }
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const onClickNext = async () => {
    if (activeStep == 0) {
      setPdfRowErrors(null);
      fileSplitRowsRef.current = null;
      handleNext();
    } else if (activeStep == 1) {
      setPdfRowErrors(null);
      fileSplitRowsRef.current = null;
      /*
      handleNext();
      proceedToBreakPdfRow(furl);
      return;*/
      let _setId = setId;
      if (setAction == 'new') {
        let obj = newSet;
        obj.Project__c = projectId;
        const formData = new FormData();
        //let issueObj = sfIssueObj;
        formData.append("recObj", JSON.stringify(obj));
        await axios.post(`${fetchUrl}/recSave/Set__c?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`, formData)
          .then(async (res) => {
            //console.log("count:::: " + JSON.stringify(res));
            if (res.data.status == 200) {
              getSetOptions();
              _setId = res.data.retObj.id;
              obj.Id = _setId;
              setNewSet(obj);
              await setSetId((preV) => { return _setId });
            }
          }).catch((err) => {
            console.log('recSave/Set__c', err);
          });
      }
      let process = false;
      let toSaveRecords = [];
      let toSaveFileVersions = [];
      if (selectedAttach && selectedAttach.length > 0) {
        for (let file of selectedAttach) {
          if (file.attachId) {
            if (setAction == 'new' || setAction == 'existing') {
              if (_setId && _setId != 'none') {
                toSaveRecords.push({
                  Id: file.attachId,
                  Set__c: _setId
                });
                if (file.fileVersionId) {
                  toSaveFileVersions.push({
                    Id: file.fileVersionId,
                    Set__c: _setId
                  });
                }
              }
            } else if (setAction == 'shop') {
              if (shopDrawingValue && shopDrawingValue != 'none') {
                toSaveRecords.push({
                  Id: file.attachId,
                  Shop_Drawing_Type__c: shopDrawingValue
                });
                if (file.fileVersionId) {
                  toSaveFileVersions.push({
                    Id: file.fileVersionId,
                    Shop_Drawing_Type__c: shopDrawingValue
                  });
                }
              }
            }
          }
        }
      }
      if (toSaveRecords.length > 0) {
        if (setAction == 'new' || setAction == 'existing') {
          if (_setId && _setId != 'none') {
            let setLable = setOptions?.filter(item => item.value == _setId);
            if (setLable && setLable[0] && setLable[0].label) {
              setExtitingSet(setLable[0].label);
            }
            //console.log("setLable ::::", setLable);
          }
        }
        const formData = new FormData();
        formData.append("recLists", JSON.stringify(toSaveRecords));
        await axios.post(`${fetchUrl}/recSave/list/Amazon_S3_Attachment__c?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`, formData)
          .then(async (res) => {
            //console.log("count:::: " + JSON.stringify(res));
            if (res.data.status == 200) {
              process = true;
              const verData = new FormData();
              verData.append("recLists", JSON.stringify(toSaveFileVersions));
              axios.post(`${fetchUrl}/recSave/list/File_Version__c?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`, verData)
                .then(async (res) => {
                }).catch((err) => {
                  console.log('recSave/Amazon_S3_Attachment__c', err);
                });
            }
          }).catch((err) => {
            console.log('recSave/Amazon_S3_Attachment__c', err);
          });
      }
      if (process) {
        handleNext();
        proceedToBreakPdfRow(selectedAttach[0].url);
      }
    } else if (activeStep == 2) {
      setSetSelectedPDFRowIds([]);
      handleNext();
      setPdfRowErrors(undefined);
      onClickSaveAsDraft(false);
    } else if (activeStep == 3) {
      processToUploadSheet();
    }
  };
  const processToUploadSheet = async () => {
    setLoading(true);
    let errorMsg
    if (folderNameToIdMap) {
      const folderId = ((setAction == 'shop') ? folderNameToIdMap[Shop_Folder_Name] : folderNameToIdMap[Contract_Folder_Name]);
      const shopDrawing = ((setAction == 'shop' && shopDrawingValue != 'none') ? shopDrawingValue : null);
      if (folderId) {
        if (docViewIns && instance) {
          const { PDFNet } = instance.Core;
          await PDFNet.initialize();
          var doc = docViewIns.getDocument();
          const docAsPDF = await doc.getPDFDoc();
          let tempRowDetails = cloneDeep(fileSplitRowsRef.current);
          let exportedPages = [];
          for (let row of tempRowDetails) {
            if (row) {
              let pageNum = Number(Number(row.id) + 1);
              const data = await doc.extractPages([pageNum]);
              const pageDoc = await PDFNet.PDFDoc.createFromBuffer(data);
              const arr = await pageDoc.saveMemoryBuffer(PDFNet.SDFDoc.SaveOptions.e_linearized);
              //const arr = new Uint8Array(data);
              const blob = new Blob([arr], { type: 'application/pdf' });
              const image = URL.createObjectURL(blob);
              //console.log(row.id,'dpdf :: ',image);
              const pageDetails = { fileBlob: blob, pageIndex: row.id, draftId: row.draftId};
              try{
                const thumb64 = await getLowerqualityThumbnailBase64( PDFNet, docAsPDF ,row.image , pageNum, 100 );
                if(thumb64){
                  const thumb = await convertImageBase64ToBlob(thumb64);
                  if(thumb){
                    pageDetails.thumb = thumb;
                  }
                }
              }catch (te){

              }
              let TITLE = row.title.trim();
              let NUMBER = row.number.trim();
              pageDetails.sheetNumber = NUMBER;
              if (!NUMBER.toLowerCase().endsWith(".pdf")) {
                NUMBER = (NUMBER + '.pdf');
              }
              pageDetails.sheetTitle = TITLE;
              pageDetails.sheetName = NUMBER;
              pageDetails.disciplineId = ((setAction == 'shop' || row.disciplineId == 'none') ? null : row.disciplineId);
              exportedPages.push(pageDetails);
            } else {
              errorMsg = 'table row not found';
              break;
            }
          }
          if (exportedPages) {
            let upload_length = 0;
            setLoading(true);
            Promise.all(exportedPages).then((values) => {
              values.forEach((file, i) => {
                const formData = new FormData();
                formData.append("sheetFile", file.fileBlob, file?.sheetName);
                if(file.thumb){
                  formData.append("thumbFile", file.thumb, file?.sheetName);
                }
                formData.append("fileNumber", file?.sheetNumber);
                formData.append("fileTitle", file?.sheetTitle);
                formData.append("shopDrawing", shopDrawing);
                formData.append("disciplineId", file?.disciplineId);
                formData.append("draftId", file?.draftId);
                //formData.append("thumb64", file?.thumb64);
                axios.post(`${fetchUrl}/add/sheet/${credential?.projectId}/${selectedAttach[0].attachId}/${folderId}?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`, formData)
                  .then((res) => {
                    if (res.status === 200) {
                      upload_length += 1;
                      console.log("upload_length", upload_length, "exportedPages.length", exportedPages.length);
                      if (upload_length === exportedPages.length) {
                        var reqBody = { sheetCount: exportedPages.length };
                        axios.put(`${fetchUrl}/publish/finish/${selectedAttach[0].attachId}?token=${credential?.sessionId}&instanceUrl=${credential?.instanceUrl}`, reqBody)
                        .then((res) => {
                          console.log('publised log');
                          goBackToSheet(setAction == 'shop' ? 1 : 0);
                          setLoading(false);
                        }).catch((err) => {
                          setLoading(false);
                          console.log("error", err);
                        });
                      }
                    }
                  }).catch((err) => {
                    setLoading(false);
                    console.log("error", err);
                  });
              });
            });
          } else {
            errorMsg = 'Files not exported.'
          }
        } else {
          errorMsg = 'Document instance not found.'
        }
      } else {
        errorMsg = 'Target Folder Not Found';
      }
    } else {
      errorMsg = 'Folder Map Empty';
    }
    if (errorMsg) {
      console.log('error msg::', errorMsg);
    }
  };
  const onStartBackgroundExtracting = async (cropArea, cropRect, section, rectOrigin) => {
    let errorMsg;
    if (documentInstanceRef.current) {
      const tempRowDetails = cloneDeep(fileSplitRowsRef.current);
      //console.log('BackgroundExtracting ::', JSON.stringify(tempRowDetails));
      if (tempRowDetails?.length > 0) {
        const { documentViewer, PDFNet } = documentInstanceRef.current.Core;
        await PDFNet.initialize();
        const doc = documentViewer.getDocument();
        const fileUrl = selectedAttachRef.current[0].url;
        const docAsPDF = await PDFNet.PDFDoc.createFromURL(fileUrl);
        const pageCount = doc.getPageCount();
        const instanceUrl = credential.instanceUrl;
        const token = credential.token;
        const s3Url = selectedAttachRef.current[0].s3Url;
        const attachmentId = selectedAttachRef.current[0].attachId;
        const initReqBody = {
          fileUrl: s3Url, cropArea: cropArea, cropRect: cropRect, section: section,
          pushForDatabase: false,
          projectId: credential?.projectId,
          binderId: attachmentId,
          cropFor: section
        };

        let upload_length = 0;
        setBackgroundProcess(true);
        console.log('---------------Background process starts------------');
        let extractingUpdate = { Id: selectedAttachRef.current[0].attachId, Publishing_Status__c: 'Processing' };
        const eFdata = new FormData();
        //let issueObj = sfIssueObj;
        eFdata.append("recObj", JSON.stringify(extractingUpdate));
        axios.post(`${fetchUrl}/recSave/Amazon_S3_Attachment__c?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`, eFdata)
          .then(async (res) => {
            //console.log("count:::: " + JSON.stringify(res));
          }).catch((err) => {
            console.log('extractingUpdate error /Amazon_S3_Attachment__c', err);
          });
        for (let ind = 0; ind < tempRowDetails.length; ind++) {
          const row = tempRowDetails[ind];
          console.log('BackgroundExtracting Passed ::', JSON.stringify(tempRowDetails));
          const reqBody = initReqBody;
          reqBody.drawingRow = row;
          reqBody.pageIndex = row.id;
          axios.post(`${fetchUrl}/extract_save_text/sheet/${attachmentId}?token=${token}&instanceUrl=${instanceUrl}`, reqBody)
            .then(async (res) => {
              if (res.status === 200) {
                const { returnRow } = res.data;
                if(returnRow?.number){
                  returnRow.number = returnRow?.number.replace(/\s/g, '');
                }
                upload_length += 1;
                console.log("background upload_length", upload_length, "totalrow.length", tempRowDetails.length);
                if (upload_length === tempRowDetails.length) {
                  console.log('---------------Background process ends------------');
                  extractingUpdate.Publishing_Status__c = 'For Review';
                  const rFdata = new FormData();
                  //let issueObj = sfIssueObj;
                  rFdata.append("recObj", JSON.stringify(extractingUpdate));
                  axios.post(`${fetchUrl}/recSave/Amazon_S3_Attachment__c?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`, rFdata)
                    .then(async (res) => {
                      //console.log("count:::: " + JSON.stringify(res));
                    }).catch((err) => {
                      console.log('ready for review update errror /Amazon_S3_Attachment__c', err);
                    });
                  setBackgroundProcess(false);
                }
                await PDFNet.runWithCleanup(async () => {
                await PDFNet.initialize();
                const pdfdraw = await PDFNet.PDFDraw.create(92);
                const currPage = await docAsPDF.getPage(row.id + 1);
                const pageInfo = doc.getPageInfo(row.id + 1);
                returnRow[section + '_image'] = await processToCropAreaImage(PDFNet, currPage, pdfdraw, pageInfo, cropRect, rectOrigin);
                if (section == 'number' && setAction != 'shop') {
                  returnRow.disciplineId = getDisciplineForRow(returnRow.number, disciplineMap);
                }
                let imgEleId = `${section}Image-${row.id}`;
                let foundImageEle = document.getElementById(imgEleId);
                if (foundImageEle && returnRow[section + '_image']) {
                  foundImageEle.src = returnRow[section + '_image'];
                }
                let eleId = `${section}Input-${row.id}`;
                let foundInput = document.getElementById(eleId);
                if (foundInput) {
                  foundInput.value = returnRow[section];
                }
                console.log('BackgroundExtracting returnRow :: ', JSON.stringify(returnRow));
                setFileSplitRows((preRows) => {
                  let tempFileSplitRows = cloneDeep(preRows);
                  if(!returnRow?.image){
                    returnRow.image = tempFileSplitRows[ind]?.image ;
                  }
                  tempFileSplitRows[ind] = returnRow;
                  fileSplitRowsRef.current = tempFileSplitRows;
                  return tempFileSplitRows;
                });
              });
              }
            }).catch((err) => {
              setLoading(false);
              setBackgroundProcess(false);
              console.log("error", err);
            });
        }
      }
    } else {
      errorMsg = 'Document instance not found.';
    }
    if (errorMsg) {
      console.log('error msg::', errorMsg);
    }
  }
  function populateDisciplineToSplitRows(_disciplineMap = disciplineMap) {
    let tempFileSplitRows = cloneDeep(fileSplitRowsRef.current);
    if (tempFileSplitRows?.length > 0) {
      for (let row_ of tempFileSplitRows) {
        row_.disciplineId = getDisciplineForRow(row_.number, _disciplineMap);
      }
    }
    setFileSplitRows_(tempFileSplitRows);

  }
  function getDisciplineForRow(numberText, _disciplineMap = disciplineMap) {
    let discipId = null
    if (numberText) {
      let i = numberText.indexOf('-');
      let disName = i > -1 ? numberText.substring(0, i) : numberText;
      if (disName && disName.trim() != '') {
        let dis = disName.trim().toLowerCase();
        if (_disciplineMap && _disciplineMap[dis]) {
          discipId = _disciplineMap[dis];
        }
      }
    }
    return discipId;
  }
  const downloadMedia = (url, fileName) => {
    const a = document.createElement("a");
    a.href = url;
    a.style.display = "none";
    a.download = `${fileName}`;
    a.setAttribute("download", fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  const checkStepAndErros = () => {
    //console.log("checkStepAndErros");
    let activeNext = true;
    if (activeStep == 0) {
      if (selectedAttach?.length > 0) {
        let countFullFilled = selectedAttach.filter(item => item.hasOwnProperty('attachId') && item['attachId'] !== null).length;
        let totalCount = selectedAttach?.length;
        activeNext = (totalCount == countFullFilled);
      } else {
        activeNext = false;
      }
    } else if (activeStep == 1) {
      /*console.log('activeNext ',activeNext);
      console.log('newSet',newSet);
      console.log('newSetErrors',newSetErrors);
      console.log('setId',setId);
      console.log('setAction0',setAction);*/
      if (setAction == 'new') {
        if (newSetErrors?.nameError || newSetErrors?.dateError || !newSet) {
          activeNext = false;
        } else if (newSet && newSet.hasOwnProperty('Issuance_Date__c') && newSet.hasOwnProperty('Name')) {
          if (!newSet.Issuance_Date__c || !newSet.Name) {
            activeNext = false;
          }
        } else {
          activeNext = false;
        }
      } else if (setAction == 'existing') {
        if (!setId || setId == 'none') {
          activeNext = false;
        }
      } else if (setAction == 'shop') {
        if (!shopDrawingValue || shopDrawingValue == 'none') {
          activeNext = false;
        }
      }
      if (!setAction || setAction == '') {
        activeNext = false;
      }
      //console.log('activeNext ',activeNext , activeStep);
    } else if (activeStep == 2) {
      activeNext = onValidatePdfPageDataChange();
    } else if (activeStep == 3) {
      activeNext = onValidatePdfPageDataChange();
    }
    if (!sourceFolderDetail?.id || !projectId || !credential) {
      //console.log('sourceFolderDetail :: check fail');
      activeNext = false;
    }
    //console.log('activeNext ', activeNext, activeStep);
    setActiveNextButton(activeNext);
  };
  function onValidatePdfPageDataChange() {
    //pdfRowErrors, setPdfRowErrors
    //console.log("onValidatePdfPageDataChange");
    let activeNext = false;
    const _temp = cloneDeep(fileSplitRowsRef.current);
    //console.log("_temp ::", _temp);
    var errObj;
    if (_temp?.length > 0) {
      const seenValues = new Map();
      let section = (activeStep == 2 ? 'number' : 'title');
      for (let i = 0; i < _temp.length; i++) {
        let value = _temp[i][section];
        if (value && value != '') {
          if (section == 'number') {
            value = value.toLowerCase();
            if (seenValues.has(value)) {
              if (!errObj) {
                errObj = { duplicates: [] };
              }
              if (!errObj.duplicates) {
                errObj.duplicates = [];
              }
              //console.log("errObj.duplicates2 ::", errObj.duplicates);
              errObj.duplicates.push(i);
              //console.log("errObj.duplicates3 ::", errObj.duplicates);
              errObj.duplicates.push(seenValues.get(value));
              //console.log("errObj.seenValues4 ::", seenValues);
            } else {
              seenValues.set(value, i);
            }
          }
        } else {
          if (!errObj) {
            errObj = { empties: [] };
          }
          if (!errObj.empties) {
            errObj.empties = [];
          }
          //console.log("_temp[i].id ::", _temp[i].id);
          errObj.empties.push(i);
        }
        if (section == 'title' && setAction != 'shop') {
          if (!_temp[i].disciplineId || _temp[i].disciplineId == null || _temp[i].disciplineId == 'none') {
            if (!errObj) {
              errObj = { discipline_empties: [] };
            }
            if (!errObj.discipline_empties) {
              errObj.discipline_empties = [];
            }
            if (errObj && errObj.empties && errObj.empties.includes(i)) {

            } else {
              errObj.discipline_empties.push(i);
            }
          }
        }
      }
      if (errObj && (errObj.duplicates?.length > 0 || errObj.empties?.length > 0 || errObj.discipline_empties?.length > 0)) {
        if (errObj.empties?.length > 0) {
          errObj.message = `${section.toUpperCase()} not populated.`
        } else if (errObj.duplicates?.length > 0) {
          errObj.message = `${section.toUpperCase()} duplicate.`;
        } else if (errObj.discipline_empties?.length > 0) {
          errObj.message = `${'discipline'.toUpperCase()} not populated.`
        }
      } else {
        activeNext = true;
      }
      setPdfRowErrors(errObj);

    }
    //console.log('errObj ', JSON.stringify(errObj));
    return activeNext;
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const removeAll = () => {
    //console.log('removeAll...');
    if (inputRef && inputRef.current) {
      inputRef.current.value = '';
    }
    setSelectedAttach([]);
    selectedAttachRef.current = [];
  };
  const proceedToBreakPdfRow = (fileUrl, draftDrawings) => {
    setLoadingTable(true);
    // setLoadingtrue here
    const inter = setTimeout(() => {
      breakFilePages(fileUrl, draftDrawings);
    }, 1000);
  };
  const handleShowErrorFiles = (fileUrl) => {
    console.log("showErrorFiles ::", showErrorFiles);
    if (showErrorFiles) {
      setShowErrorFiles(false);
    } else {
      //console.log("pdfRowErrors::",pdfRowErrors);
      //console.log("pdfRowErrors.empties::",pdfRowErrors.empties);
      //console.log("pdfRowErrors.duplicates::",pdfRowErrors.duplicates);
      console.log("pdfRowErrors.discipline_empties  ::", pdfRowErrors.discipline_empties);
      setShowErrorFiles(true);
      const filterErrorFiles = fileSplitRowsRef.current.filter(row =>
        pdfRowErrors.empties && pdfRowErrors.duplicates && pdfRowErrors.discipline_empties ? pdfRowErrors.duplicates.concat(pdfRowErrors.empties, pdfRowErrors.discipline_empties).includes(row.id) : pdfRowErrors.empties && pdfRowErrors.duplicates ? pdfRowErrors.duplicates.concat(pdfRowErrors.empties).includes(row.id) : pdfRowErrors.empties && pdfRowErrors.discipline_empties ? pdfRowErrors.empties.concat(pdfRowErrors.discipline_empties).includes(row.id) : pdfRowErrors.empties ? pdfRowErrors.empties.includes(row.id) : pdfRowErrors.duplicates ? pdfRowErrors.duplicates.includes(row.id) : pdfRowErrors.discipline_empties.includes(row.id)
      );
      console.log("erroe files ::", filterErrorFiles);
      setErrorFiles(filterErrorFiles);
    }
  };
  const breakFilePages = async (fileUrl, draftDrawings) => {
    console.log('pdfContainerRef ', pdfContainerRef);
    if (pdfContainerRef != null && pdfContainerRef?.current != null) {
      const drafAvailable = (draftDrawings?.length > 0);
      await WebViewer({
        fullAPI: true,
        path: '/webviewer/public',
        initialDoc: fileUrl,
        licenseKey: APRYSE_L_KEY,  // sign up to get a free trial key at https://dev.apryse.com,
        disableObjectURLBlobs: true,
        backendType: WebViewer.BackendTypes.ASM,
      }, pdfContainerRef.current).then(async (instance) => {
        documentInstanceRef.current = instance;
        setInstance(instance);
        const { documentViewer, PDFNet } = instance.Core;
        setViewIns(documentViewer);
        await documentViewer.addEventListener('documentLoaded', async () => {
          //console.log('Document Loaded');
          setViewIns(documentViewer);
          await PDFNet.initialize();
          let originCoordinates = null;
          const doc = documentViewer.getDocument();
          const getOriginCoordinates = doc.getPDFCoordinates(1, 0, 0);
          if(getOriginCoordinates.x != 0){
            originCoordinates = getOriginCoordinates;
          }
          const docAsPDF = await PDFNet.PDFDoc.createFromURL(fileUrl);
          //const docAsPDF = await doc.getPDFDoc();
          const pageCount = doc.getPageCount();
          totalPageCountsRef.current = pageCount;
          setPageCount(pageCount);
          setTotalPageExtract(pageCount);
          if (!drafAvailable) {
            const initialRows = Array.from({ length: pageCount }, (_, index) => ({ id: index, pageNumber: index + 1, image: null, /* You'll populate this once the content is loaded*/ }));
            setFileSplitRows_(initialRows);
            setLoadingTable(false);
          } 
          const inter = setTimeout(async () => {
            for (let i = 0; i < pageCount; i++) {
              let ind = i;
              let pushToRow = (drafAvailable == false ? true : false);
              let draftRow;
              if (drafAvailable) {
                const fInd = draftDrawings.findIndex(row => Number(row.id) === ind);
                if (fInd > -1) { draftRow = draftDrawings[fInd]; pushToRow = true; ind = fInd; }
              }
              if (pushToRow) {
                //console.log("break start for :: ",ind);
                try {
                  //console.log(ind,"starts page break ::");
                    await PDFNet.runWithCleanup(async () => {
                    const pdfdraw = await PDFNet.PDFDraw.create(92);
                    //console.log(ind,"pdfdraw success ::");
                    const currPage = await docAsPDF.getPage(ind + 1);
                    //console.log(ind,"currPage Found ::",currPage);
                    const pageInfo = doc.getPageInfo(ind + 1);
                    //console.log(ind,"pageInfo Found ::",pageInfo);
                    pdfdraw.setImageSize(pageInfo.width, pageInfo.height);
                    //console.log(ind,"pdfdraw updated ::");
                    const pngBuffer = await pdfdraw.exportBuffer(currPage, 'PNG');
                    //console.log('ind,pngBuffer success');
                    const image = URL.createObjectURL(new Blob([pngBuffer.buffer], { type: 'image/png' }));
                    var number_img;
                    var title_img;
                    if (draftRow?.number_cropRect) {
                 
                      number_img = await processToCropAreaImage(PDFNet, currPage, pdfdraw, pageInfo, draftRow?.number_cropRect, originCoordinates);
                    }
                    if (draftRow?.title_cropRect) {
                      title_img = await processToCropAreaImage(PDFNet, currPage, pdfdraw, pageInfo, draftRow?.title_cropRect, originCoordinates);
                    }
                    setCheckingError(false);
                    console.log(ind, 'to update list :: ');
                    setFileSplitRows((preRows) => {
                      let tempFileSplitRows = cloneDeep(preRows);
                      tempFileSplitRows[ind].image = image;
                      tempFileSplitRows[ind].number_image = number_img;
                      tempFileSplitRows[ind].title_image = title_img;
                      fileSplitRowsRef.current = tempFileSplitRows;
                      return tempFileSplitRows;
                    });
                    setLoadingTable(false);
                  })
                } catch (e) {
                  console.log(ind, 'Page Break Error :: ', e);
                }
              }
            }
          }, 1000);
        });
      });
    }
  };
  const onExtractSave = async (_pageIndexToValue, section, populateDisi, rectOrigin) => {
    const _temp = cloneDeep(fileSplitRowsRef.current);
    /*console.log('index :: ',index);
    console.log('section :: ',section);
    console.log('text :: ',text); */
    //console.log('_pageIndexToValue :: ',JSON.stringify(_pageIndexToValue));
    //console.log('section :: ',section);
    if (_pageIndexToValue) {
      for (let key in _pageIndexToValue) {
        const fInd = _temp?.findIndex((data) => data.id == key);
        //console.log('key :: ',key);
        const index = Number(fInd);
        let text = !_pageIndexToValue[key].error ? _pageIndexToValue[key].value : '';
        let cropRect_ = !_pageIndexToValue[key].error ? _pageIndexToValue[key].cropRect : null;
        //console.log('text :: ',text);
        if (_temp[index]) {
          _temp[index][section] = text;
          //console.log('_temp :: ',_temp);
          let eleId = `${section}Input-${_temp[index].id}`;
          let foundInput = document.getElementById(eleId);
          if (foundInput) {
            foundInput.value = text;
          }
          _temp[index][section + '_cropRect'] = cropRect_;
          if (cropRect_ || _pageIndexToValue[key].cropRect) {
            _temp[index][section + '_image'] = await getCropAreaImage(instance, _pageIndexToValue[key].cropRect, _temp[index].id, rectOrigin);
            let imgEleId = `${section}Image-${_temp[index].id}`;
            console.log("imgEleId :", imgEleId);
            let foundImageEle = document.getElementById(imgEleId);
            if (foundImageEle && _temp[index][section + '_image']) {
              foundImageEle.src = _temp[index][section + '_image'];
            }
          } else {
            _temp[index][section + '_image'] = null;
          }
          if (section == 'number' && setAction != 'shop') {
            //console.log('stopCheckingError ::'+stopCheckingError);
            if (populateDisi == true) {
              _temp[index].disciplineId = getDisciplineForRow(text, disciplineMap);
            }
          }
          //console.log('_temp',_temp);
        }
      }
      setFileSplitRows_(_temp);
    }
  };
  const getCropAreaImage = async (_instance = instance, cropRect, pageIndex, rectOrigin) => {
    try {
      const fileUrl = selectedAttach[0].url;
      const { documentViewer, PDFNet } = _instance.Core;
      await PDFNet.initialize();
      const doc = documentViewer.getDocument();
      const docAsPDF = await PDFNet.PDFDoc.createFromURL(fileUrl);
      const pdfdraw = await PDFNet.PDFDraw.create(92);
      const currPage = await docAsPDF.getPage(pageIndex + 1);
      const pageInfo = doc.getPageInfo(pageIndex + 1);
      return await processToCropAreaImage(PDFNet, currPage, pdfdraw, pageInfo, cropRect, rectOrigin);
    } catch (e) {
      console.log('getCropAreaImage_ :: ', e);
    }

  };
  const processToCropAreaImage = async (_PDFNet, _currPage, _pdfdraw, _pageInfo, cropRect, rectOrigin) => {
    try {
      const clonePage = await _currPage.copy();
      var cropBox = new _PDFNet.Rect(cropRect.x1, _pageInfo.height - cropRect.y1, cropRect.x2, _pageInfo.height - cropRect.y2);
      if (cropRect.altered) {
        //  cropBox = new _PDFNet.Rect(_pageInfo.width - cropRect.x1, _pageInfo.height - cropRect.y1, _pageInfo.width - cropRect.x2,_pageInfo.height - cropRect.y2);
        // changes for thumbnail, working for pat binder 28sept23
        //cropBox = new _PDFNet.Rect((2592-2560), (3456-3420), (2592-2450), (3456-3100));
        cropBox = new _PDFNet.Rect(cropRect.x1, (_pageInfo.width - cropRect.y1), cropRect.x2, (_pageInfo.width - cropRect.y2));
      }
      if (rectOrigin) {
       
        cropBox = new _PDFNet.Rect( cropRect.x2 + rectOrigin.x, rectOrigin.y - cropRect.y2 ,  rectOrigin.x + cropRect.x1,  rectOrigin.y - cropRect.y1 );
      }
      clonePage.setCropBox(cropBox);
      const pngBuffer = await _pdfdraw.exportBuffer(clonePage, 'PNG');
      const image = URL.createObjectURL(new Blob([pngBuffer.buffer], { type: 'image/png' }));
      console.log("image ::", image);
      return image;
    } catch (e) {
      console.log('processToCropAreaImage :: ', e);
    }
  }
  const onSplitDataChange = async (idValue, section, text, populateDisi) => {
    if (showErrorFiles == true) {
      const _temp1 = cloneDeep(errorFiles);
      const fInd = _temp1?.findIndex((data) => data.id == idValue);
      let index = fInd;
      if (index == -1) {
        index = 0;
      }
      _temp1[index][section] = text;
      setErrorFiles(_temp1);
    }
    const _temp = cloneDeep(fileSplitRowsRef.current);
    const fInd = _temp?.findIndex((data) => data.id == idValue);
    let index = fInd;
    /*console.log('index :: ',index);
    console.log('section :: ',section);
    console.log('text :: ',text);*/
    if (index == -1) {
      index = 0;
    }
    if (_temp[index]) {
      _temp[index][section] = text;
      //console.log('_temp :: ',_temp);
      let eleId = `${section}Input-${_temp[index].id}`;
      setInputId(eleId);
      let foundInput = document.getElementById(eleId);
      //console.log("foundInput ::", foundInput);
      if (foundInput) {
        foundInput.value = text;
      }
      if (eleId != inputId && showErrorFiles == true) {
        const filterErrorFiles = fileSplitRowsRef.current.filter(row =>
          pdfRowErrors.empties && pdfRowErrors.duplicates && pdfRowErrors.discipline_empties ? pdfRowErrors.duplicates.concat(pdfRowErrors.empties, pdfRowErrors.discipline_empties).includes(row.id) : pdfRowErrors.empties && pdfRowErrors.duplicates ? pdfRowErrors.duplicates.concat(pdfRowErrors.empties).includes(row.id) : pdfRowErrors.empties && pdfRowErrors.discipline_empties ? pdfRowErrors.empties.concat(pdfRowErrors.discipline_empties).includes(row.id) : pdfRowErrors.empties ? pdfRowErrors.empties.includes(row.id) : pdfRowErrors.duplicates ? pdfRowErrors.duplicates.includes(row.id) : pdfRowErrors.discipline_empties.includes(row.id)
        );
        //console.log("erroe files ::", filterErrorFiles);
        setErrorFiles(filterErrorFiles);
      }
      if (section == 'number' && setAction != 'shop') {
        //console.log('stopCheckingError ::'+stopCheckingError);
        if (populateDisi == true) {
          _temp[index].disciplineId = getDisciplineForRow(text, disciplineMap);
        }
      }
      //console.log('_temp',_temp);
      setFileSplitRows_(_temp);
    }
  };
  const onFocusErrorFilter = async (idValue, section) => {
    const _temp = cloneDeep(fileSplitRowsRef.current);
    const fInd = _temp?.findIndex((data) => data.id == idValue);
    let index = fInd;
    if (index == -1) {
      index = 0;
    }
    if (_temp[index]) {
      let eleId = `${section}Input-${_temp[index].id}`;
      setInputId(eleId);

      if (eleId != inputId && showErrorFiles == true) {
        const filterErrorFiles = fileSplitRowsRef.current.filter(row =>
          pdfRowErrors.empties && pdfRowErrors.duplicates && pdfRowErrors.discipline_empties ? pdfRowErrors.duplicates.concat(pdfRowErrors.empties, pdfRowErrors.discipline_empties).includes(row.id) : pdfRowErrors.empties && pdfRowErrors.duplicates ? pdfRowErrors.duplicates.concat(pdfRowErrors.empties).includes(row.id) : pdfRowErrors.empties && pdfRowErrors.discipline_empties ? pdfRowErrors.empties.concat(pdfRowErrors.discipline_empties).includes(row.id) : pdfRowErrors.empties ? pdfRowErrors.empties.includes(row.id) : pdfRowErrors.duplicates ? pdfRowErrors.duplicates.includes(row.id) : pdfRowErrors.discipline_empties.includes(row.id)
        );
        setErrorFiles(filterErrorFiles);
      }
    }
  };
  const handleDeleteRow = async (idValue) => {
    let id = [];
    id.push(idValue);
    setSetSelectedPDFRowIds(id);
    setDeleteDialog(true);
  }
  const DroppedFiles = selectedAttach.map((doc, index) => (
    <BinderFile folderId={sourceFolderDetail?.id} credential={credential}
      document={doc.file} index={index}
      attachId={doc?.attachId}
      onSuccess={onSuccess}
      removeFile={removeFile}
      attach={attach}
      setAttach={setAttach}
       />
  ));
  async function onSuccess(att, index, sts) {
    const tmpList = cloneDeep(selectedAttach);
    //console.log('tmpList :: ',tmpList);
    if (index > -1) {
      if (tmpList[index]) {
        if (att && att?.Id) {
          //console.log('att :: ',JSON.stringify(att));
          tmpList[index].attachId = att.Id;
          tmpList[index].setId = att.Set__c;
          //tmpList[index].url = URL.createObjectURL(tmpList[index].file);
          tmpList[index].url = att.url;
          tmpList[index].s3Url = att.File_URL__c;
          tmpList[index].fileVersionId = att.fileVersionId;
        }
        //console.log('tmpList :: ',tmpList);
        setSelectedAttach(tmpList);
        selectedAttachRef.current = tmpList;
      }
    }
  };
  async function removeFile(index) {
    const tmpList = cloneDeep(selectedAttach);
    //console.log('tmpList :: ',tmpList);
    if (index > -1) {
      if (tmpList[index]) {
        tmpList.splice(index, 1);
        //console.log('tmpList :: ',tmpList);
        setSelectedAttach(tmpList);
        selectedAttachRef.current = tmpList;
      }
    }
    if (tmpList?.length > 0) {
      let countFullFilled = tmpList.filter(item => item.hasOwnProperty('attachId') && item['attachId'] !== null).length;
      let totalCount = selectedAttach?.length;
      setActiveNextButton(totalCount == countFullFilled);
    } else {
      setActiveNextButton(false);
    }
  };
  //console.log("activeStep", activeStep);
  return (
    <AddSheetComponentStyles>
      <Grid container className="hold_content">
        {isLoading &&
          <Spinner />
        }
        <Grid className="add_sheet_steps" item>
          <Box className="steps_heading">
            <img src={LeftArrowIcon} alt="left_arrow_icon" onClick={() => goBackToSheet()} />
            <Typography variant="body2" onClick={() => goBackToSheet()}>Back to Sheets</Typography>
          </Box>
          <Box className="steps_body">
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((step, index) => (
                <Step key={step.label}>
                  <StepLabel
                    optional={
                      index < activeStep ? (
                        <Box sx={{ display: "grid" }}>
                          <Typography sx={{ fontSize: 10 }} className="step_label_desc" variant="caption" >
                            {index === 0 && activeStep > 0
                              ? 1 + " " + "Files Uploaded"
                              : index === 1 && activeStep > 1
                                ? extitingSet ? extitingSet : "Version Set Name"
                                : index === 2 && activeStep > 2
                                  ? 2 + " " + "Sheets"
                                  : ""}
                          </Typography>
                          <Typography variant="caption" sx={{ color: "#0AA110", fontSize: 10 }} >
                            Completed
                          </Typography>
                        </Box>
                      ) : index === activeStep ? null : (
                        <Typography sx={{ fontSize: 10 }} className="step_label_desc" variant="caption" >
                          Pending
                        </Typography>
                      )
                    }
                  >
                    {step.label}
                  </StepLabel>
                  <StepContent className="holderstep">
                    <Typography>{step.description}</Typography>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </Box>
        </Grid>
        <Grid item className="rsheet_area">
          <div className="wrapper_right">
            <Typography className="title" variant="h4">
              Add Sheets
            </Typography>
            <Box className="upload_body">
              <div ref={pdfContainerRef_step1} style={{ display: 'none' }}></div>
              {activeStep === 0 && (
                <div className="upload_holder">
                  <Typography variant="h6">Upload your sheet files</Typography>
                  <Typography variant="body2" className="upload_p">
                    Please upload your files below to start adding sheets
                  </Typography>
                  <section className="dropZone_container">
                    {sourceFolderDetail?.id &&
                      <div {...getRootProps({ className: "dropzone" })}>
                        <div className="upload_holder">
                          <input {...getInputProps()} disabled={!sourceFolderDetail?.id} />
                          <img src={UploadIcon} alt="upoad_icon" />
                          <p className="drag_drop">
                            Drag and Drop or{" "}
                            <span className="blue_upload">Choose File</span> to Upload
                          </p>
                          <p className="upload_p">File supported: pdf </p>
                        </div>
                      </div>
                    }
                    {!sourceFolderDetail?.id &&
                      <p className="dropzone upload_p">
                        {isLoading ? 'Source Folder Loading' : 'Source Folder Missing'}
                      </p>
                    }
                    <aside>
                      <h4>{`${selectedAttach.length} File${selectedAttach?.length > 1 ? 's' : ''}`}</h4>
                      {selectedAttach.length > 0 &&
                        <ul>{DroppedFiles}</ul>
                      }
                    </aside>
                  </section>
                </div>
              )}
              {activeStep === 1 && (
                <>
                  <div className="upload_holder">
                    <Typography variant="h6">Choose set name</Typography>
                    <Typography variant="body2" className="upload_p">
                      You may choose between creating a new version set or selecting
                      an existing one.
                    </Typography>
                  </div>
                  <Grid container className="holder_set">
                    <Grid className={setAction === "new" ? "checkbox-border" : "checkbox"} item onClick={() => { handleSetChange("new"); }}>
                      <Grid className="set_title">
                        <Typography variant="h4">New Set Name</Typography>
                        <IconButton >
                          {setAction === "new" ? (
                            <CheckCircleIcon style={{ color: "#346EF5" }} />
                          ) : (
                            <RadioButtonUncheckedIcon />
                          )}
                        </IconButton>
                      </Grid>
                      <Grid>
                        <div className="form_group">
                          <InputLabel>Set Name</InputLabel>
                          <OutlinedInput
                            style={{ backgroundColor: setAction !== "new" ? "#EAEAEA" : "white", color: "red" }}
                            placeholder="Enter Set Name"
                            variant=""
                            disabled={setAction !== "new"}
                            value={newSet?.Name}
                            onChange={(event) => {
                              let val = event.target.value;
                              let nset = cloneDeep(newSet);
                              if (!nset) {
                                nset = { Name: '' };
                              }
                              nset.Name = val;
                              setNewSet_(nset);
                              if (setOptions?.length > 0 && val && val.length > 1) {
                                let countFullFilled = setOptions?.filter(item => item.label.toLowerCase() === val.toLowerCase()).length;
                                if (countFullFilled) {
                                  // set error
                                  setNewSetErros((_sts) => {
                                    let f = _sts;
                                    if (!f) {
                                      f = {};
                                    }
                                    f = { ...f, ...{ nameError: 'Duplicate set name' } };
                                    return f;
                                  });
                                }
                              } else if (!val || val == '') {
                                // set error
                                setNewSetErros((_sts) => {
                                  let f = _sts;
                                  if (!f) {
                                    f = {};
                                  }
                                  f = { ...f, ...{ nameError: 'Set name not filled' } };
                                  return f;
                                });
                              } else {
                                setNewSetErros((_sts) => {
                                  let f = _sts;
                                  if (!f) {
                                    return null;
                                  }
                                  f = { ...f, ...{ nameError: undefined } };
                                  return f;
                                });
                              }
                            }}
                          />
                        </div>
                        <div className="form_group">
                          <InputLabel>Issuance Date</InputLabel>
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <Stack spacing={0} style={{ backgroundColor: setAction !== "new" ? "#EAEAEA" : "white", color: "red" }}>
                              <DesktopDatePicker
                                disabled={setAction !== "new"}
                                inputFormat="MM/dd/yyyy"
                                value={dateValue}
                                onChange={(newValue) => {
                                  setDateValue(newValue);
                                  let fff = cloneDeep(newSet);
                                  if (!fff) {
                                    fff = { Issuance_Date__c: null };
                                  }
                                  if (newValue) {
                                    fff.Issuance_Date__c = moment(newValue).format("YYYY-MM-DD");
                                  } else {
                                    fff.Issuance_Date__c = null;
                                  }
                                  setNewSet_(fff);
                                  if (!fff) {
                                    // set error
                                    setNewSetErros((_sts) => {
                                      let f = _sts;
                                      if (!f) {
                                        f = {};
                                      }
                                      f = { ...f, ...{ dateError: 'Issuance date not filled' } };
                                      return f;
                                    });
                                  } else {
                                    setNewSetErros((_sts) => {
                                      let f = _sts;
                                      if (!f) {
                                        return null;
                                      }
                                      f = { ...f, ...{ dateError: undefined } };
                                      return f;
                                    });
                                  }
                                }}
                                placeholder="Select Date"
                                renderInput={(params) => <TextField {...params} />}
                              />
                            </Stack>
                          </LocalizationProvider>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid className={setAction === "existing" ? "checkbox-border" : "checkbox"} item  onClick={() => { handleSetChange("existing"); }}>
                      <Grid className="set_title">
                        <Typography variant="h4">Existing Set Name</Typography>
                        <IconButton>
                          {setAction === "existing" ? (
                            <CheckCircleIcon style={{ color: "#346EF5" }} />
                          ) : (
                            <RadioButtonUncheckedIcon />
                          )}
                        </IconButton>
                      </Grid>
                      <Grid className="form_group">
                        <InputLabel>Version Set</InputLabel>
                        <Select disabled={setAction !== "existing"}
                          style={{ backgroundColor: setAction !== "existing" ? "#EAEAEA" : "white" }}
                          label="Select Existing Version Set"
                          value={setId}
                          onChange={(event) => {
                            setSetId(event?.target?.value);
                          }}>
                          <MenuItem value={'none'}>
                            <em>Select Existing Version Set</em>
                          </MenuItem>
                          {setOptions &&
                            setOptions.map((opt, index) => (
                              <MenuItem key={opt.value} value={opt.value}>{opt.label}</MenuItem>
                            ))
                          }
                        </Select>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container className="holder_set set_full">
                    <Grid className={setAction === "shop" ? "checkbox-border" : "checkbox"} item xs={12} md={12} onClick={() => { handleSetChange("shop") }}>
                      <Grid className="set_title">
                        <Typography variant="h6">Shop Drawing</Typography>
                        <IconButton >
                          {setAction === "shop" ? (
                            <CheckCircleIcon style={{ color: "#346EF5" }} />
                          ) : (
                            <RadioButtonUncheckedIcon />
                          )}
                        </IconButton>
                      </Grid>
                      <Grid className="holder_shop">
                        <Typography variant="body2" className="upload_p">
                          Only choose this option if you are planning to upload
                          shop drawings.
                        </Typography>
                        <div className="form_group">
                          <InputLabel>Type of Shop Drawing</InputLabel>
                          <Select disabled={setAction !== "shop"}
                            style={{ backgroundColor: setAction !== "shop" ? "#EAEAEA" : "white", width: "50%" }}
                            className="select_cstm"
                            label="Select"
                            value={shopDrawingValue}
                            onChange={(event) => {
                              setShopDrawingValue(event?.target?.value);
                            }}>
                            <MenuItem value="none">
                              <em>Select</em>
                            </MenuItem>

                            {shopDrawingTypes &&
                              shopDrawingTypes.map((opt, index) => (
                                <MenuItem key={opt} value={opt}>{opt}</MenuItem>
                              ))
                            }
                          </Select>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
              {(activeStep === 2 || activeStep === 3) && (
                <>
                  <div ref={pdfContainerRef} style={{ display: 'none' }}></div>
                  {activeStep === 3 &&
                    <Button onClick={() => setDisciplineSettingModal(true)} disabled={isLoading} className="discipline" variant="outlined">
                      <img src={SettingIcon} alt="save_icon" />
                      Discipline Settings
                    </Button>
                  }
                  <div className="upload_holder">
                    <Typography variant="h6">{activeStep === 2 ? 'Define Sheet Numbers' : 'Title and Discipline'}</Typography>
                    <Typography variant="body2" className="upload_p">
                      {activeStep === 2 ? `Please review sheet numbers below. If they are not correct, you may edit it by clicking edit button.`
                        : `Please review or define sheet title and discipline for your files/pages.`}
                    </Typography>
                  </div>
                  {pdfRowErrors && (pdfRowErrors.duplicates || pdfRowErrors.empties || pdfRowErrors.discipline_empties) &&
                    <div style={{ background: "#F928280F", border: "1px solid #F92828", justifyContent: "space-between", display: "flex", padding: "5px 10px", marginTop: "10px" }}>
                      <div style={{ color: "#F92828", display: "flex" }}>
                        <div>
                          <img src="error-red.svg" alt="error" />
                        </div>
                        <div style={{ paddingTop: "1px", paddingLeft: "5px" }}>
                          {pdfRowErrors && pdfRowErrors.duplicates && pdfRowErrors.empties && pdfRowErrors.discipline_empties ? pdfRowErrors.duplicates.length + pdfRowErrors.empties.length + pdfRowErrors.discipline_empties.length : pdfRowErrors && pdfRowErrors.discipline_empties && pdfRowErrors.empties ? pdfRowErrors.empties.length + pdfRowErrors.discipline_empties.length : pdfRowErrors && pdfRowErrors.duplicates && pdfRowErrors.empties ? pdfRowErrors.empties.length + pdfRowErrors.duplicates.length : pdfRowErrors && pdfRowErrors.duplicates ? pdfRowErrors.duplicates.length : pdfRowErrors.discipline_empties ? pdfRowErrors.discipline_empties.length : pdfRowErrors.empties ? pdfRowErrors.empties.length : 0} sheet with error
                        </div>
                      </div>
                      <div style={{ color: "#505050", justifyContent: "flex-end" }}>
                        <Checkbox checked={showErrorFiles} onChange={handleShowErrorFiles} style={{ color: "#D8D8D8" }} /> Only show sheets with error
                      </div>
                    </div>
                  }
                  <Grid container className="holder_list">
                    <Box className="hold_head_btn">
                      <Box className="selected_items">{selectedPDFRowIds?.length} Selected</Box>
                      <Box className="btn_sheetgroup">
                        <Button className={"sheet_button " + (pageIndexForExtraction.length < 1 ? "sheet_button_disable" : "")} disabled={pageIndexForExtraction.length < 1} variant="outlined" onClick={() => {
                          setExtractNumber(true);
                        }} >
                          <Checkbox style={{ color: "#D8D8D8" }} />
                          {activeStep === 2 ? ` Define Sheet Number Area` : ` Define Title`}
                        </Button>
                        <Button className={'sheet_button ' + ((selectedPDFRowIds.length < 1 || fileSplitRows.length == selectedPDFRowIds.length || fileSplitRows.length == 1) ? "sheet_button_disable" : "")} variant="outlined" disabled={(selectedPDFRowIds.length < 1 || fileSplitRows.length == selectedPDFRowIds.length || fileSplitRows.length == 1)} onClick={() => setDeleteDialog(true)}>
                          <img src={(selectedPDFRowIds.length < 1 || fileSplitRows.length == selectedPDFRowIds.length || fileSplitRows.length == 1) ? "delete_disable.svg" : DeleteIcon} alt="delete_icon" />
                          Delete Sheets
                        </Button>
                      </Box>
                    </Box>
                    <Box sx={{ width: "100%" }} id="dataGridContainer">
                      {isLoadingTable &&
                        <div style={{ height: "500px", alignItems: 'center', justifyContent: 'center' , display: 'flex'}}>
                          <Loader />
                        </div>
                      }
                      {fileSplitRows?.length > 0 && !isLoadingTable &&
                     
                     <DataGrid
                     style={{ maxHeight: "500px" }}
                     className="add-sheet_extract-table"
                     rows={showErrorFiles ? errorFiles : fileSplitRows}
                     columns={activeStep === 2 ? columns : columnsLastStep}
                     pageSize={100}
                     initialState={{
                       columns,
                       pagination: { paginationModel: { pageSize: 100 } },
                     }}
                     checkboxSelection
                     disableColumnMenu={true}
                     columnVisibilityModel={activeStep === 3 && setAction === 'shop' ? { id: false, disciplineId: false } : { id: false, }}
                     disableRowSelectionOnClick
                     rowHeight={112}
                     autoheight={true}
                     disableVirtualization
                     rowSelectionModel={selectedPDFRowIds}
                     onRowSelectionModelChange={(newSelectionIds) => {
                       if (newSelectionIds?.length === 0) {
                         setPageIndexForExtraction([]);
                       } else {
                         setPageIndexForExtraction(newSelectionIds.sort());
                       }
                       setSetSelectedPDFRowIds(newSelectionIds);
                     }}
                     slots={{
                       footer: CustomFooter,
                     }}
                   />
                      }
                    </Box>
                  </Grid>
                </>
              )}
              <Box className="actions action_button">
                {activeStep > 0 && !(activeStep === 2 && bState?.attachId) &&
                  <Button onClick={handleBack} variant="contained" className="back_button">
                    Back: {stepToDetail[activeStep].backLabel}
                  </Button>
                }
                {(activeStep == 2 || activeStep == 3) &&
                  <Button className="back_button" variant="contained" disabled={isLoading} onClick={() => setSaveAsDraftDialog(true)}>
                    <img src={SaveIcon} alt="save_icon" />
                    Save as Draft
                  </Button>
                }
                <Button variant="contained" className="next_button" onClick={onClickNext} disabled={!activeNextStep}> {/* */}
                  {activeStep < 4 ? 'Next: ' : ''}{stepToDetail[activeStep].nextLabel}
                </Button>
              </Box>
            </Box>
          </div>
        </Grid>
      </Grid>
      <DisciplineDialog
        credential={credential}
        setDisciplineSettingModal={setDisciplineSettingModal}
        disciplineSettingModal={disciplineSettingModal}
        disciplineList={disciplineList}
        onRefreshDiscipline={getDisciplineOptions}
      />
      <SaveDraftDialog
        saveAsDraftDialog={saveAsDraftDialog}
        setSaveAsDraftDialog={setSaveAsDraftDialog}
        onAccept={() => {
          onClickSaveAsDraft(true);
          setSaveAsDraftDialog(false);
        }}
      />
      {(deleteDialog ) &&
        <ConfirmDialog
          onCancel={() => {
            setDeleteDialog(false);
          }}
          onAccept={async () => {
          
              const updatedItems = fileSplitRowsRef.current.filter((item) => !selectedPDFRowIds.includes(item.id));
              setFileSplitRows_(updatedItems);
              setSetSelectedPDFRowIds([]);
              checkStepAndErros();
              setDeleteDialog(false);
              onClickSaveAsDraft(false);
          }}
          DialogData={confirmDialogData}
        />
      }
      {extractNumber && selectedAttach?.length > 0 &&
        <ExtractNumber credential={credential}
          attachId={selectedAttach[0].attachId}
          defineSheetNumberDialog={defineSheetNumberDialog}
          setDefineSheetNumberDialog={setDefineSheetNumberDialog}
          s3Url={selectedAttach[0].s3Url}
          fileUrl={selectedAttach[0].url}
          section={activeStep == 2 ? 'number' : 'title'}
          allRowCount={showErrorFiles ? errorFiles.length : fileSplitRows.length}
          selectedPageIndexs={pageIndexForExtraction}
          extractNumber={extractNumber} setExtractNumber={setExtractNumber}
          cropLabel={cropLabel} setCropLabel={setCropLabel}
          onExtractSave={onExtractSave}
          onStartBackgroundExtracting={onStartBackgroundExtracting} />
      }
    </AddSheetComponentStyles>
  );
}
export function BinderFile({ folderId, attachId, document, index, credential, onSuccess, removeFile, setAttach, attach }) {
  const [fileStatus, setStatus] = useState({ uploading: true, status: 'not_started', progress: 0 });
  
  useEffect(() => {
    if (folderId) {
      if (!attachId) {
        onClickUpload();
      } else {
        setStatus({ uploading: false, status: 'success', progress: 100 });
      }
    }
  }, []);
  const onClickUpload = async () => {
    if (document && folderId && credential?.projectId) {
      setStatus({ uploading: true, status: 'in_progress', progress: 0 });
      processUploadBinder();
    } else {
      setStatus({ uploading: false, status: 'failed', progress: 100 });
    }
  };
  const processUploadBinder = async () => {
    let coun = 0;
    let countLimit = 5;
    let prg = 0;
    const inter = setInterval(async () => {
      if (countLimit == coun || prg > 80 || fileStatus.uploading == false) {
        if (inter) {
          clearInterval(inter);
        }
        return;
      } else {
        setStatus((_sts) => {
          prg = _sts.progress + (_sts.progress > 60 ? 10 : 20);
          return { ..._sts, ...{ progress: prg } }
        });
        coun++
      }
    }, 1200);
    const formData = new FormData();
    formData.append("binderFile", document, document?.name);
    await axios.post(`${fetchUrl}/add/binder/${credential?.projectId}/${folderId}?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`, formData)
      .then(async (res) => {
        //console.log('add binder response ',JSON.stringify(res?.data));
        if (res?.data?.ret?.Id) {
          var reqBody = { sheetCount: 0 };
          axios.put(`${fetchUrl}/publish/start/${res?.data?.ret?.Id}`, reqBody).then((res) => {

          }).catch((err) => {
            console.log(err);
          });
          let sts = { uploading: false, status: 'success', progress: 100 };
          setAttach(res.data.ret);
          onSuccess({ ...res.data.ret, ...{ fileVersionId: res.data.fileVersionId, url: res.data.url } }, index, sts);
          setStatus(sts);
        } else {
          setStatus({ uploading: false, status: 'failed', progress: 100 });
        }
      }).catch((err) => {
        setStatus({ uploading: false, status: 'failed', progress: 100 });
        console.log("err", err);
      });
  };
  return (
    <AddSheetComponentStyles>
      <li key={index + 1}>
        <div className="file_name">
          {fileStatus?.uploading ? (<img src={PdfIcon} alt="pdf_icon" />) : (
            <img src="/pdfdark.svg" alt="pdf_icon" />
          )}
          <p style={fileStatus?.uploading ? { color: "#929292" } : {}}
            className={fileStatus?.uploading ? "file_name_incomplete" : "file_name_complete"} >
            {document?.path}
          </p>
        </div>
        <div className="file_status">
          <div className={fileStatus?.uploading ? "upload_inprogress" : (fileStatus?.status == 'success' ? "upload_completed" : "upload_failed")}>
            {fileStatus?.uploading &&
              <>
                {fileStatus?.status == 'not_started' &&
                  <>
                    <p className="progress_value">click to upload</p>
                    <img src={UploadIcon} alt="upoad_icon" onClick={onClickUpload} />
                  </>
                }
                {fileStatus?.status != 'not_started' &&
                  <>
                    <p className="progress_value">{fileStatus.progress}%</p>
                    <BorderLinearProgress variant="determinate" value={fileStatus.progress} />
                  </>
                }
              </>
            }
            {fileStatus?.uploading == false &&
              <>
                {fileStatus?.status == 'success' &&
                  <>
                    <p>Upload Completed</p>
                    <img src={CheckIcon} alt="pdf_icon" />
                  </>
                }
                {fileStatus?.status == 'failed' &&
                  <>
                    <p>Upload Failed</p>
                  </>
                }
              </>
            }
          </div>
          <img className="imageHover"
            src={fileStatus?.uploading ? CloseIcon : fileStatus.status == 'success' ? DeleteIcon : ResetIcon} alt="icons"
            onClick={() => {
              if (fileStatus?.status == 'failed') {
                onClickUpload();
              } else {
                if (attach?.Id) {
                  axios.delete(`${fetchUrl}/delete/Amazon_S3_Attachment__c/${attach.Id}?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`)
                    .then((res) => {
                    }).catch((err) => {
                      console.log(err);
                    });
                }
                removeFile(index);
              }
            }}
          />
        </div>
      </li>
    </AddSheetComponentStyles>
  );
};

AddSheetsComponent.propTypes = {
  window: PropTypes.func,
};

export default AddSheetsComponent;