import React, { useRef, useEffect, useState } from "react";
import axios from "axios";
import JSZip from 'jszip';
import { saveAs } from "file-saver";
import { fetchUrl } from "Urls";
import { Document, Page, Text, PDFViewer, View, StyleSheet, Image, pdf, Font } from '@react-pdf/renderer'
import moment from "moment";
const ChecklistFormPreviewExport = ({ uiId, onClose, credential, formIds, processed, zipName, uploadPDFToAmazon }) => {
   const [sfObj, setSfObj] = useState();
   const [checklistFormDetails, setChecklistFormDetails] = useState([]);
   Font.register({
      family: 'Poppins',
      src: 'https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap'
    });
   useEffect(() => {
      console.log('uiId :: ', uiId, 'formIds.length', formIds?.length, processed);
      if (!processed && formIds) {
         const zip = new JSZip();
         getFormDetails(formIds, -1, zip);
      }
   }, []);
   async function getFormDetails(_formIds, count, zip) {
      count++;
      axios.get(`${fetchUrl}/getProjectFormDetail/${_formIds[count]}`)
         //eslint-disable-next-line no-loop-func
         .then((res) => {
            if (res.status === 200) {
               if (res.data?.formObj) {
                  if (res.data.docDetail) {
                     if (res.data.formObj.Form_Template__c) {
                        setChecklistFormDetails(res.data.docDetail);
                     }
                     setSfObj(res.data.formObj);
                     generateDownloadPDF(res.data.formObj, count, zip, res.data.docDetail);
               }
            }
            }
         })
         .catch((err) => {
            console.log(err);
         });
   }
 
   const generateDownloadPDF = async (recordData, count, zip, formDetails) => {
      const MyDocument = () => (
         <Document>
                  <Page size={'A4'} style={styles.a4Page} wrap>
                     <View fixed render={({ pageNumber, totalPages }) => (
                        pageNumber > 1 && (
                           <View style={styles.pageHeader}>
                              <Text style={styles.greyText}>
                                 {credential?.projectName} | {recordData?.Name}
                              </Text>
                              <Text style={styles.greyText}>
                                 {recordData?.Form_Template__r?.Form_Type__c} | {recordData?.Status__c}
                              </Text>
                           </View>
                        )
                     )} />
                     <View style={styles.pdfHeader}>
                        <View>
                           <Text style={styles.greyText}>
                              {credential?.projectName}
                           </Text>
                           <Text style={styles.blackTextLarge}>
                              {recordData?.Name}
                           </Text>
                           <View style={styles.blueLine}>
                           </View>
                        </View>
                        <View style={{width: "40px", height: "40px" }}>
                        <Image  src={'/clogo192.png'}/>
                        </View>
                     </View>
                     <View style={styles.formDetailsBox}>
                        <View style={styles.formDetailsHeading}>
                           <Text style={styles.blackText}>Form Details</Text>
                        </View>
                        <View>
                           <View style={styles.formDetailsRow}>
                              <Text style={styles.formDetailsField}>Id</Text>
                              <Text style={styles.formDetailsFieldValue}>{recordData?.Numbering_Sequence__c}</Text>
                           </View>
                           <View style={styles.formDetailsRow}>
                              <Text style={styles.formDetailsField}>Form Type</Text>
                              <Text style={styles.formDetailsField}>{recordData?.Form_Template__r?.Form_Type__c}</Text>
                           </View>
                           <View style={styles.formDetailsRow}>
                              <Text style={styles.formDetailsField}>Category</Text>
                              <Text style={styles.formDetailsField}>{recordData?.Form_Template__r?.Template_Category__c}</Text>
                           </View>
                           <View style={styles.formDetailsRow}>
                              <Text style={styles.formDetailsField}>Description</Text>
                              <Text style={styles.formDetailsField}>{recordData?.Description__c}</Text>
                           </View>
                           <View style={styles.formDetailsRow}>
                              <Text style={styles.formDetailsField}>Status</Text>
                              <Text style={styles.formDetailsField}>{recordData?.Status__c}</Text>
                           </View>
                           <View style={styles.formDetailsRow}>
                              <Text style={styles.formDetailsField}>Location</Text>
                              <Text style={styles.formDetailsField}>-</Text>
                           </View>
                           <View style={styles.formDetailsRow}>
                              <Text style={styles.formDetailsField}>Ball in court</Text>
                              <Text style={styles.formDetailsField}>{recordData?.Ball_in_Court__r?.Name}</Text>
                           </View>
                           <View style={styles.formDetailsRow}>
                              <Text style={styles.formDetailsField}>Due date</Text>
                              <Text style={styles.formDetailsField}>{recordData?.Date__c ? moment(recordData?.Date__c).format('MM/DD/YYYY') : ''}</Text>
                           </View>
                           <View style={styles.formDetailsRow}>
                              <Text style={styles.formDetailsField}>Last Updated By</Text>
                              <Text style={styles.formDetailsField}>{recordData?.LastModifiedBy?.Name}</Text>
                           </View>
                           <View style={styles.formDetailsRow}>
                              <Text style={styles.formDetailsField}>Last Updated</Text>
                              <Text style={styles.formDetailsField}>{moment(recordData?.LastModifiedDate).format('MM/DD/YYYY')}</Text>
                           </View>
                           <View style={styles.formDetailsRow}>
                              <Text style={styles.formDetailsField}>Created By</Text>
                              <Text style={styles.formDetailsField}>{recordData?.CreatedBy?.Name}</Text>
                           </View>
                        </View>
                     </View>
                     <View break>
                        {formDetails?.length > 0 && formDetails.map(section => (
                           section.Name === "Signature" ? (
                              <View key={section.Id}>
                                 <View style={{ borderBottom: "1px solid #BABABA", display: "flex", justifyContent: "space-between", flexDirection: 'row', paddingBottom: '8px' }}>
                                    <Text style={{ fontSize: '12px', fontWeight: 600, color: '#000' }}>Signature</Text>
                                    <Text style={styles.greyText}>{section.items.length} Signees</Text>
                                 </View>
                                 {section.items.map(Question => (
                                    <View key={Question.Id} style={{ marginTop: 8, marginBottom: 8 }}>
                                       <View style={{ display: 'flex', flexDirection: 'row' }}>
                                          <Text style={{ fontSize: '12px', fontWeight: 600, color: '#000' }}>{Question.Question__c}</Text>
                                          <Text style={Question.Optional__c ? styles.optionalText : styles.requiredText}>
                                             {Question.Optional__c ? "(optional)" : "*"}
                                          </Text>
                                       </View>
                                       <Text style={{ fontSize: '12px', fontWeight: 400, color: '#505050', marginTop: !Question.Optional__c ? '-3px'  : '3px', marginBottom: '8px' }}>{Question.Description__c}</Text>
                                       <Image src={Question.ans.Signature__c} style={styles.signatureImage} />
                                       <View style={{ marginTop: 8 }}>
                                          <Text style={styles.greyText}>
                                             Signature By: {Question.ans.Print_Name__c}
                                          </Text>
                                          <Text style={styles.greyText}>
                                             Company: {Question.ans.Company_Name__c}
                                          </Text>
                                          <Text style={styles.greyText}>
                                             Signed on: May 20 2054 21:59
                                          </Text>
                                       </View>
                                    </View>
                                 ))}
                              </View>
                           ) : (
                              <View key={section.Id}>
                                 <View style={{ borderBottom: "1px solid #BABABA", display: "flex", justifyContent: "space-between", flexDirection: 'row', paddingBottom: '8px' }}>
                                    <Text style={{ fontSize: '12px', fontWeight: 600, color: '#000' }}>Section {section?.Numbering_Sequence__c} of {formDetails.length}</Text>
                                    <Text style={styles.greyText}>{section.items.length} questions</Text>
                                 </ View>
                                 <View style={{ marginTop: 8, marginBottom: 8 }}>
                                    <Text style={{ fontSize: '12px', fontWeight: 600, color: '#000', marginBottom: '2px' }}>{section?.Name}</Text>
                                    <Text style={styles.greyText}>{section?.Description__c}</Text>
                                 </View>
                                 {section.items.map(Question => (
                                    <View key={Question.Id} style={styles.questionContainer}>
                                       <Text style={{ fontSize: '10px', fontWeight: 600, color: '#929292', marginBottom: '8px' }}>Question {Question.Numbering_Sequence__c} of {section.items.length}</Text>
                                       <View style={{ display: 'flex', flexDirection: 'row' }}>
                                          <Text style={{ fontSize: '12px', fontWeight: 600, color: '#000' }}>{Question.Question__c}</Text>
                                          <Text style={Question.Optional__c ? styles.optionalText : styles.requiredText}>
                                             {Question.Optional__c ? "(optional)" : "*"}
                                          </Text>
                                       </View>
                                       <Text style={{ fontSize: '12px', fontWeight: 400, color: '#505050', marginTop: !Question.Optional__c ? '-3px'  : '3px', marginBottom: '8px' }}>{Question.Description__c}</Text>
                                       {Question.Response_Type__c === "Preconfigured" && (
                                          <View break>
                                             <Text style={{ fontSize: '12px', fontWeight: 600, color: '#000' }}>Response:</Text>
                                             <Text style={{ fontSize: '12px', fontWeight: 400, color: '#505050', marginTop: '2px' }}>{Question.ans.Response__c}</Text>
                                          </View>
                                       )}
                                       {(["Text", "Number", "Date", "Dropdown"].includes(Question.Response_Type__c)) && (
                                          <Text break style={styles.responseContainer}>{Question.ans.Response__c}</Text>
                                       )}
                                       {Question.Response_Type__c === "Checkbox" && (
                                          <View break style={{display: 'flex', flexDirection: 'column', gap: '2px'}}>
                                             {Question.responseOptions.map((r) => (
                                                <View key={r.value} style={{ display: 'flex', flexDirection: 'row', color: 'black', gap: 10, padding: '2px 0px', alignItems: 'center' }}>
                                                   <Image style={{height: '20px', width: '20px'}} src={Question.ans.Response__c?.includes(r.value) ? '/selectedCheckbox.png' : '/unselectedCheckbox.png'} />
                                                   <Text style={styles.greyText}>{r.label}</Text>
                                                </View>
                                             ))}
                                          </View>
                                       )}
                                       {Question.Response_Type__c === 'Multiple Choice' && (
                                          <View break style={{display: 'flex', flexDirection: 'column', gap: '3px'}}>
                                             {Question.responseOptions.map((r) => (
                                                <View key={r.key} style={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>
                                                   <Image style={{height: '20px', width: '20px'}} src={Question.ans.Response__c === r.value ? '/selectedRadio.png' : '/unselectedRadio.png'} />
                                                   <Text style={styles.greyText}>{r.label}</Text>
                                                </View>
                                             ))}
                                          </View>
                                       )}
                                       {Question.ans && Question.ans?.attachments && Question.ans?.attachments?.length > 0 && (
                                          <View style={styles.attachmentContainer} break>
                                             <Text style={styles.greyText}>Images ({Question.ans?.attachments.length})</Text>
                                             <View style={styles.gridContainer}>
                                                {Question.ans?.attachments?.map((val) => (
                                                   <View key={val.Id} style={styles.gridItem}>
                                                      <Image style={styles.attachmentImage} src={val.fileurl} />
                                                      <Text style={styles.attachmentFileName}>
                                                         {val.File_Name__c}
                                                      </Text>
                                                   </View>
                                                ))}
                                             </View>
                                          </View>
                                       )}
                                       {Question.ans && Question.ans?.issues && Question.ans?.issues?.length > 0 && (
                                          <View style={styles.issueContainer} break>
                                             <Text style={styles.greyText}>Issues ({Question.ans?.issues.length})</Text>
                                             {Question.ans?.issues?.map((val) => (
                                                <View key={val.Id} style={{ width: '100%', height: 'auto', padding: 8, marginBottom: 12 }}>
                                                   <Text style={{ color: '#505050' }}>{val?.Numbering_Sequence__c}</Text>
                                                   <Text style={{ color: '#000000', fontWeight: 400 }}>{val?.Name}</Text>
                                                </View>
                                             ))}
                                          </View>
                                       )}
                                    </View>
                                 ))}
                              </View>
                           )
                        ))}

                     </View>
                     <View style={{ marginTop: 'auto', display: 'flex' }} fixed render={({ pageNumber, totalPages }) => (
                        <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', borderTop: '1px solid #505050', paddingTop: '8px', marginTop: '8px' }}>
                           <Text style={styles.greyText}>
                              Form Detailed Report
                           </Text>
                           <Text style={styles.greyText}>
                              Page {pageNumber} of {totalPages}
                           </Text>
                        </View>
                     )} />
                  </Page>
               </Document>
      );
  
      // Render the PDF document to a blob
      const pdfBlob = await pdf(<MyDocument />).toBlob();
  
      if (formIds.length === 1) {
          if (uploadPDFToAmazon === true) {
              uploadExportedChecklistFormToAmazon(recordData, pdfBlob);
          } else {
              saveAs(pdfBlob, `${recordData.Name}.pdf`);
          }
          onClose(uiId, true);
      } else {
          if (zip) {
              zip.file(`${recordData.Name}.pdf`, pdfBlob);
              if (count + 1 === formIds.length) {
                  zip.generateAsync({ type: 'blob' }).then((content) => {
                      const zipName_ = zipName ? zipName : `${credential?.projectName} Forms.zip`;
                      saveAs(content, zipName_);
                      onClose(uiId, true);
                  });
              } else {
                  getFormDetails(formIds, count, zip);
              }
          }
      }
  };
   const uploadExportedChecklistFormToAmazon = async (formObj, blobData) => {
      const formData = new FormData();
      formData.append("uploadedFile", blobData, `${formObj.Name}.pdf`);
      formData.append("fileTitle", `${formObj.Name}.pdf`);
      formData.append("formId", formObj.Id);
      await axios.post(`${fetchUrl}/upload/checklist/${formObj.Id}`, formData)
         .then((res) => {
            if (res.data) {
               console.log('checklist pdf uploaded ::');
            }
         }).catch((err) => {
            console.log('upload checklist pdf error ::', err);
         });
   };

   const styles = StyleSheet.create({
      questionContainer: {
         border: '1px solid #D8D8D8',
         padding: 10,
         borderRadius: 4,
         marginBottom: 8,
      },
      optionalText: {
         color: '#D8D8D8',
         fontSize: 12,
         fontWeight: 400,
         marginLeft: 5
      },
      requiredText: {
         color: 'red',
      },
      responseContainer: {
         color: '#505050',
         border: '1px solid #D8D8D8',
         height: 37,
         paddingTop: 10,
         paddingLeft: 8,
         marginTop: 5,
         fontSize: '12px',
         fontWeight: 400,
      },
      signatureImage: {
         width: 255,
         height: 101,
         border: '1px solid #D8D8D8',
      },
      attachmentContainer: {
         marginTop: 8,
         border: '1px solid #EAEAEA',
         backgroundColor: '#F8F8F8',
         padding: 12,
      },
      gridContainer: {
         display: 'flex',
         flexDirection: 'row',
         flexWrap: 'wrap',
         gap: '8px', // Adjust the gap between grid items
         marginTop: '8px',
      },
      gridItem: {
         width: '32%', // Adjust the width to fit 2 items per row (50% - gap)
         border: '1px solid #D8D8D8',
         backgroundColor: 'white',
         padding: 8,
         marginBottom: 12,
         height: 160
      },
      attachmentImage: {
         width: '100%',
         height: 121,
         maxHeight: '121px', // Adjust the height as needed
      },
      attachmentFileName: {
         color: '#505050',
         fontSize: '10px',
         marginTop: '4px',
         whiteSpace: 'nowrap',
         overflow: 'hidden',
         textOverflow: 'ellipsis',
      },

      issueContainer: {
         marginTop: 20,
         border: '1px solid #EAEAEA',
         backgroundColor: '#F8F8F8',
         padding: 12,
      },


      a4Page: {
         padding: 50
      },
      pageHeader: {
         display: 'flex',
         flexDirection: 'row', 
         justifyContent: 'space-between', 
         borderBottom: '1px solid #505050', 
         paddingBottom: '8px', 
         marginBottom: '16px'
      },
      greyText: {
         fontSize: '12px', 
         fontWeight: 400, 
         color: '#505050'
      },
      pdfHeader: {
         display: "flex", 
         justifyContent: "space-between", 
         flexDirection: 'row' 
      },
      blackTextLarge: {
         color: "#00000", 
         fontSize: "20px", 
         fontWeight: 600, 
      },
      blackText: {
         color: "#00000", 
         fontSize: "12px", 
         fontWeight: 600, 
      },
      blueLine: {
         backgroundColor: "#346EF5", 
         width: "58px", 
         height: "4px", 
         marginTop: "24px"
      },
      formDetailsBox: {
         marginTop: "24px" 
      },
      formDetailsHeading: {
         paddingBottom: "8px", 
         borderBottom: "1px solid #BABABA", 
         marginBottom: "8px"
      },
      formDetailsRow: {
         display: 'flex', 
         flexDirection: 'row', 
         gap: '48px', 
         paddingBottom: '10px', 
         paddingTop: '10px', 
         borderBottom: '1px solid #EAEAEA'
      },
      formDetailsField: {
         width: '110px', 
         color: '#505050', 
         fontSize: '12px', 
         fontWeight: 400 
      },
      formDetailsFieldValue: {
         color: "#000000", 
         fontSize: '12px', 
         fontWeight: 400
      }

   });

   return (
      <></>
      // <div style={{ position: "absolute", width: '100%' }}>
      //    {sfObj && sfObj.Id &&
      //       <PDFViewer style={{ width: '100%', height: "100vh", position: 'absolute' }}>
      //          <Document>
      //             <Page size={'A4'} style={styles.a4Page} wrap>
      //                <View fixed render={({ pageNumber, totalPages }) => (
      //                   pageNumber > 1 && (
      //                      <View style={styles.pageHeader}>
      //                         <Text style={styles.greyText}>
      //                            {credential?.projectName} | {sfObj?.Name}
      //                         </Text>
      //                         <Text style={styles.greyText}>
      //                            {sfObj?.Form_Template__r?.Form_Type__c} | {sfObj?.Status__c}
      //                         </Text>
      //                      </View>
      //                   )
      //                )} />
      //                <View style={styles.pdfHeader}>
      //                   <View>
      //                      <Text style={styles.greyText}>
      //                         {credential?.projectName}
      //                      </Text>
      //                      <Text style={styles.blackTextLarge}>
      //                         {sfObj?.Name}
      //                      </Text>
      //                      <View style={styles.blueLine}>
      //                      </View>
      //                   </View>
      //                   <View style={{width: "40px", height: "40px" }}>
      //                   <Image  src={'/clogo192.png'}/>
      //                   </View>
      //                </View>
      //                <View style={styles.formDetailsBox}>
      //                   <View style={styles.formDetailsHeading}>
      //                      <Text style={styles.blackText}>Form Details</Text>
      //                   </View>
      //                   <View>
      //                      <View style={styles.formDetailsRow}>
      //                         <Text style={styles.formDetailsField}>Id</Text>
      //                         <Text style={styles.formDetailsFieldValue}>{sfObj?.Numbering_Sequence__c}</Text>
      //                      </View>
      //                      <View style={styles.formDetailsRow}>
      //                         <Text style={styles.formDetailsField}>Form Type</Text>
      //                         <Text style={styles.formDetailsField}>{sfObj?.Form_Template__r?.Form_Type__c}</Text>
      //                      </View>
      //                      <View style={styles.formDetailsRow}>
      //                         <Text style={styles.formDetailsField}>Category</Text>
      //                         <Text style={styles.formDetailsField}>{sfObj?.Form_Template__r?.Template_Category__c}</Text>
      //                      </View>
      //                      <View style={styles.formDetailsRow}>
      //                         <Text style={styles.formDetailsField}>Description</Text>
      //                         <Text style={styles.formDetailsField}>{sfObj?.Description__c}</Text>
      //                      </View>
      //                      <View style={styles.formDetailsRow}>
      //                         <Text style={styles.formDetailsField}>Status</Text>
      //                         <Text style={styles.formDetailsField}>{sfObj?.Status__c}</Text>
      //                      </View>
      //                      <View style={styles.formDetailsRow}>
      //                         <Text style={styles.formDetailsField}>Location</Text>
      //                         <Text style={styles.formDetailsField}>-</Text>
      //                      </View>
      //                      <View style={styles.formDetailsRow}>
      //                         <Text style={styles.formDetailsField}>Ball in court</Text>
      //                         <Text style={styles.formDetailsField}>{sfObj?.Ball_in_Court__r?.Name}</Text>
      //                      </View>
      //                      <View style={styles.formDetailsRow}>
      //                         <Text style={styles.formDetailsField}>Due date</Text>
      //                         <Text style={styles.formDetailsField}>{sfObj?.Date__c ? moment(sfObj?.Date__c).format('MM/DD/YYYY') : ''}</Text>
      //                      </View>
      //                      <View style={styles.formDetailsRow}>
      //                         <Text style={styles.formDetailsField}>Last Updated By</Text>
      //                         <Text style={styles.formDetailsField}>{sfObj?.LastModifiedBy?.Name}</Text>
      //                      </View>
      //                      <View style={styles.formDetailsRow}>
      //                         <Text style={styles.formDetailsField}>Last Updated</Text>
      //                         <Text style={styles.formDetailsField}>{moment(sfObj?.LastModifiedDate).format('MM/DD/YYYY')}</Text>
      //                      </View>
      //                      <View style={styles.formDetailsRow}>
      //                         <Text style={styles.formDetailsField}>Created By</Text>
      //                         <Text style={styles.formDetailsField}>{sfObj?.CreatedBy?.Name}</Text>
      //                      </View>
      //                   </View>
      //                </View>
      //                <View break>
      //                   {checklistFormDetails?.length > 0 && checklistFormDetails.map(section => (
      //                      section.Name === "Signature" ? (
      //                         <View key={section.Id}>
      //                            <View style={{ borderBottom: "1px solid #BABABA", display: "flex", justifyContent: "space-between", flexDirection: 'row', paddingBottom: '8px' }}>
      //                               <Text style={{ fontSize: '12px', fontWeight: 600, color: '#000' }}>Signature</Text>
      //                               <Text style={styles.greyText}>{section.items.length} Signees</Text>
      //                            </View>
      //                            {section.items.map(Question => (
      //                               <View key={Question.Id} style={{ marginTop: 8, marginBottom: 8 }}>
      //                                  <View style={{ display: 'flex', flexDirection: 'row' }}>
      //                                     <Text style={{ fontSize: '12px', fontWeight: 600, color: '#000' }}>{Question.Question__c}</Text>
      //                                     <Text style={Question.Optional__c ? styles.optionalText : styles.requiredText}>
      //                                        {Question.Optional__c ? "(optional)" : "*"}
      //                                     </Text>
      //                                  </View>
      //                                  <Text style={{ fontSize: '12px', fontWeight: 400, color: '#505050', marginTop: !Question.Optional__c ? '-3px'  : '3px', marginBottom: '8px' }}>{Question.Description__c}</Text>
      //                                  <Image src={Question.ans.Signature__c} style={styles.signatureImage} />
      //                                  <View style={{ marginTop: 8 }}>
      //                                     <Text style={styles.greyText}>
      //                                        Signature By: {Question.ans.Print_Name__c}
      //                                     </Text>
      //                                     <Text style={styles.greyText}>
      //                                        Company: {Question.ans.Company_Name__c}
      //                                     </Text>
      //                                     <Text style={styles.greyText}>
      //                                        Signed on: May 20 2054 21:59
      //                                     </Text>
      //                                  </View>
      //                               </View>
      //                            ))}
      //                         </View>
      //                      ) : (
      //                         <View key={section.Id}>
      //                            <View style={{ borderBottom: "1px solid #BABABA", display: "flex", justifyContent: "space-between", flexDirection: 'row', paddingBottom: '8px' }}>
      //                               <Text style={{ fontSize: '12px', fontWeight: 600, color: '#000' }}>Section {section?.Numbering_Sequence__c} of {checklistFormDetails.length}</Text>
      //                               <Text style={styles.greyText}>{section.items.length} questions</Text>
      //                            </ View>
      //                            <View style={{ marginTop: 8, marginBottom: 8 }}>
      //                               <Text style={{ fontSize: '12px', fontWeight: 600, color: '#000', marginBottom: '2px' }}>{section?.Name}</Text>
      //                               <Text style={styles.greyText}>{section?.Description__c}</Text>
      //                            </View>
      //                            {section.items.map(Question => (
      //                               <View key={Question.Id} style={styles.questionContainer}>
      //                                  <Text style={{ fontSize: '10px', fontWeight: 600, color: '#929292', marginBottom: '8px' }}>Question {Question.Numbering_Sequence__c} of {section.items.length}</Text>
      //                                  <View style={{ display: 'flex', flexDirection: 'row' }}>
      //                                     <Text style={{ fontSize: '12px', fontWeight: 600, color: '#000' }}>{Question.Question__c}</Text>
      //                                     <Text style={Question.Optional__c ? styles.optionalText : styles.requiredText}>
      //                                        {Question.Optional__c ? "(optional)" : "*"}
      //                                     </Text>
      //                                  </View>
      //                                  <Text style={{ fontSize: '12px', fontWeight: 400, color: '#505050', marginTop: !Question.Optional__c ? '-3px'  : '3px', marginBottom: '8px' }}>{Question.Description__c}</Text>
      //                                  {Question.Response_Type__c === "Preconfigured" && (
      //                                     <View break>
      //                                        <Text style={{ fontSize: '12px', fontWeight: 600, color: '#000' }}>Response:</Text>
      //                                        <Text style={{ fontSize: '12px', fontWeight: 400, color: '#505050', marginTop: '2px' }}>{Question.ans.Response__c}</Text>
      //                                     </View>
      //                                  )}
      //                                  {(["Text", "Number", "Date", "Dropdown"].includes(Question.Response_Type__c)) && (
      //                                     <Text break style={styles.responseContainer}>{Question.ans.Response__c}</Text>
      //                                  )}
      //                                  {Question.Response_Type__c === "Checkbox" && (
      //                                     <View break style={{display: 'flex', flexDirection: 'column', gap: '2px'}}>
      //                                        {Question.responseOptions.map((r) => (
      //                                           <View key={r.value} style={{ display: 'flex', flexDirection: 'row', color: 'black', gap: 10, padding: '2px 0px', alignItems: 'center' }}>
      //                                              <Image style={{height: '20px', width: '20px'}} src={Question.ans.Response__c?.includes(r.value) ? '/selectedCheckbox.png' : '/unselectedCheckbox.png'} />
      //                                              <Text style={styles.greyText}>{r.label}</Text>
      //                                           </View>
      //                                        ))}
      //                                     </View>
      //                                  )}
      //                                  {Question.Response_Type__c === 'Multiple Choice' && (
      //                                     <View break style={{display: 'flex', flexDirection: 'column', gap: '3px'}}>
      //                                        {Question.responseOptions.map((r) => (
      //                                           <View key={r.key} style={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>
      //                                              <Image style={{height: '20px', width: '20px'}} src={Question.ans.Response__c === r.value ? '/selectedRadio.png' : '/unselectedRadio.png'} />
      //                                              <Text style={styles.greyText}>{r.label}</Text>
      //                                           </View>
      //                                        ))}
      //                                     </View>
      //                                  )}
      //                                  {Question.ans && Question.ans?.attachments && Question.ans?.attachments?.length > 0 && (
      //                                     <View style={styles.attachmentContainer} break>
      //                                        <Text style={styles.greyText}>Images ({Question.ans?.attachments.length})</Text>
      //                                        <View style={styles.gridContainer}>
      //                                           {Question.ans?.attachments?.map((val) => (
      //                                              <View key={val.Id} style={styles.gridItem}>
      //                                                 <Image style={styles.attachmentImage} src={val.fileurl} />
      //                                                 <Text style={styles.attachmentFileName}>
      //                                                    {val.File_Name__c}
      //                                                 </Text>
      //                                              </View>
      //                                           ))}
      //                                        </View>
      //                                     </View>
      //                                  )}
      //                                  {Question.ans && Question.ans?.issues && Question.ans?.issues?.length > 0 && (
      //                                     <View style={styles.issueContainer} break>
      //                                        <Text style={styles.greyText}>Issues ({Question.ans?.issues.length})</Text>
      //                                        {Question.ans?.issues?.map((val) => (
      //                                           <View key={val.Id} style={{ width: '100%', height: 'auto', padding: 8, marginBottom: 12 }}>
      //                                              <Text style={{ color: '#505050' }}>{val?.Numbering_Sequence__c}</Text>
      //                                              <Text style={{ color: '#000000', fontWeight: 400 }}>{val?.Name}</Text>
      //                                           </View>
      //                                        ))}
      //                                     </View>
      //                                  )}
      //                               </View>
      //                            ))}
      //                         </View>
      //                      )
      //                   ))}

      //                </View>
      //                <View style={{ marginTop: 'auto', display: 'flex' }} fixed render={({ pageNumber, totalPages }) => (
      //                   <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', borderTop: '1px solid #505050', paddingTop: '8px', marginTop: '8px' }}>
      //                      <Text style={styles.greyText}>
      //                         Form Detailed Report
      //                      </Text>
      //                      <Text style={styles.greyText}>
      //                         Page {pageNumber} of {totalPages}
      //                      </Text>
      //                   </View>
      //                )} />
      //             </Page>
      //          </Document>
      //       </PDFViewer>
      //    }
      // </div>
   );
};
export default ChecklistFormPreviewExport;