import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Dialog, MenuItem, Select, Box, TextField, InputAdornment, Button, Tooltip } from "@mui/material";
import { Search } from "@mui/icons-material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import { fetchUrl } from "Urls";
import moment from "moment";
import _, { cloneDeep } from "lodash";
import Spinner from "components/spinner";
import CompareFiles from "components/FolderTable/CompareFiles";
import SheetsIcon from 'assets/images/sheets_icon.svg'
const CompareStyle = styled.div` 
    menuItem: {
        "&:hover": {
          backgroundColor: "#009900 !important"
        }
    }
    .sheet-view-issues {
      
     }
     
     .modal-header {
       align-items: flex-start;
       background-color: var(--neutralswhite);
       border-bottom-style: solid;
       border-bottom-width: 1px;
       border-color: var(--neutralslight-grey-2);
       border-radius: 2px 2px 0px 0px;
       display: flex;
       flex: 0 0 auto;
       justify-content: space-between;
       overflow: hidden;
       padding: 16px 20px;
       position: relative;
       width: 400px;
     }
     
    .text-wrapper-6 {
       color: var(--neutralsblack);
       font-family: var(--common-font), Helvetica;
       font-size: 16px;
       font-weight: 600;
       letter-spacing: 0.16px;
       line-height: normal;
       margin-top: -1px;
       position: relative;
       width: fit-content;
     }
     
    .modal-body {
       align-items: flex-start;
       background-color: var(--neutralswhite);
       display: flex;
       flex: 0 0 auto;
       flex-direction: column;
       gap: 12px;
       justify-content: center;
       padding: 16px 20px;
       position: relative;
       width: 400px;
     }
     
     .please-select-the {
       color: var(--neutralsdark-grey);
       font-family: var(--common-font), Helvetica;
       font-size: 14px;
       font-weight: 400;
       letter-spacing: 0.14px;
       line-height: normal;
       margin-top: -1px;
       position: relative;
       width: 360px;
     }
     
     .span {
       color: #4f4f4f;
       font-family: var(--body-regular-font-family);
       font-size: var(--body-regular-font-size);
       font-style: var(--body-regular-font-style);
       font-weight: var(--body-regular-font-weight);
       letter-spacing: var(--body-regular-letter-spacing);
       line-height: var(--body-regular-line-height);
     }
     
     .text-wrapper-7 {
       font-family: var(--body-emphasize-font-family);
       font-size: var(--body-emphasize-font-size);
       font-style: var(--body-emphasize-font-style);
       font-weight: var(--body-emphasize-font-weight);
       letter-spacing: var(--body-emphasize-letter-spacing);
       line-height: var(--body-emphasize-line-height);
     }
     
    .references {
       align-items: flex-start;
       background-color: var(--neutralslight-grey-1);
       border: 1px solid;
       border-color: var(--neutralslight-grey-2);
       border-radius: 2px;
       display: inline-flex;
       flex: 0 0 auto;
       flex-direction: column;
       gap: 12px;
       margin-right: -8px;
       padding: 12px;
       position: relative;
     }
     
    .title {
       align-items: flex-start;
       display: flex;
       flex: 0 0 auto;
       justify-content: space-between;
       position: relative;
       width: 344px;
     }
     
    .frame-4 {
       align-items: flex-start;
       display: inline-flex;
       flex: 0 0 auto;
       gap: 4px;
       position: relative;
     }
     
   .text-wrapper-8 {
       color: var(--neutralsdark-grey);
       font-family: var(--body-small-font-family);
       font-size: var(--body-small-font-size);
       font-style: var(--body-small-font-style);
       font-weight: var(--body-small-font-weight);
       letter-spacing: var(--body-small-letter-spacing);
       line-height: var(--body-small-line-height);
       margin-top: -1px;
       position: relative;
       width: fit-content;
     }
     
    .content {
       align-items: center;
       display: flex;
       height: 59px;
       justify-content: space-between;
       position: relative;
       width: 344px;
     }
     
    .sheet-number {
       align-items: center;
       background-color: var(--neutralslight-grey-1);
       display: flex;
       gap: 12px;
       padding: 8px;
       position: relative;
       width: 209px;
     }
     
   .photo-and-title-wrapper {
       align-items: center;
       display: flex;
       flex: 1;
       flex-grow: 1;
       gap: 12px;
       position: relative;
     }
     
    .photo-and-title {
       align-items: flex-start;
       display: inline-flex;
       flex: 0 0 auto;
       gap: 12px;
       position: relative;
     }
     
   .thumbnail {
       height: 40px;
       object-fit: cover;
       position: relative;
       width: 56px;
     }
     
    .element-liner-text {
       align-items: flex-start;
       display: inline-flex;
       flex: 0 0 auto;
       flex-direction: column;
       gap: 2px;
       justify-content: center;
       position: relative;
     }
     
   .title-here {
       color: var(--neutralsblack);
       font-family: var(--common-font), Helvetica;
       font-size: 14px;
       font-weight: 600;
       letter-spacing: 0;
       line-height: normal;
       margin-top: -1px;
       width: 110px;
        position: relative;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
     }
     
   .version-2 {
       color: var(--neutralsdark-grey);
       font-family: var(--common-font), Helvetica;
       font-size: 12px;
       font-weight: 400;
       letter-spacing: 0;
       line-height: normal;
       position: relative;
       text-overflow: ellipsis;
       overflow: hidden;
       white-space: nowrap;
     }
     
   .textfield-2 {
    &:hover {
        background: #F8F8F8;
      }
       align-items: center;
       background-color: var(--neutralswhite);
      
       border-radius: 2px;
       display: flex;
       justify-content: space-between;
       padding: 8px 12px;
       position: relative;
       width: 128px;
       align-items: center;
       
    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8 ul li:nth-child(2){
        margin-bottom: 0px;
        padding-bottom: 0px;
    }
      .MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
        width: 110px;
      }
      .MuiOutlinedInput-notchedOutline {
        border-color: #D8D8D8 !important;
      }
      textarea {
        min-height: 37px;
        border: 1px solid #D8D8D8;
        padding: 8px 12px;
        border-radius: 2px;
        outline: none;
        color: #505050;
      }
      img {
        border: none;
        padding: 0;
      }
    }
    .file_field-error {
      align-items: center;
      .MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
        width: 110px;
      }
      .MuiOutlinedInput-notchedOutline {
        border-color: #D8D8D8 !important;
      }
      textarea {
        min-height: 37px;
        border: 1px solid red;
        padding: 8px 12px;
        border-radius: 2px;
        outline: none;
        color: #505050;
      }
      img {
        border: none;
        padding: 0;
      }
    }
    .MuiDataGrid-cell--withRenderer[data-field="delete"] img {
      padding: 0;
      border: none;
      /* filter: invert(38%) sepia(56%) saturate(6202%) hue-rotate(345deg)
        brightness(97%) contrast(101%); */
    }
    .MuiDataGrid-main {
      height: 500px;
    }
    .MuiDataGrid-cell:focus {
      outline: none;
    }
    .MuiDataGrid-root {
    border: none;
    }
     }
     
    .modal-bottom {
       align-items: center;
       background-color: var(--neutralswhite);
       border-color: var(--neutralslight-grey-2);
       border-radius: 0px 0px 2px 2px;
       border-top-style: solid;
       border-top-width: 1px;
       display: flex;
       flex: 0 0 auto;
       gap: 12px;
       justify-content: flex-end;
       overflow: hidden;
       padding: 12px 20px;
       position: relative;
       width: 400px;
     }
     
        .text-wrapper-5 {
            cursor: pointer;
            color: var(--brandblue);
            font-family: var(--body-small-font-family);
            font-size: var(--body-small-font-size);
            font-style: var(--body-small-font-style);
            font-weight: var(--body-small-font-weight);
            letter-spacing: var(--body-small-letter-spacing);
            line-height: var(--body-small-line-height);
            margin-top: -1px;
            position: relative;
            width: fit-content;
          }
          .img {
            height: 16px;
            position: relative;
            width: 16px;
            margin-top: -2px;
          }
          .img-wrapper {
            &:hover {
                background: #F8F8F8;
              }
            cursor: pointer;
            align-items: center;
            border-radius: 2px;
            display: flex;
            height: 24px;
            justify-content: center;
            overflow: hidden;
            position: relative;
            width: 24px;
          }
          .menu-items {
            color: #505050;
            background-color: #FFFFFF;
            &:hover {
                background: #F8F8F8;
              }
          }
          .MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
            background-color: #FFFFFF;
            color: #505050;
          }
     `;
const VersionListStyle = styled.div`
     .modal-header {
      align-items: flex-start;
      background-color: var(--neutralswhite);
      border-bottom-style: solid;
      border-bottom-width: 1px;
      border-color: var(--neutralslight-grey-2);
      border-radius: 2px 2px 0px 0px;
      display: flex;
      flex: 0 0 auto;
      justify-content: space-between;
      overflow: hidden;
      padding: 16px 20px;
      position: relative;
      width: 548px;
    }
    .text-wrapper-6 {
      color: var(--neutralsblack);
      font-family: var(--common-font), Helvetica;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0.16px;
      line-height: normal;
      margin-top: -1px;
      position: relative;
      width: fit-content;
    }
    .img-wrapper {
      &:hover {
        background: #F8F8F8;
      }
      cursor: pointer;
      align-items: center;
      border-radius: 2px;
      display: flex;
      height: 24px;
      justify-content: center;
      overflow: hidden;
      position: relative;
      width: 24px;
    }
    .img {
      height: 16px;
      position: relative;
      width: 16px;
    }
    .frame-4 {
      align-items: flex-start;
      align-self: stretch;
      display: flex;
      flex: 0 0 auto;
      justify-content: space-between;
      position: relative;
      width: 100%;
    }
    .search-wrapper {
      align-items: flex-start;
      background-color: var(--neutralswhite);
     // border: 1px solid;
     // border-color: var(--neutralslight-grey-3);
     // border-radius: 2px;
      display: flex;
      flex-direction: column;
      padding: 0px 12px;
      position: relative;
      width: 379px;
    }
    .search {
      align-items: center;
      align-self: stretch;
      display: flex;
      flex: 0 0 auto;
      justify-content: space-between;
      position: relative;
      width: 100%;
    }
    .search-label {
      color: var(--neutralsgrey);
      font-family: var(--common-font), Helvetica;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0;
      line-height: normal;
      margin-top: -1px;
      position: relative;
      width: fit-content;
    }
    .version-set {
      &:hover {
        background: #F8F8F8;
      }
       align-items: center;
       background-color: var(--neutralswhite);
      
       border-radius: 2px;
       display: flex;
       justify-content: space-between;
       padding: 8px 12px;
       position: relative;
       width: 128px;
       align-items: center;
       
    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8 ul li:nth-child(2){
        margin-bottom: 0px;
        padding-bottom: 0px;
    }
      .MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
        width: 110px;
      }
      .MuiOutlinedInput-notchedOutline {
        border-color: #D8D8D8 !important;
      }
      textarea {
        min-height: 37px;
        border: 1px solid #D8D8D8;
        padding: 8px 12px;
        border-radius: 2px;
        outline: none;
        color: #505050;
      }
      img {
        border: none;
        padding: 0;
      }
    }
    .modal-body {
      align-items: flex-start;
      background-color: var(--neutralswhite);
      display: flex;
      flex-direction: column;
      gap: 12px;
      height: 470px;
      justify-content: center;
      padding: 16px 20px;
      position: relative;
      width: 548px;
    }
    .data_table {
      height: 400px;
      width: 100%;
      min-width: 505px;
      .MuiDataGrid-virtualScroller {
      }
      .MuiDataGrid-root {
        color: #505050;
        border: none;
      }
      .MuiDataGrid-cell:focus {
        outline: none;
      }
      .MuiOutlinedInput-input {
        padding: 10px 14px;
      }
      .MuiDataGrid-cell:focus-within{
        outline:none
      }
      .MuiDataGrid-columnSeparator.MuiDataGrid-columnSeparator--sideRight {
        display: none;
      }
    }
    .modal-bottom {
      align-items: center;
      background-color: var(--neutralswhite);
      border-color: var(--neutralslight-grey-2);
      border-radius: 0px 0px 2px 2px;
      border-top-style: solid;
      border-top-width: 1px;
      display: flex;
      flex: 0 0 auto;
      gap: 12px;
      justify-content: flex-end;
      overflow: hidden;
      padding: 12px 20px;
      position: relative;
      width: 548px;
    }
    .buttons-3 {
      pointer-events: none;
      align-items: center;
      background-color: var(--neutralslight-grey-4);
      border-radius: 2px;
      display: inline-flex;
      flex: 0 0 auto;
      gap: 10px;
      justify-content: center;
      overflow: hidden;
      padding: 9px 16px;
      position: relative;
    }
    .buttons-3-select {
      cursor: pointer;
      align-items: center;
      background-color: #346EF5;
      border-radius: 2px;
      display: inline-flex;
      flex: 0 0 auto;
      gap: 10px;
      justify-content: center;
      overflow: hidden;
      padding: 9px 16px;
      position: relative;
    }
    .label {
      color: var(--neutralswhite);
      font-family: var(--common-font), Helvetica;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0;
      line-height: normal;
      margin-top: -1px;
      position: relative;
      width: fit-content;
    }
    .textfield-2 {
      &:hover {
          background: #F8F8F8;
        }
         align-items: center;
         background-color: var(--neutralswhite);
        
         border-radius: 2px;
         display: flex;
         justify-content: space-between;
         padding: 8px 12px;
         position: relative;
         width: 128px;
         align-items: center;
         
      .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8 ul li:nth-child(2){
          margin-bottom: 0px;
          padding-bottom: 0px;
      }
        .MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
          width: 110px;
        }
        .MuiOutlinedInput-notchedOutline {
          border: none !important;
        }
        textarea {
          min-height: 37px;
          border: none;
          padding: 8px 12px;
          border-radius: 2px;
          outline: none;
          color: #505050;
        }
        img {
          border: none;
          padding: 0;
        }
      }   
      .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8 ul li:nth-child(2){
        margin-bottom: 0px;
        padding-bottom: 0px;
    }
      .MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
        width: 110px;
      }
      .MuiOutlinedInput-notchedOutline {
        border-color: #D8D8D8 !important;
      }
      textarea {
        min-height: 37px;
        border: 1px solid #D8D8D8;
        padding: 8px 12px;
        border-radius: 2px;
        outline: none;
        color: #505050;
      }
      img {
        border: none;
        padding: 0;
      }   
     `;
const VersionsSelect = ({ compareOpen, setCompareOpen, compareDialog, setCompareDialog, setSheetsForReplace, sheetsForReplace, pdfopenfiledata, defaultFile, viewFolderFiles, credential, versionList, instance, callFrom, replaceDocument, comparedDocuments, onAccept }) => {
  const [documentList, setDocumentList] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [allFiles, setAllFiles] = useState([]);
  const [filterFiles, setFilterFiles] = useState([]);
  const [allFilesReplace, setAllFilesReplace] = useState([]);
  const [filterFilesReplace, setFilterFilesReplace] = useState([]);
  const [sheetNumber, setSheetNumber] = useState(0);
  const [sheetsForCompare, setSheetsForCompare] = useState(comparedDocuments);
  const [sheetsVersionForReplace, setSheetsVersionForReplace] = useState([]);
  const [sheetsThumbnailForReplace, setSheetsThumbnailForReplace] = useState([]);
  const [browseFile, setBrowseFile] = useState(pdfopenfiledata && pdfopenfiledata.Document_File_Versions__r?.records.length == 1 ? true : false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [disciplineOptions, setDisciplineOptions] = useState();
  const [disciplineValue, setDisciplineValue] = useState(defaultFile && defaultFile.discipline);
  const [disciplineValueReplace, setDisciplineValueReplace] = useState();
  const [version, setVersion] = useState(pdfopenfiledata && pdfopenfiledata.currentVersion != defaultFile?.currentVersion ? pdfopenfiledata.currentVersion : pdfopenfiledata && pdfopenfiledata.currentVersion - 1);
  const [setId, setSetId] = useState('none'); // 'a4aDb000000VWH9IAO'
  const [searchText, setSearchText] = useState("");
  const [filesForCompre, setFilesForCompare] = useState();
  const [compareFiles, setCompareFiles] = useState(false);
  const [selectedVersions, setSelectedVersions] = useState({});
  const [fileData, setFileData] = useState(pdfopenfiledata);
  const [versionLists, setVersionLists] = useState(versionList);
  const [compareVersionLists, setCompareVersionLists] = useState([]);
  const [thumbnail, setThumbnail] = useState(null);

  const columns = [
    {
      field: "name",
      headerName: "Number",
      width: 132,
    },
    {
      field: "set",
      headerName: "Set Name",
      width: 150,
    },
    {
      field: "currentVersion",
      headerName: "Version",
      width: 170,
      renderCell: (param) => {
        /* if (!selectedVersions[param.row.id]) {
          setSelectedVersions({
            ...selectedVersions,
            [param.row.id]: param.row.Document_File_Versions__r?.records[0].Numbering_Sequence__c,
          });
        } */
        return (
          <Select
            className="textfield-2"
            style={{ height: "37px", width: "150px", color: "#505050", fontSize: "0.875rem", fontWeight: 400 }}
            MenuProps={{
              sx: {
                "&& .Mui-selected": {
                  backgroundColor: "white"
                },
              }
            }}
            value={selectedVersions[param.row.id] ? selectedVersions[param.row.id] : param.row.currentVersion}
            renderValue={() => { return 'Version ' + (selectedVersions[param.row.id] ? selectedVersions[param.row.id] : param.row.currentVersion) }}
            onChange={(event) => {
              const selectedValue = event.target.value;
              setSelectedVersions({
                ...selectedVersions,
                [param.row.id]: selectedValue,
              });
            }}
          >
            {param.row && param.row.currentVersion > 0 &&
              Array.from({ length: param.row.currentVersion }, (_, i) => i + 1).map((ver) =>
                <MenuItem value={ver} >
                  Version {ver}
                </MenuItem>
              )
            }

          </Select>
        )
      }
    },
  ];
  const columnsFiles = [
    {
      field: "name",
      headerName: "Number",
      width: 300,
    },
    {
      field: "currentVersion",
      headerName: "Version",
      width: 170,
      renderCell: (param) => {
        /* if (!selectedVersions[param.row.id]) {
          setSelectedVersions({
            ...selectedVersions,
            [param.row.id]: param.row.Document_File_Versions__r?.records[0].Numbering_Sequence__c,
          });
        } */
        return (
          <Select
            className="textfield-2"
            style={{ height: "37px", width: "150px", color: "#505050", fontSize: "0.875rem", fontWeight: 400, padding: "0px" }}
            MenuProps={{
              sx: {
                "&& .Mui-selected": {
                  backgroundColor: "white"
                },
              }
            }}
            value={selectedVersions[param.row.id] ? selectedVersions[param.row.id] : param.row.Document_File_Versions__r?.records[0]?.Numbering_Sequence__c}
            renderValue={() => { return 'Version ' + (selectedVersions[param.row.id] ? selectedVersions[param.row.id] : param.row.Document_File_Versions__r?.records[0]?.Numbering_Sequence__c) }}
            onChange={(event) => {
              const selectedValue = event.target.value;
              setSelectedVersions({
                ...selectedVersions,
                [param.row.id]: selectedValue,
              });
            }}
          >
            {param.row && param.row.currentVersion > 0 &&
              Array.from({ length: param.row.currentVersion }, (_, i) => i + 1).filter((t) => t !== param.row.currentVersion).map((ver) =>
                <MenuItem value={ver} >
                  Version {ver}
                </MenuItem>
              )
            }
            {/* {param.row &&
              param.row.Document_File_Versions__r?.records?.filter((item) => item.Id !== defaultFile.Id).map((item) => (
                <MenuItem value={item.Numbering_Sequence__c} >
                  Version {item.Numbering_Sequence__c}
                </MenuItem>
              ))} */}

          </Select>
        )
      }
    },
  ];
  useEffect(() => {
    const fetchThumbnail = async () => {
      let files = defaultFile ? [defaultFile] : [];
      const filterList = versionLists && versionLists.find((versions) => versions.SF_Display_Version_Num === version);
      if (filterList) {
        const thumbnail = await getPdfThumbnailFromWebViewer(filterList.url);
        setThumbnail(thumbnail);
        files.push(filterList);
        setFilesForCompare(files);
      }
    };

    fetchThumbnail();
  }, [version]);

  useEffect(() => {
    if (comparedDocuments) {
      handleGetCompareDocumentsVersion();
    }
  }, [comparedDocuments]);

  useEffect(() => {
    console.log("callFrom ::", callFrom);
    if (callFrom == "FilesComponent") {
      setAllFiles(viewFolderFiles);
      setFilterFiles(viewFolderFiles);
    } else {
      getDisciplineOptions();
    }
  }, [browseFile, documentList]);
  const handleOpenDocumentList = () => {
    setDocumentList(true);
  };
  const getPdfThumbnailFromWebViewer = async (fileUrl) => {
    try {
      const { PDFNet } = instance.Core;
      await PDFNet.initialize();
      async function main() {
        const docAsPDF = await PDFNet.PDFDoc.createFromURL(fileUrl);
        const pdfdraw = await PDFNet.PDFDraw.create(92);
        const currPage = await docAsPDF.getPage(1);
        const pageInfo = {};
        pageInfo.width = await currPage.getPageWidth();
        pageInfo.height = await currPage.getPageHeight();
        pdfdraw.setImageSize(pageInfo.width, pageInfo.height);
        const pngBuffer = await pdfdraw.exportBuffer(currPage, 'PNG');
        //console.log('ind,pngBuffer success');
        const image = URL.createObjectURL(new Blob([pngBuffer.buffer], { type: 'image/png' }));

        return image;
      }
      // Automatically locks all worker operations
      return PDFNet.runWithCleanup(main);
    } catch (e) {
    }
  };
  const handleCloseDocumentList = () => {
    setDocumentList(false);
  };

  const handleGetCompareDocumentsVersion = async () => {
    console.log("sheetsForReplace :", sheetsForReplace);
    setShowSpinner(true);
    let versionLists = [];
    let versionNumber = [];
    let documentThumbnail = [];
    for (let i = 0; i < sheetsForReplace.length; i++) {
      await axios
        .get(`${fetchUrl}/version/list/${sheetsForReplace[i].folderId}/${sheetsForReplace[i].id}`)
        .then(async (res) => {
          if (res?.status === 200) {
            let versionList = res.data.versionList;
            versionList.forEach((e, i) => {
              e.folderId = sheetsForReplace[i]?.folderId;
              e.id = sheetsForReplace[i]?.id;
            });
            console.log("versionList :", versionList, sheetsForReplace[i].id);
            const thumbnail = await getPdfThumbnailFromWebViewer(comparedDocuments[i].url);
            versionLists.push(versionList);
            documentThumbnail.push(thumbnail);
            versionNumber.push(comparedDocuments[i].Version_Number__c ? comparedDocuments[i].Version_Number__c : comparedDocuments[i].SF_Display_Version_Num)
            if (versionLists.length == 2) {
              setCompareVersionLists(versionLists);
              setSheetsVersionForReplace(versionNumber);
              setSheetsThumbnailForReplace(documentThumbnail);
              setShowSpinner(false);
            }
          }
        });
    }
  }

  const handleSelectDocumentList = async () => {
    setShowSpinner(true);
    setDocumentList(false);
    setBrowseFile(false);
    setThumbnail(null);
    await axios
      .get(`${fetchUrl}/version/list/${selectedRows.folderId}/${selectedRows.id}`)
      .then(async (res) => {
        if (res?.status === 200) {
          let versionList = res.data.versionList;
          versionList.forEach((e, i) => {
            e.folderId = selectedRows?.folderId;
            e.id = selectedRows?.id;
          });
          console.log("versionList ::", versionList);
          let files = defaultFile ? [defaultFile] : [];
          const curVe = selectedVersions && selectedVersions[selectedRows.id] ? selectedVersions[selectedRows.id] : selectedRows && selectedRows.currentVersion;
          const filterList = versionList.find((version) => version.SF_Display_Version_Num === curVe);
          if (filterList && !replaceDocument) {
            const thumbnail = await getPdfThumbnailFromWebViewer(filterList.url);
            setThumbnail(thumbnail);
            files.push(filterList);
            setVersionLists(versionList);
            setFileData(selectedRows);
            setFilesForCompare(files);
            setVersion(curVe);
          }
          if (replaceDocument) {
            let compareSheets = cloneDeep(sheetsForCompare);
            compareSheets[sheetNumber] = filterList;
            setSheetsForCompare(compareSheets);
            let sheets = cloneDeep(sheetsForReplace)
            let versionLists = cloneDeep(compareVersionLists)
            let thumbnails = cloneDeep(sheetsThumbnailForReplace)
            let versionNumber = cloneDeep(sheetsVersionForReplace);
            const thumbnail = await getPdfThumbnailFromWebViewer(filterList.url);
            console.log("thumbnail :", thumbnail);
            versionLists[sheetNumber] = versionList;
            thumbnails[sheetNumber] = thumbnail;
            sheets[sheetNumber] = selectedRows;
            versionNumber[sheetNumber] = curVe;
            setSheetsForReplace(sheets);
            setCompareVersionLists(versionLists);
            setSheetsThumbnailForReplace(thumbnails);
            setSheetsVersionForReplace(versionNumber);

          }
          setShowSpinner(false);
        }
      });
  };

  const handleVersionChange = async (e, changeFor) => {
    let versions = cloneDeep(sheetsVersionForReplace);
    if (changeFor && changeFor == 'A') {
      const filterList = compareVersionLists && compareVersionLists[0].find((versions) => versions.SF_Display_Version_Num === e.target.value);
      let compareSheets = cloneDeep(sheetsForCompare);
      compareSheets[0] = filterList;
      setSheetsForCompare(JSON.parse(JSON.stringify(compareSheets)));
      let thumbnails = cloneDeep(sheetsThumbnailForReplace);


      versions[0] = e.target.value;

      console.log("versions :", versions);
      setSheetsVersionForReplace(JSON.parse(JSON.stringify(versions)));

      const thumbnail = await getPdfThumbnailFromWebViewer(filterList.url);
      thumbnails[0] = thumbnail;
      setSheetsThumbnailForReplace(thumbnails);
    } else if (changeFor && changeFor == 'B') {
      const filterList = compareVersionLists && compareVersionLists[1].find((versions) => versions.SF_Display_Version_Num === e.target.value);
      let thumbnails = cloneDeep(sheetsThumbnailForReplace);


      let compareSheets = cloneDeep(sheetsForCompare);
      compareSheets[1] = filterList;
      versions[1] = e.target.value;
      setSheetsVersionForReplace(versions)
      setSheetsForCompare(JSON.parse(JSON.stringify(compareSheets)));
      const thumbnail = await getPdfThumbnailFromWebViewer(filterList.url);
      thumbnails[1] = thumbnail;
      setSheetsThumbnailForReplace(thumbnails);
    } else {
      setVersion(e.target.value);
    }
  };
  const handleDisiciplineChange = (e, sheetNumber) => {
    let orgData = cloneDeep(viewFolderFiles);
    //console.log(searchTerm,'orgData :: '+orgData?.length);
    let searchFoundList = orgData;
    let searchFoundListSecond = orgData;
    if (orgData) {
      searchFoundList = [];
      searchFoundListSecond = [];
      for (let el of orgData) {
        if (((el.Discipline__c ? el.Discipline__c : el.discipline).includes(e.target.value))) {
          searchFoundList.push(el);
        }
      }
    }

    if (replaceDocument) {
      let disValue = cloneDeep(disciplineValueReplace);
      let allSheets = cloneDeep(allFilesReplace);
      allSheets[sheetNumber] = searchFoundList
      disValue[sheetNumber] = e.target.value
      setDisciplineValueReplace(disValue)
      setAllFilesReplace(allSheets);
      setFilterFilesReplace(allSheets);
    } else {
      setDisciplineValue(e.target.value);
      setFilterFiles(searchFoundList);
      setAllFiles(searchFoundList);
    }
  };

  async function getDisciplineOptions() {
    //setLoading(true);
    const instanceUrl = credential.instanceUrl;
    const token = credential.token;
    await axios.get(`${fetchUrl}/disciplineOptions/${credential?.projectId}?token=${token}&instanceUrl=${instanceUrl}`)
      .then((res) => {
        if (res?.status === 200) {
          let retData = res.data;
          setDisciplineOptions(retData?.options);
          let dis_records = [];
          let i = 0;
          for (let d of retData?.dis_records) {
            dis_records.push({ ...{ id: i }, ...d });
            i++;
          }
          let orgData = cloneDeep(viewFolderFiles);
          console.log("orgData ::", orgData);
          let searchFoundList = orgData;
          let allSheets = [];
          let searchFoundListSecond = orgData;
          if (orgData) {
            searchFoundList = [];
            searchFoundListSecond = [];
            console.log("replaceDocument ::", replaceDocument, comparedDocuments);
            for (let el of orgData) {
              if (replaceDocument) {
                if ((el?.Discipline__c?.includes(comparedDocuments && comparedDocuments[0].Discipline__c ? comparedDocuments[0].Discipline__c : comparedDocuments[0].discipline))) {
                  searchFoundList.push(el);
                }
                if ((el?.Discipline__c?.includes(comparedDocuments && comparedDocuments[1].Discipline__c ? comparedDocuments[1].Discipline__c : comparedDocuments[1].discipline))) {
                  searchFoundListSecond.push(el);
                }
              }else {
                if ((el?.Discipline__c?.includes(defaultFile && defaultFile.discipline))) {
                  searchFoundList.push(el);
                }
              }
            }
          } else {
            searchFoundList = cloneDeep(filterFiles);
          }
          setDisciplineValue(defaultFile && defaultFile.discipline);

          setFilterFiles(searchFoundList);
          setAllFiles(searchFoundList);
         
          if (replaceDocument) {
            allSheets.push(searchFoundList, searchFoundListSecond)
            setDisciplineValueReplace([comparedDocuments[0].Discipline__c ? comparedDocuments[0].Discipline__c : comparedDocuments[0].discipline, comparedDocuments[1].Discipline__c ? comparedDocuments[1].Discipline__c : comparedDocuments[1].discipline])
            setAllFilesReplace(allSheets);
            setFilterFilesReplace(allSheets);
          }
        }
      }).catch((err) => {
        console.log(err);
      });
  }
  async function searchListViewFunction(searchTerm) {
    let orgData = replaceDocument && callFrom != "FilesComponent" ? cloneDeep(filterFilesReplace[sheetNumber]) : cloneDeep(filterFiles);
    console.log("orgData ::", orgData);
    let searchFoundList = orgData;
    if (orgData && searchTerm && searchTerm != null && searchTerm !== "") {
      searchFoundList = [];
      for (let el of orgData) {
        if ((el.name && el.name.toLowerCase().includes(searchTerm))) {
          searchFoundList.push(el);
        }
      }
    } else {
      searchFoundList = replaceDocument && callFrom != "FilesComponent" ? cloneDeep(filterFilesReplace[sheetNumber]) : cloneDeep(filterFiles);
    }
    //console.log("searchFoundList1 ::", searchFoundList);
    //console.log(searchTerm, 'searchFoundList :: ' + searchFoundList?.length);
    if (replaceDocument && callFrom != "FilesComponent") {
      let allSheets = cloneDeep(allFilesReplace);
      allSheets[sheetNumber] = searchFoundList;
      setAllFilesReplace(allSheets);
    } else {
      setAllFiles(searchFoundList);
    }
  };
  const selectStyles = {
    width: '230px',
    height: '200px',
  };
  return (
    <>

      {!documentList && !replaceDocument && (
        <Dialog open={true}>
          <CompareStyle>
            <div className="sheet-view-issues">
              <div className="modal-header">
                <div className="text-wrapper-6">{callFrom == "FilesComponent" ? "Compare Files" : "Compare Sheets"}</div>
                <div onClick={() => setCompareOpen(false)} className="img-wrapper">
                  <img
                    className="img"
                    alt="Close"
                    src="/close.svg"
                  />
                </div>
              </div>
              <div className="modal-body">
                <p className="please-select-the">
                  <span className="span">Please select the {callFrom == "FilesComponent" ? "file" : "sheet"} you want to compare ‘</span>
                  <span className="text-wrapper-7">{defaultFile?.Document_Number__c}</span>
                  <span className="span">’ with.</span>
                </p>
                {!browseFile && <div className="references">
                  <div className="title">
                    <div className="frame-4">
                      <img
                        className="img"
                        alt="Sheets"
                        src={SheetsIcon}
                      />
                      <div className="text-wrapper-8">{callFrom == "FilesComponent" ? "Selected File" : "Selected Sheet"}</div>
                    </div>
                    <div onClick={handleOpenDocumentList} className="text-wrapper-5">Change</div>
                  </div>
                  <div className="content">
                    <div className="sheet-number">
                      <div className="photo-and-title-wrapper">
                        <div className="photo-and-title">
                          <img
                            style={{ width: !thumbnail && "36px" }}
                            className="thumbnail"
                            alt="Thumbnail"
                            src={thumbnail ? thumbnail : "icon.svg"}
                          />
                          <div className="element-liner-text">
                            <Tooltip
                              title={filesForCompre && filesForCompre[1].Document_Number__c}
                              placement="top"
                              PopperProps={{ className: "version-action-tooltip" }}
                              arrow
                            >
                              <div className="title-here">{filesForCompre && filesForCompre[1].Document_Number__c}</div>
                            </Tooltip>
                            <div className="version-2">{callFrom == "FilesComponent" ? filesForCompre && moment(filesForCompre[1].CreatedDate).format("MMM DD, YYYY HH:mm") : filesForCompre && filesForCompre[1].set}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <Select
                        className="textfield-2"
                        style={{ height: "37px", width: "150px", color: "#505050", fontSize: "0.875rem", fontWeight: 400 }}
                        MenuProps={{
                          sx: {
                            "&& .Mui-selected": {
                              backgroundColor: "white"
                            },
                          }
                        }}
                        value={version}
                        renderValue={() => { return 'Version ' + version }}
                        onChange={(e) => handleVersionChange(e)}
                      >
                        {fileData &&
                          Array.from({ length: fileData.currentVersion }, (_, i) => i + 1).filter(t => fileData.id !== pdfopenfiledata.id || defaultFile.currentVersion !== t).map((ver) =>
                            <MenuItem key={ver} value={ver} >
                              Version {ver}
                            </MenuItem>
                          )
                      /* fileData.Document_File_Versions__r?.records.filter((item) => item.Id != defaultFile.Id).map((item) => (
                        <MenuItem value={item.Numbering_Sequence__c} >
                          Version {item.Numbering_Sequence__c}
                        </MenuItem>
                      )) */}
                      </Select>
                    </div>
                  </div>
                </div>
                }
                {browseFile &&
                  <div style={{ border: "1px solid #D8D8D8", background: "#F8F8F8", width: "100%", paddingTop: "20px", paddingBottom: "20px" }}>
                    <div style={{ color: "black", fontWeight: 600, fontSize: "14px", paddingLeft: "120px", paddingBottom: "10px" }}>
                      {callFrom == "FilesComponent" ? "No File Selected" : "No Sheet Selected"}
                    </div>
                    <div style={{ color: "black", paddingLeft: "60px", paddingBottom: "10px" }}>
                      Please click 'browse' to select a {callFrom == "FilesComponent" ? "file" : "sheet"}
                    </div>
                    <div>
                      <Button style={{ border: "1px solid #D8D8D8", color: "#505050", textTransform: "none", marginLeft: "140px" }} onClick={() => handleOpenDocumentList()}>Browse</Button>
                    </div>
                  </div>
                }
              </div>
              <div className="modal-bottom">
                <Button className="custom_button cancel_button" onClick={() => setCompareOpen(false)}>Cancel</Button>
                <Button className="custom_button apply_button" disabled={browseFile} onClick={() => {
                  setCompareFiles(true);
                }}>
                  {callFrom == "FilesComponent" ? "Select File" : "Select Sheet"}
                </Button>
              </div>
            </div>
          </CompareStyle>
          {compareFiles && <CompareFiles
            open={compareFiles}
            callFrom={callFrom}
            setCompareFiles={setCompareFiles}
            selectedFiles={filesForCompre}
            credential={credential}
            viewFolderFiles={viewFolderFiles}

          />}
        </Dialog>
      )}
      {documentList && (
        <Dialog open={documentList}>
          <VersionListStyle>
            <div className="modal-header">
              <div className="text-wrapper-6">{callFrom == "FilesComponent" ? "Select File" : "Select Sheet"}</div>
              <div onClick={handleCloseDocumentList} className="img-wrapper">
                <img
                  className="img"
                  alt="Close"
                  src='/close.svg'
                />
              </div>
            </div>
            <div className="modal-body">
              <div className="frame-4">
                <div >
                  <div style={{ width: callFrom == "FilesComponent" ? "500px" : "257px" }} className="right_side">
                    <TextField
                      sx={{ "& .MuiInputBase-root": { color: "#505050", height: '37px' } }}
                      id="outlined-basic"
                      variant="outlined"
                      fullWidth
                      autoComplete="off"
                      className="Searchbar-field"
                      placeholder={callFrom == "FilesComponent" ? "Search File" : "Search Sheet Number"}
                      value={searchText}
                      onChange={(e) => {
                        //convert input text to lower case
                        var lowerCase = e.target.value.toLowerCase();
                        if (!lowerCase || lowerCase == null || lowerCase === "") {
                          searchListViewFunction(lowerCase);
                        }
                        setSearchText(lowerCase);
                      }}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          // Do code here
                          e.preventDefault();
                          var lowerCase = e.target.value.toLowerCase();
                          //console.log('searchText ',searchText , 'lowerCase',lowerCase);
                          searchListViewFunction(searchText);
                        }
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>
                {callFrom != "FilesComponent" &&
                  <div>
                    <Select
                      className="version-set"
                      style={{ height: "37px", width: "226px", color: "#505050", fontSize: "0.875rem", fontWeight: 400 }}
                      placeholder="Select"
                      MenuProps={{
                        PaperProps: {
                          style: selectStyles,
                        },
                        sx: {
                          "&& .Mui-selected": {
                            backgroundColor: "white"
                          },
                        }
                      }}
                      onChange={(e) => {
                        handleDisiciplineChange(e, sheetNumber)

                      }}
                      value={callFrom != "FilesComponent" && replaceDocument ? disciplineValueReplace[sheetNumber] : disciplineValue}
                    >
                      {disciplineOptions && disciplineOptions.map((item) =>
                        <MenuItem value={item.key}>
                          {item.label}
                        </MenuItem>
                      )}
                    </Select>
                  </div>
                }
              </div>
              <Box className="data_table" style={{ width: "485px" }}>
                <DataGrid
                  style={{ width: "510px" }}
                  rows={callFrom != "FilesComponent" && replaceDocument ? allFilesReplace[sheetNumber] : allFiles}
                  columns={callFrom == "FilesComponent" ? columnsFiles : columns}
                  checkboxSelection={false}
                  hideFooter
                  disableColumnMenu
                  onRowSelectionModelChange={(newSelection) => {
                    setSelectedRows((callFrom != "FilesComponent" && replaceDocument ? allFilesReplace[sheetNumber] : allFiles).find((row) => row.id === newSelection[0]))
                  }}
                  
                />
              </Box>
            </div>
            <div className="modal-bottom">
              <Button className="custom_button cancel_button" onClick={handleCloseDocumentList}>Cancel</Button>
              <Button className="custom_button apply_button" onClick={handleSelectDocumentList}>{callFrom == "FilesComponent" ? "Select File" : "Select Sheet"}</Button>
            </div>
          </VersionListStyle>
        </Dialog>
      )}
      {replaceDocument &&
        <Dialog open={true}>
          <CompareStyle>
            {showSpinner &&
              <Spinner />
            }
            <div className="sheet-view-issues">
              <div className="modal-header">
                <div className="text-wrapper-6">{callFrom == "FilesComponent" ? "Compare Files" : "Compare Sheets"}</div>
                <div onClick={() => setCompareOpen(false)} className="img-wrapper">
                  <img
                    className="img"
                    alt="Close"
                    src="/close.svg"
                  />
                </div>
              </div>
              <div className="modal-body">
                <p className="please-select-the">
                  <span className="span">Please select the {callFrom == "FilesComponent" ? "file" : "sheet"} you want to compare.</span>
                </p>
                <div className="references">
                  <div className="title">
                    <div className="frame-4">
                      <img
                        className="img"
                        alt="Sheets"
                        src={SheetsIcon}
                      />
                      <div className="text-wrapper-8">{callFrom == "FilesComponent" ? "File A (Red)" : "Sheet A (Red)"}</div>
                    </div>
                    <div onClick={() => {
                      setSheetNumber(0)
                      handleOpenDocumentList()
                    }} className="text-wrapper-5">Replace</div>
                  </div>
                  <div className="content">
                    <div className="sheet-number">
                      <div className="photo-and-title-wrapper">
                        <div className="photo-and-title">
                          <img
                            style={{ width: !sheetsThumbnailForReplace[0] && "36px" }}
                            className="thumbnail"
                            alt="Thumbnail"
                            src={sheetsThumbnailForReplace[0] ? sheetsThumbnailForReplace[0] : "icon.svg"}
                          />
                          <div className="element-liner-text">
                            <Tooltip
                              title={sheetsForReplace && sheetsForReplace[1].Document_Number__c}
                              placement="top"
                              PopperProps={{ className: "version-action-tooltip" }}
                              arrow
                            >
                              <div className="title-here">{sheetsForReplace && sheetsForReplace[0].Document_Number__c}</div>
                            </Tooltip>
                            <div className="version-2">{callFrom == "FilesComponent" ? sheetsForReplace && moment(sheetsForReplace[0].CreatedDate).format("MMM DD, YYYY HH:mm") : sheetsForReplace && sheetsForReplace[0].set}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <Select
                        className="textfield-2"
                        style={{ height: "37px", width: "150px", color: "#505050", fontSize: "0.875rem", fontWeight: 400 }}
                        MenuProps={{
                          sx: {
                            "&& .Mui-selected": {
                              backgroundColor: "white"
                            },
                          }
                        }}
                        defaultValue={comparedDocuments[0]?.Version_Number__c ? comparedDocuments[0]?.Version_Number__c : comparedDocuments[0]?.SF_Display_Version_Num}
                        value={sheetsVersionForReplace[0]}
                        renderValue={() => { return 'Version ' + sheetsVersionForReplace[0] }}
                        onChange={(e) => handleVersionChange(e, 'A')}
                      >

                        {compareVersionLists.length > 1 &&
                          (compareVersionLists[0].length > 0 && compareVersionLists[0]).map((versions, i) => (
                            <MenuItem key={i} value={versions.version} >
                              Version {versions.version}
                            </MenuItem>
                          )
                          )
                      /* fileData.Document_File_Versions__r?.records.filter((item) => item.Id != defaultFile.Id).map((item) => (
                        <MenuItem value={item.Numbering_Sequence__c} >
                          Version {item.Numbering_Sequence__c}
                        </MenuItem>
                      )) */}
                      </Select>
                    </div>
                  </div>
                </div>
                <div className="references">
                  <div className="title">
                    <div className="frame-4">
                      <img
                        className="img"
                        alt="Sheets"
                        src={SheetsIcon}
                      />
                      <div className="text-wrapper-8">{callFrom == "FilesComponent" ? "File B (Blue)" : "Sheet B (Blue)"}</div>
                    </div>
                    <div onClick={() => {
                      setSheetNumber(1);
                      handleOpenDocumentList()
                    }} className="text-wrapper-5">Replace</div>
                  </div>
                  <div className="content">
                    <div className="sheet-number">
                      <div className="photo-and-title-wrapper">
                        <div className="photo-and-title">
                          <img
                            style={{ width: !sheetsThumbnailForReplace[1] && "36px" }}
                            className="thumbnail"
                            alt="Thumbnail"
                            src={sheetsThumbnailForReplace[1] ? sheetsThumbnailForReplace[1] : "icon.svg"}
                          />
                          <div className="element-liner-text">
                            <Tooltip
                              title={sheetsForReplace && sheetsForReplace[1].Document_Number__c}
                              placement="top"
                              PopperProps={{ className: "version-action-tooltip" }}
                              arrow
                            >
                              <div className="title-here">{sheetsForReplace && sheetsForReplace[1].Document_Number__c}</div>
                            </Tooltip>
                            <div className="version-2">{callFrom == "FilesComponent" ? sheetsForReplace && moment(sheetsForReplace[1].CreatedDate).format("MMM DD, YYYY HH:mm") : sheetsForReplace && sheetsForReplace[1].set}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <Select
                        className="textfield-2"
                        style={{ height: "37px", width: "150px", color: "#505050", fontSize: "0.875rem", fontWeight: 400 }}
                        MenuProps={{
                          sx: {
                            "&& .Mui-selected": {
                              backgroundColor: "white"
                            },
                          }
                        }}
                        defaultValue={comparedDocuments[1]?.Version_Number__c ? comparedDocuments[1]?.Version_Number__c : comparedDocuments[1]?.SF_Display_Version_Num}
                        value={sheetsVersionForReplace[1]}
                        renderValue={() => { return 'Version ' + sheetsVersionForReplace[1] }}
                        onChange={(e) => handleVersionChange(e, 'B')}
                      >

                        {compareVersionLists.length > 1 &&
                          (compareVersionLists[1].length > 0 && compareVersionLists[1]).map((versions, i) => (
                            <MenuItem key={i} value={versions.version} >
                              Version {versions.version}
                            </MenuItem>
                          )
                          )
                      /* fileData.Document_File_Versions__r?.records.filter((item) => item.Id != defaultFile.Id).map((item) => (
                        <MenuItem value={item.Numbering_Sequence__c} >
                          Version {item.Numbering_Sequence__c}
                        </MenuItem>
                      )) */}
                      </Select>
                    </div>
                  </div>
                </div>

              </div>
              <div className="modal-bottom">
                <Button className="custom_button cancel_button" onClick={() => setCompareOpen(false)}>Cancel</Button>
                <Button className="custom_button apply_button" onClick={() => {
                  onAccept(sheetsForCompare);
                }}>
                  {callFrom == "FilesComponent" ? "Select File" : "Select Sheet"}
                </Button>
              </div>
            </div>
          </CompareStyle>
        </Dialog>
      }
    </>
  );
}
export default VersionsSelect;