import { useState, useEffect, useRef } from "react";
import moment from "moment";
import _, { cloneDeep } from "lodash";
import { makeRandomId, getInternalRecordUrl } from "api";
import axios from "axios";
import { fetchUrl } from "Urls";
import { Tabs, Tab } from "@mui/material";
import DropdownFilter from "components/DropdownFilter";
import Spinner from "components/spinner";
import { useSelector, useDispatch } from "react-redux";
import FormsDataTable from "components/ReactDataTableComponent";
import FormTemplateComponent from "components/FormTemplateComponent";
import { SplitButtonContainedAction } from "components/SplitButtonAction";
import { Tooltip, Box, Button, IconButton, TextField, InputAdornment, Checkbox, Stack, Paper, Menu } from "@mui/material";
import Design from "components/SheetsComponent/Design";
import { highlightText } from "components/SheetsComponent";
import { Search } from "@mui/icons-material";
import BuildChecklistForm from "./BuildChecklistForm";
import BuildPdfForm from "./BuildPdfForm";
import CreateProjectForm from "./CreateProjectForm";
import { ConfirmDialog, AssignDialog } from "components/DrawerComponent/DisciplineSettingDialog";
import ToastComponent from "components/ToastComponent";
import closedIcon from 'components/../../public/closed-green-1.svg';
import inProgressIcon from 'components/../../public/formsInProgess.svg';
import notStartedIcon from 'components/../../public/FormsNotStarted.svg';
import ChecklistFormPreviewExport from "components/FormsComponent/BuildChecklistForm/ChecklistFormPreviewExport";
import userGroupIcon from 'components/../../public/groups.svg';
import { useLocation } from "react-router-dom";
import pdfRed from "assets/images/pdfRed.svg";
import ItemInQueueComponent from "components/ItemInQueueComponent";
import ShareableLinkComponent from "components/ShareableLinkComponent";
import copyLinkIcon from "components/ShareableLinkComponent/Icons/copy_link_icon.svg";
import { setSavedAllFormsStatusActiveFilters,
	setSavedAllFormsCategoryActiveFilters,
	setSavedAllFormsTypeActiveFilters,
	setSavedAllFormsApprovedByActiveFilters,
	setSavedAllFormsProjectTemplateActiveFilters,
	setSavedAllFormsCreatedByActiveFilters,
	setSavedAssignedFormsStatusActiveFilters,
	setSavedAssignedFormsCategoryActiveFilters,
	setSavedAssignedFormsTypeActiveFilters,
	setSavedAssignedFormsProjectTemplateActiveFilters,
	setSavedAssignedFormsCreatedByActiveFilters } from "../../redux/features/appStateSlice"

const openFormStatus = ["In Progress", "Not Started"];
const FormsComponent = (props) => {
	const location = useLocation();
	const dispatch = useDispatch();
	const [state, setStates] = useState(location.state); 
	const stateRef = useRef(location.state);
	const setState=(value)=>{
	  stateRef.current = value;
	  setStates(value);
	}; 
	const { userData = {} } = useSelector((state) => state.userData);
	const [anchorEl, setAnchorEl] = useState(null);
	const openMenu = Boolean(anchorEl);
	const [_projectId, setProjectId] = useState();
	const [credential, setCredential] = useState(userData);
	const [warningDialodData, setWarningDialodData] = useState();
	const [activeTab, setActiveTabState] = useState(0);
	const activeTabRef = useRef(activeTab);
	const [showSpinner, setSpinner] = useState(false);
	const [showTemplateSpinner, setTemplateSpinner] = useState(false);
	const [openCreateForm, setOpenCreateForm] = useState(false);
	const [currentStep, setCurrentStep] = useState(1);
	const [filterOptionsVisible, showFilterOptions] = useState(true);
	const [assignDialog, setAssignDialog] = useState(null);
	const [rowsPerPage, setRowsPerPage] = useState([]);
	const [selectedMenuRow, setSelectedMenuRow] = useState();
	const [selectedRowIds, setSelectedRowIds] = useState([]);
	const addCheckListButton = useRef();
	const addPdfButton = useRef();
	const importTemplatesButton = useRef();
	const [templateOptions, setTemplateOptions] = useState();
	const [userFilterOptions, setUserFilterOptions] = useState(credential?.userList);
	const [projectTemplateFilterOptions, setProjectTemplateFilterOptions] = useState([]);
	const [templateUserFilterOptions, setTemplateUserFilterOptions] = useState(credential?.userList);
	const [formOptions, setFormOptions] = useState();
	const currentTabFormListRef = useRef([]);
	const [currentTabFormList, setCurrentTabFormListState] = useState([]);
	const [fetchedFormList, setFetchedFormList] = useState([]);
	const [assigntoMeListData, setAssigntoMeListData] = useState([]);
	const [myFormListData, setMyFormListData] = useState([]);
	const [viewListData, setViewListData] = useState(fetchedFormList);
	const [projectFormId, openedProjectForm] = useState(null);
	const [formTypeForBuild, setFormTypeForBuild] = useState(null);
	const [selectedRows, setSelectedRows] = useState([]);
	const [toastMessage, setToastMessage] = useState();
	const [projectFormTemplates, setProjectFormTemplates] = useState([]);
	const processTofilter_ = useRef(null);
	const [allFormsFilteredTableData, setAllFormsFilteredTableData] = useState([]);
	const [assignedFormsFilteredTableData, setAssignedFormsFilteredTableData] = useState([]);
	const [allFormsTotalFilterApplied, setAllFormsTotalFilterApplied] = useState(0);
	const [assignedFormsTotalFilterApplied, setAssignedFormsTotalFilterApplied] = useState(0);
	const [activeFilterDropdown, setActiveFilterDropdown] = useState(false);

	const { savedAllFormsStatusActiveFilters,
		savedAllFormsCategoryActiveFilters,
		savedAllFormsTypeActiveFilters,
		savedAllFormsApprovedByActiveFilters,
		savedAllFormsProjectTemplateActiveFilters,
		savedAllFormsCreatedByActiveFilters,
		savedAssignedFormsStatusActiveFilters,
		savedAssignedFormsCategoryActiveFilters,
		savedAssignedFormsTypeActiveFilters,
		savedAssignedFormsProjectTemplateActiveFilters,
		savedAssignedFormsCreatedByActiveFilters,
	} = useSelector((state) => state.appState);

	const [allFormsCreatedByActiveFilters, setAllFormsCreatedByActiveFilters] = useState(savedAllFormsCreatedByActiveFilters[userData?.projectId]);
	const [allFormsProjectTemplateActiveFilters, setAllFormsProjectTemplateActiveFilters] = useState(savedAllFormsProjectTemplateActiveFilters[userData?.projectId]);
	const [allFormsApprovedByActiveFilters, setAllFormsApprovedByActiveFilters] = useState(savedAllFormsApprovedByActiveFilters[userData?.projectId]);
	const [allFormsTypeActiveFilters, setAllFormsTypeActiveFilters] = useState(savedAllFormsTypeActiveFilters[userData?.projectId]);
	const [allFormsCategoryActiveFilters, setAllFormsCategoryActiveFilters] = useState(savedAllFormsCategoryActiveFilters[userData?.projectId]);
	const [allFormsStatusActiveFilters, setAllFormsStatusActiveFilters] = useState(savedAllFormsStatusActiveFilters[userData?.projectId]);

	const [assignedFormsCreatedByActiveFilters, setAssignedFormsCreatedByActiveFilters] = useState(savedAssignedFormsCreatedByActiveFilters[userData?.projectId]);
	const [assignedFormsProjectTemplateActiveFilters, setAssignedFormsProjectTemplateActiveFilters] = useState(savedAssignedFormsProjectTemplateActiveFilters[userData?.projectId]);
	const [assignedFormsTypeActiveFilters, setAssignedFormsTypeActiveFilters] = useState(savedAssignedFormsTypeActiveFilters[userData?.projectId]);
	const [assignedFormsCategoryActiveFilters, setAssignedFormsCategoryActiveFilters] = useState(savedAssignedFormsCategoryActiveFilters[userData?.projectId]);
	const [assignedFormsStatusActiveFilters, setAssignedFormsStatusActiveFilters] = useState(savedAssignedFormsStatusActiveFilters[userData?.projectId]);

	const [searchText, setSearchText] = useState("");
	const [searchBoxFilter, setSearchBoxFilter] = useState(false);
	const [createFormDialogData, setCreateFormDialogData] = useState(null);
	const [exportChecklistFormPdf, setExportChecklistFormPdf] = useState(false);
	const [exportProcesses, setExportProcesses] = useState([]);
	const [toShareItems, setShareItems] = useState([]);
	const dropdownItems = [
		{ label: "Create Checklist Template" },
		{ label: "Create PDF Template" },
		// { label: "Create New Folder" },
	];
	const FormsDataColumns = [
		{
			id: "checkbox",
			accessor: "",
			disableSortBy: true,
			Header: (
				<Checkbox
					style={{ padding: "0px", marginLeft: "10px" }}
					sx={{ padding: "0px", "&:hover": { backgroundColor: "#F8F8F8", color: "#D8D8D8" }, }}
					checked={selectedRowIds.length > 0 && selectedRowIds.length === rowsPerPage?.length}
					onChange={(e) => {
						onRowSelectionAllChange(e.target.checked);
					}}
				/>
			),
			Cell: (rowInfo) => {
				return (
					<Checkbox
						style={{ padding: "0px", marginLeft: "10px" }}
						sx={{ "&:hover": { backgroundColor: "#F8F8F8", color: "#D8D8D8" } }}
						type="checkbox"
						className="checkbox"
						checked={selectedRows.filter((row) => row.id === rowInfo?.row?.original?.id).length > 0}
						onChange={(e) =>
							onRowSelectionChange(rowInfo?.row?.original?.id, e.target.checked)
						}
					/>
				);
			},
			overrideStyle: { width: "4%" },
		},
		{
			accessor: "numberSequence",
			Header: "ID",
			overrideStyle: { width: "4%" },
			disableColumnHide: true,
			Cell: (params) => (
				<div className="table_id">
					<Box className="id_content">
						<span dangerouslySetInnerHTML={{ __html: params.row?.original?.numberSequence_mark ? params.row?.original?.numberSequence_mark : params.row?.original?.numberSequence, }}
						/>
					</Box>
				</div>
			),
		},
		{
			accessor: "title",
			Header: "Form Name",
			disableColumnHide: true,
			flex: 1,
			overrideStyle: { width: "16%" },
			Cell: (params) => {
				return (
					<>
					<div style={{ display: "flex", overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', cursor: 'pointer'}} onClick={() => {
						setFormTypeForBuild(params.row.original.formType);
						openedProjectForm(params.row.original.id)
					}}
					onContextMenu={(e) => {
					  let params_ = { id: params.row.original.id, row: params.row.original };
					  handleClickMenu(e , params_);
					}}>
						<Tooltip title={params.row.original.tooltip} className="tooltip1" style={{ display: "flex" }} >
							<div className="name_main" style={{ marginLeft: "10px", color: "#505050", }} >
								<a href={params.row.original.inappurl} onClick={(e)=>{
									e.preventDefault();
								}}>
									<b style={{ color: "black" }}>
										<span dangerouslySetInnerHTML={{ __html: params.row.original.title_mark ? params.row.original.title_mark : params.row.original.title, }} />
									</b>
								</a>
								<br />
								<span dangerouslySetInnerHTML={{ __html: params.row.original.type_mark ? params.row.original.type_mark : params.row.original.formType, }} />
							</div>
						</Tooltip>
					</div>
					{params.row.original.Shared_Links__r?.length > 0  &&
						<Tooltip title="Document is being shared" placement="bottom" >
							<div style={{ marginLeft: 'auto' }} onClick={(e) => {
								e.stopPropagation();
								setShareItems([params.row.original]);
							}}>
								<img style={{ marginRight: "6px" }} src={copyLinkIcon} alt="copy" />
							</div>
						</Tooltip>
					}
					</>
				);
			},
		},
		{
			accessor: "form",
			Header: "Template Name",
			flex: 1,
			disableColumnHide: true,
			overrideStyle: { width: "16%" },
		},
		{
			accessor: "category",
			Header: "Category",
			flex: 1,
			overrideStyle: { width: "12%" },
		},
		{
			accessor: "status",
			Header: "Status",
			flex: 1,
			disableColumnHide: true,
			overrideStyle: { width: "12%" },
			Cell: (params) => {
				return (
					<div style={{ display: "flex" }}>
						<div>
							<img src={params.row.original.status == "Completed" ? closedIcon
								: params.row.original.status == "In Progress" ? inProgressIcon
									: notStartedIcon} alt="statusIcons" />
						</div>
						<div style={{ color: "#505050", marginLeft: "5px" }}>
							{params.row.original.status}
						</div>
					</div>
				);
			},
		},

		{
			accessor: "assignedTo",
			Header: "Ball In Court",
			flex: 1,
			disableColumnHide: true,
			overrideStyle: { width: "12%" },
			Cell: (params) => {
				return (
					<div style={{ display: "flex" }}>
						<div style={{ color: "#505050", marginRight: "5px", fontSize: "14px", fontWeight: 400, }} >
							{params.row.original.user}
							<div>{params.value}</div>
						</div>
						<div>
							<img src={userGroupIcon} alt="groupIcon" />
						</div>
					</div>
				);
			},
		},
		{
			accessor: "updatedBy",
			Header: "Last Updated By",
			flex: 1,
			overrideStyle: { width: "10%" },
			disableSortBy: true,
			Cell: (params) => {
				return (
					<div style={{ display: "flex" }}>
						<div style={{ marginTop: "5px" }}>
							<img src="user.svg" alt="statusIcons" />
						</div>
						<div style={{ color: "#505050", marginLeft: "5px", fontSize: "14px", fontWeight: 400, }} >
							{params.row.original.createdBy}
							<div>{moment(params.row.original.createdDate).format("MMM D, YYYY")}</div>
						</div>
					</div>
				);
			},
		},
		{
			accessor: "created",
			Header: "Created",
			flex: 1,
			overrideStyle: { width: "10%" },
			disableSortBy: true,
			Cell: (params) => {
				return (
					<div style={{ display: "flex" }}>
						<div style={{ marginTop: "5px" }}>
							<img src="user.svg" alt="statusIcons" />
						</div>
						<div style={{ color: "#505050", marginLeft: "5px", fontSize: "14px", fontWeight: 400, }} >
							{params.row.original.createdBy}
							<div>{moment(params.row.original.createdDate).format("MMM D, YYYY")}</div>
						</div>
					</div>
				);
			},
		},
		{
			accessor: "settings",
			Header: "settings",
			flex: 1,
			disableSortBy: true,
			overrideStyle: { width: "4%" },
		},
	];
	useEffect(() => {
		//console.log('change state :: ',location.state);
		setState(location.state);
	}, [location?.state]);
	useEffect(() => {
		//console.log('files use effect ::',userData?.projectId,_projectId);
		if (_projectId !== userData?.projectId) {
			if (userData?.projectId) {
				//console.log("files use effect :: in ");
				setProjectId(userData?.projectId);
				setCredential(userData);
				getTemplateOptions();
				fetchProjectFormLists(userData);
				fetchTemplateList(userData);
				let ffff = stateRef.current;
				if (ffff) {
					if(ffff?.redirectId && ffff?.redirectId != null){
						openDefaultFormRecord(ffff?.redirectId);
					}
					window.history.replaceState({}, document.title);
					setState(null);
				}
			}
		}
	}, [userData?.projectId]);
	useEffect(() => {
		setCredential(userData);
	}, [userData?.userList]);
	useEffect(() => {
		//console.log('projectId :: '+projectId);
		setViewListData(currentTabFormList);
	}, [currentTabFormList]);
	useEffect(() => {
		let cc = 0;
		if (activeTabRef.current === 0) {
			if (allFormsTypeActiveFilters?.length > 0) {
				cc += allFormsTypeActiveFilters.length;
			}
			if (allFormsCategoryActiveFilters?.length > 0) {
				cc += allFormsCategoryActiveFilters.length;
			}
			if (allFormsStatusActiveFilters?.length > 0) {
				cc += allFormsStatusActiveFilters.length;
			}
			if (allFormsCreatedByActiveFilters?.length > 0) {
				cc += allFormsCreatedByActiveFilters.length;
			}
			if (allFormsApprovedByActiveFilters?.length > 0) {
				cc += allFormsApprovedByActiveFilters.length;
			}
			if (allFormsProjectTemplateActiveFilters?.length > 0) {
				cc += allFormsProjectTemplateActiveFilters.length;
			}
			setAllFormsTotalFilterApplied(cc);
		} else if (activeTabRef.current === 1) {
			if (assignedFormsTypeActiveFilters?.length > 0) {
				cc += assignedFormsTypeActiveFilters.length;
			}
			if (assignedFormsCategoryActiveFilters?.length > 0) {
				cc += assignedFormsCategoryActiveFilters.length;
			}
			if (assignedFormsStatusActiveFilters?.length > 0) {
				cc += assignedFormsStatusActiveFilters.length;
			}
			if (assignedFormsCreatedByActiveFilters?.length > 0) {
				cc += assignedFormsCreatedByActiveFilters.length;
			}
			if (assignedFormsProjectTemplateActiveFilters?.length > 0) {
				cc += assignedFormsProjectTemplateActiveFilters.length;
			}
			setAssignedFormsTotalFilterApplied(cc);
		}
		if (processTofilter_.current) {
			onHandleFilterData(cc);
		}
		processTofilter_.current = false;
	}, [allFormsTypeActiveFilters, allFormsCategoryActiveFilters, allFormsStatusActiveFilters, allFormsCreatedByActiveFilters, allFormsApprovedByActiveFilters, allFormsProjectTemplateActiveFilters, assignedFormsTypeActiveFilters, assignedFormsCategoryActiveFilters, assignedFormsStatusActiveFilters, assignedFormsCreatedByActiveFilters, assignedFormsProjectTemplateActiveFilters, currentTabFormList]);
	function setActiveTab_(tabValue) {
		activeTabRef.current = tabValue;
		setActiveTabState(tabValue);
	}
	function onActiveTabChange(tabValue) {
		setSpinner(true);
		setActiveTab_(tabValue);
		if (tabValue !== 3) {
			setCurrentTabFormList(getCurrentViewData(fetchedFormList));
		}
		searchListViewFunction('');
		setSpinner(false);
	}
	const onRowSelectionAllChange = (isChecked) => {
		setSelectedRowIds([]);
		let rowIds = [];
		//console.log('rowsPerPage :: ',rowsPerPage?.length,isChecked);
		if (isChecked === true) {
			rowsPerPage.map((row) => rowIds?.push(row.id));
		}
		setSelectedRowIds(rowIds);
		const selRowIds = new Set(rowIds);
		const seleFolRows = viewListData.filter((row) => selRowIds.has(row.id.toString()));
		setSelectedRows(seleFolRows);
	}
	const openDefaultFormRecord = async(formId)=> {
		let reqb = {qry : `SELECT Id,Form_Template__c,Form_Template__r.Form_Type__c FROM Form__c WHERE Id='${formId}' AND Form_Template__c !=null`}
		await axios.post(`${fetchUrl}/execute`,reqb).then(res=>{
			if(res.status === 200){
				let {records} = res.data;
				if(records?.length > 0){
					let formType = records[0].Form_Template__r.Form_Type__c;
					setFormTypeForBuild(formType);
					openedProjectForm(formId);
				}
			}
		}).catch(err=>{
			console.log('execute' , err);
		});
	};
	const onRowSelectionChange = (rowId, isChecked) => {
		//console.log('rowsPerPage :: ',rowsPerPage?.length,rowId,isChecked,selectedRowIds?.length);
		let rowIds = [];
		if (isChecked === true) {
			rowIds = selectedRowIds.length > 0 ? selectedRowIds : [];
			rowIds?.push(rowId);
		} else {
			rowIds = selectedRowIds.length > 0 ? selectedRowIds.filter((item) => item !== rowId) : [];
		}
		//console.log('rowIds :: ',rowIds?.length);
		setSelectedRowIds(rowIds);
		const selRowIds = new Set(rowIds);
		const seleFolRows = viewListData.filter((row) => selRowIds.has(row.id.toString()));
		setSelectedRows(seleFolRows);
	};
	async function refreshTableList(_credential = credential) {
		handleCloseIcon();
		setCurrentTabFormList([]);
		setViewListData([]);
		setFetchedFormList([]);
		setAssigntoMeListData([]);
		setMyFormListData([]);
		setSelectedMenuRow(null);
		fetchProjectFormLists(_credential);
	};
	const handleClearAllFilters = () => {
		handleClearProjectAppStateFilters();
		processTofilter_.current = true;
		setViewListData(getCurrentViewData(fetchedFormList));
		setActiveFilterDropdown(undefined);
		if (activeTabRef.current === 0) {
			setAllFormsTypeActiveFilters([]);
			setAllFormsCategoryActiveFilters([]);
			setAllFormsStatusActiveFilters([]);
			setAllFormsCreatedByActiveFilters([]);
			setAllFormsApprovedByActiveFilters([]);
			setAllFormsProjectTemplateActiveFilters([]);
		} else {
			setAssignedFormsTypeActiveFilters([]);
			setAssignedFormsCategoryActiveFilters([]);
			setAssignedFormsStatusActiveFilters([]);
			setAssignedFormsCreatedByActiveFilters([]);
			setAssignedFormsProjectTemplateActiveFilters([]);
		}
		onHandleFilterData(0);
	};

	const handleClearProjectAppStateFilters = () => {
		  let payload = {projectId : credential?.projectId , data : []};
		  if(activeTabRef.current === 0){
			dispatch(setSavedAllFormsStatusActiveFilters(payload));
			dispatch(setSavedAllFormsCategoryActiveFilters(payload));
			dispatch(setSavedAllFormsTypeActiveFilters(payload));
			dispatch(setSavedAllFormsApprovedByActiveFilters(payload));
			dispatch(setSavedAllFormsProjectTemplateActiveFilters(payload));
			dispatch(setSavedAllFormsCreatedByActiveFilters(payload));
		  }else if(activeTabRef.current === 1){
			dispatch(setSavedAssignedFormsStatusActiveFilters(payload));
			dispatch(setSavedAssignedFormsCategoryActiveFilters(payload));
			dispatch(setSavedAssignedFormsTypeActiveFilters(payload));
			dispatch(setSavedAssignedFormsProjectTemplateActiveFilters(payload));
			dispatch(setSavedAssignedFormsCreatedByActiveFilters(payload));
		  }
		};
	async function onHandleFilterData(_totalFilterApplied = activeTabRef.current === 0 ? allFormsTotalFilterApplied : assignedFormsTotalFilterApplied) {
		//console.log("running ::");
		let filteredFoundList = [];
		let orgData = cloneDeep(currentTabFormListRef?.current);
		if (orgData?.length > 0 && _totalFilterApplied > 0) {
			for (const currRow of orgData) {
				let matched = true;
				if (activeTabRef.current === 0 && allFormsTypeActiveFilters?.length > 0 && matched === true) {
					matched = false;
					if (currRow.formType) {
						matched = allFormsTypeActiveFilters.includes(currRow.formType);
					}
				}
				if (activeTabRef.current === 0 && allFormsCategoryActiveFilters?.length > 0 && matched === true) {
					matched = false;
					if (currRow.category) {
						matched = allFormsCategoryActiveFilters.includes(currRow.category);
					}
				}
				if (activeTabRef.current === 0 && allFormsStatusActiveFilters?.length > 0 && matched === true) {
					matched = false;
					if (currRow.status) {
						matched = allFormsStatusActiveFilters.includes(currRow.status);
					}
				}
				if (activeTabRef.current === 0 && allFormsApprovedByActiveFilters?.length > 0 && matched === true) {
					matched = false;
					const valueFound = allFormsApprovedByActiveFilters.find(
						(usss) => usss.value === currRow.assignedTo_Id
					);
					if (valueFound) {
						matched = true;
					}
				}
				if (activeTabRef.current === 0 && allFormsCreatedByActiveFilters?.length > 0 && matched === true) {
					matched = false;
					const valueFound = allFormsCreatedByActiveFilters.find(
						(usss) => usss.value === currRow.createdById
					);
					if (valueFound) {
						matched = true;
					}
				}
				if (activeTabRef.current === 0 && allFormsProjectTemplateActiveFilters?.length > 0 && matched === true) {
					matched = false;
					const valueFound = allFormsProjectTemplateActiveFilters.find(
						(usss) => usss.Id === currRow.rObj.Form_Template__c
					);
					if (valueFound) {
						matched = true;
					}
				}


				if (activeTabRef.current === 1 && assignedFormsTypeActiveFilters?.length > 0 && matched === true) {
					matched = false;
					if (currRow.formType) {
						matched = assignedFormsTypeActiveFilters.includes(currRow.formType);
					}
				}
				if (activeTabRef.current === 1 && assignedFormsCategoryActiveFilters?.length > 0 && matched === true) {
					matched = false;
					if (currRow.category) {
						matched = assignedFormsCategoryActiveFilters.includes(currRow.category);
					}
				}
				if (activeTabRef.current === 1 && assignedFormsStatusActiveFilters?.length > 0 && matched === true) {
					matched = false;
					if (currRow.status) {
						matched = assignedFormsStatusActiveFilters.includes(currRow.status);
					}
				}
				if (activeTabRef.current === 1 && assignedFormsCreatedByActiveFilters?.length > 0 && matched === true) {
					matched = false;
					const valueFound = assignedFormsCreatedByActiveFilters.find(
						(usss) => usss.value === currRow.createdById
					);
					if (valueFound) {
						matched = true;
					}
				}
				if (activeTabRef.current === 1 && assignedFormsProjectTemplateActiveFilters?.length > 0 && matched === true) {
					matched = false;
					const valueFound = assignedFormsProjectTemplateActiveFilters.find(
						(usss) => usss.Id === currRow.rObj.Form_Template__c
					);
					if (valueFound) {
						matched = true;
					}
				}
				if (matched) {
					filteredFoundList.push(currRow);
				}
			}
		} else {
			filteredFoundList = orgData;
		}
		//console.log('onHandleFilterData filteredFoundList :: '+filteredFoundList?.length);
		if(activeTabRef.current === 0){
		setAllFormsFilteredTableData(filteredFoundList);
		}
		if(activeTabRef.current === 1){
			setAssignedFormsFilteredTableData(filteredFoundList);
			}
		setViewListData(filteredFoundList);
	}
	async function searchListViewFunction(searchTerm) {
		setSearchBoxFilter(false);
		setSearchText(searchTerm);
		let orgData = (allFormsFilteredTableData?.length > 0 || allFormsTotalFilterApplied > 0) && activeTabRef.current === 0 ? cloneDeep(allFormsFilteredTableData) : (assignedFormsFilteredTableData?.length > 0 || assignedFormsTotalFilterApplied > 0) && activeTabRef.current === 1 ? cloneDeep(assignedFormsFilteredTableData) : cloneDeep(currentTabFormList);
		//console.log(searchTerm,'orgData :: '+orgData?.length);
		let searchFoundList = orgData;
		if (orgData) {
			if (searchTerm && searchTerm !== null && searchTerm !== "") {
				searchFoundList = [];
				for (let el of orgData) {
					if ((el.numberSequence && el.numberSequence.toLowerCase().includes(searchTerm)) || (el.title && el.title.toLowerCase().includes(searchTerm))) {
						if (el.numberSequence && el.numberSequence.toLowerCase().includes(searchTerm)) {
							el.numberSequence_mark = highlightText(el.numberSequence, searchTerm);
						}
						if (el.title && el.title.toLowerCase().includes(searchTerm)) {
							el.title_mark = highlightText(el.title, searchTerm);
						}
						searchFoundList.push(el);
					}
				}
				setSearchBoxFilter(true);
			}
		}
		//console.log(searchTerm, 'searchFoundList :: ' + searchFoundList?.length);
		setViewListData(searchFoundList);
	}
	const handleCloseIcon = () => {
		setOpenCreateForm(false);
		setSelectedRowIds([]);
		setSelectedRows([]);
		setCurrentStep(1);
	};
	function setCurrentTabFormList(values) {
		processTofilter_.current = true;
		currentTabFormListRef.current = values;
		setCurrentTabFormListState(values);
	}
	async function fetchProjectFormLists(_credential = credential) {
		setSpinner(true);
		var allValues = [];
		let usersList = credential?.userList?.length > 0 ? cloneDeep(credential?.userList) : [];
		await axios.get(`${fetchUrl}/projectFormsLists/${_credential.projectId}`)
			.then((res) => {
				if (res?.status === 200) {
					const reData = res.data;
					if (reData?.status === 200) {
						allValues = _.map(reData?.projectForms, (row) => {
							const row_ = prepareProjectFormTableRow(row , _credential);
							if (row_.createdById) {
								let find = usersList.findIndex((u) => u.value === row_.createdById);
								if (!(find > -1)) {
									usersList.push({ Id: row_.createdById, Name: row_.createdBy, value: row_.createdById, label: row_.createdBy, key: row_.createdById })
								}
							}
							if (row_.assignedTo_Id) {
								let find = usersList.findIndex((u) => u.value === row_.assignedTo_Id);
								if (!(find > -1)) {
									usersList.push({ Id: row_.assignedTo_Id, Name: row_.assignedTo, value: row_.assignedTo_Id, label: row_.assignedTo, key: row_.assignedTo_Id })
								}
							}
							return row_;
						});
					}
				}
			}).catch((err) => {
				setSpinner(false);
				console.error(err);
			});
		let usersList_ = cloneDeep(usersList);
		usersList_.sort((a, b) => {
			return a.Name.localeCompare(b.Name);
		});
		setUserFilterOptions(usersList_);
		setFetchedFormList(allValues);
		setCurrentTabFormList(getCurrentViewData(allValues, true));
		setSpinner(false);
	}

	  async function fetchTemplateList(_credential = credential) {
		setTemplateSpinner(true);
		let usersList = credential?.userList?.length > 0 ? cloneDeep(credential?.userList) : [];
		await axios.get(`${fetchUrl}/formTemplateLists/${_credential?.projectId}`)
		  .then((res) => {
			if (res?.status === 200) {
			  const reData = res.data;
			  if (reData?.status === 200) {
				const values2 = _.map(reData?.projectFormTemplates, (row) => {
				  let row_ = prepareFormTemplateObj(row);
				  if(row_.createdById){
					let find = usersList.findIndex((u) => u.value === row_.createdById);
					if( !(find > -1) ){
					  usersList.push({Id: row_.createdById, Name : row_.createdBy, value:row_.createdById, label: row_.createdBy , key : row_.createdById})
					}
				  }
				  if(row_.updatedById){
					let find = usersList.findIndex((u) => u.value === row_.updatedById);
					if( !(find > -1) ){
					  usersList.push({Id: row_.updatedById, Name : row_.updatedBy, value:row_.updatedById, label: row_.updatedBy , key : row_.updatedById})
					}
				  }
				  return row_;
				});
				setProjectFormTemplates(values2);
				const filteredData = values2.filter(item => item.status === 'Active' || item.status === 'Inactive');
				setProjectTemplateFilterOptions(filteredData);
			  }
			}
		  }).catch((err) => {
			setTemplateSpinner(false);
			console.error(err);
		  });
		  setTemplateSpinner(false);
		  let usersList_ = cloneDeep(usersList);
		  usersList_.sort((a, b) => {
			return a.Name.localeCompare(b.Name);
		  });
		  setTemplateUserFilterOptions(usersList_);
	  };

	const getCurrentViewData = (fetchedFormList_ = fetchedFormList, setValueToState) => {
		let retunList = [];
		let asignedToMe = [];
		let myIssues = [];
		if (fetchedFormList_?.length > 0) {
			if (activeTabRef.current === 0) {
				retunList = JSON.parse(JSON.stringify(fetchedFormList_));
			}
			for (let issue of fetchedFormList_) {
				let row = JSON.parse(JSON.stringify(issue));
				if (row.assignedTo_Id === credential?.userId && (openFormStatus.includes(row.status))) {
					if (activeTabRef.current === 1) {
						retunList.push(row);
					}
					asignedToMe.push(row);
				}
				if (row.createdById === credential?.userId) {
					if (activeTabRef.current === 2) {
						retunList.push(row);
					}
					myIssues.push(row);
				}
			}
		}
		if (setValueToState === true) {
			setAssigntoMeListData(asignedToMe);
			setMyFormListData(myIssues);
		}
		return retunList;
	};

	async function getTemplateOptions() {
		if (!templateOptions) {
			setSpinner(true);
			let temOptions;
			let formOptions;
			await axios.get(`${fetchUrl}/form_template_options`)
				.then((res) => {
					if (res?.status === 200) {
						const reData = res.data;
						temOptions = { checkListOptions: reData?.checkListOptions };
						temOptions.questionTypeOptions = reData?.itemTypeOptions;
						formOptions = reData?.formOptions;
					}
				}).catch((err) => {
					setSpinner(false);
					console.error(err);
				});
			setTemplateOptions(temOptions);
			setFormOptions(formOptions);
			setSpinner(false);
		}
	};
	const handleClickMenu = (event, parms) => {
		//console.log('handleClickMenu :: ',parms);
		event.preventDefault();
		event.stopPropagation();
		setSelectedMenuRow(parms.row);
		//setAnchorEl(event.currentTarget);
		setAnchorEl({ mouseX: event.clientX, mouseY: event.clientY });
	};
	async function onClickOpenAction(action, clickSource) {
		let dialogData;
		if (clickSource == "menu" && action === 'Delete') {
			dialogData = {
				title: `Delete Form?`,
				content: `Are you sure you want to delete this form? Once deleted, it cannot be recovered.`,
				action1: "Cancel",
				action2: "Delete",
				source: clickSource,
				operation: action,
				operationItems: [selectedMenuRow]
			};
			setWarningDialodData(dialogData);
		} else if (clickSource == "header" && action === 'Delete') {
			dialogData = {
				title: `Delete Form${selectedRows.length > 1 ? "s" : ""}?`,
				content: `Are you sure you want to delete the selected form${selectedRows.length > 1 ? "s" : ""}?  The deleted form${selectedRows.length > 1 ? "s" : ""} cannot be recovered.`,
				action1: "Cancel",
				action2: "Delete",
				source: clickSource,
				operation: action,
				operationItems: clickSource === 'menu' ? selectedMenuRow : selectedRows
			};
			setWarningDialodData(dialogData);
		}
	};
	const handleCloseMenu = () => {
		setAnchorEl(null);
		setSelectedMenuRow(null);
	};
	const onDeleteForm = async (status_, selectedMenu) => {
		setWarningDialodData(null)
		setSpinner(true);
		await axios.delete(`${fetchUrl}/delete/Form__c/${selectedMenu.id}?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`).then((res) => {
			let tmsg = `Form has been deleted.`;
			refreshTableList();
			setToastMessage(tmsg);
		})
			.catch((err) => {
				console.log(err);
			});
	};
	const deleteSelectedItems = async (status_, selectedMenu) => {
		if (selectedRows.length > 0) {
			setWarningDialodData(null)
			setSpinner(true);
			let k = 0;
			Promise.all(selectedRows).then((values) => {
				values.forEach(async (f) => {
					await axios.delete(
						`${fetchUrl}/delete/Form__c/${f.id}?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`
					).then((res) => {
					})
					k++;
					if (k === selectedRows.length) {
						let tmsg = `Form${selectedRows.length > 1 ? "s" : ""} has been deleted.`;
						setToastMessage(tmsg);
						refreshTableList();
					}
				});
			});
		}
	};
	const handleExportClick = (toExport) => {
	  if(toExport?.length > 0){
		//console.log('credential?.projectName :: ',credential?.projectId,credential?.projectName);
		const fname = toExport?.length > 1 ? `${credential?.projectName} Forms.zip` : toExport[0].title;
		let formIds = toExport.map(t=>t.id);
		let que_obj = {id : makeRandomId(3)+fname+'_'+makeRandomId(5) , formIds:formIds,
							name :fname , label  :fname , started : true};
		if(toExport?.length == 1){
			que_obj.icon = pdfRed;
		}
		setItemToBgProcess(que_obj);
	  }
	};

	const handleAssignDialogData = () => {
		console.log("selectedRows :", selectedRows);
		const sameBallInCourt = selectedRows.every(item => item.assignedTo_Id === selectedRows[0].assignedTo_Id);
		let dialogData;
		dialogData = {
			title: `Assign/Reassign Ball in Court`,
			content: `Current Ball in Court`,
			contentValue: sameBallInCourt ? selectedRows[0].assignedTo : 'Multiple Users',
			selectLabel: 'New Ball in Court',
			action1: "Cancel",
			action2: "Save Changes",

		};
		setAssignDialog(dialogData);
	  };

	const setItemToBgProcess=(queue_obj)=>{
		setExportProcesses(pv=>{
		 let t = cloneDeep(pv);
			if(!t){
			  t = [];
			}
			let fi = t.findIndex(k=>k.id === queue_obj.id);
			if(fi > -1){
			  t[fi] = {...t[fi] , ...queue_obj};
			}else{
			  t.push(queue_obj);
			}
			let _opn_count = t.filter(l=> !l.processed);
			if(_opn_count?.length > 0){
				setExportChecklistFormPdf(true);
			}else{
				setExportChecklistFormPdf(false);
			}
		 return t;
	  });
	}
	const handleFormDialogData = async () => {
		let dialogData = {headerTitle : 'Create Form', showSteps: true, showTemplateDetail: true, button1: 'Cancel', button2: "Create Form"}
	   setCreateFormDialogData(dialogData);
	  }

	  const updateTableStateForLink = (links) => {
		if (links?.length > 0) {
		  setSpinner(true);
		  toAddLinksToElementbyId(links, viewListData, setViewListData);
		  toAddLinksToElementbyId(links, currentTabFormListRef.current, setCurrentTabFormList);
		  toAddLinksToElementbyId(links, allFormsFilteredTableData, setAllFormsFilteredTableData);
		  toAddLinksToElementbyId(links, assignedFormsFilteredTableData, setAssignedFormsFilteredTableData);
		  setSpinner(false);
		}
		function toAddLinksToElementbyId(links, _list, setToList) {
		  let _tempList = cloneDeep(_list);
		  if (_tempList?.length > 0) {
			let change = false;
			for (let lnk of links) {
			  if (lnk.id) {
				let rowId = lnk.id;
				let link = lnk.link;
				const fInd = _tempList?.findIndex((data) => data.id === rowId);
				if (fInd > -1) {
				  if(link){
					if (!(_tempList[fInd].Shared_Links__r?.length > 0)) {
					  _tempList[fInd].Shared_Links__r = [link];
					  change = true;
					}
				  }else{
					if ((_tempList[fInd].Shared_Links__r?.length > 0)) {
					  _tempList[fInd].Shared_Links__r = [];
					  change = true;
					}
				  }
				}
			  }
			}
			if (change == true) {
			  if (setToList) {
				setToList(_tempList);
			  }
			}
		  }
		  return _tempList;
		}
	  };
	  
	return (
		<Box sx={{ display: "flex" }}>
			<Box component="main" sx={{ flexGrow: 1, width: { sm: "100%" }, height: `calc(100vh - 113px)`, overflow: "hidden", }} >
				<div className="wrapper_main">
					<div className="table_head">
						<div className="header_heading">
							<h1>Forms</h1>
						</div>
						<div className="right_side">
							{activeTab === 3 && (
								<>
									<Button className="custom_button cancel_button" onClick={(event) => { importTemplatesButton.current.click() }}>
										Import
									</Button>
									<SplitButtonContainedAction
										label="Create"
										items={dropdownItems}
										onAction={(label) => {
											if (label === 'Create Checklist Template') {
												addCheckListButton.current.click();
											}
											if (label === 'Create PDF Template') {
												addPdfButton.current.click();
											}
										}}
									/>
								</>
							)}
							{(activeTab === 0 || activeTab === 1 )&& (
								<Button component="button" className="custom_button apply_button" onClick={() => {
									setCurrentStep(1);
									handleFormDialogData();
									setOpenCreateForm(true);
								}}>
									Create Form
								</Button>
							)}
						</div>
					</div>
					<div className="tab_wrapper" style={{ paddingLeft: "10px" }}>
						<Tabs value={activeTab} textColor="primary" indicatorColor="primary" onChange={(event, newValue) => { onActiveTabChange(newValue); }} >
							<Tab label="All Forms" />
							<Tab
								label={
									<div className="publish-logs">
										<div className="text-and-kpi">
											<div className="label poppins-normal-blue-14px">
												{" "} Forms Assigned to me
											</div>
											<div className="kpi">
												<div className="x1 poppins-normal-white-10px"> {" "}
													{assigntoMeListData.filter((issue) => openFormStatus.includes(issue.status)).length}{" "}
												</div>
											</div>{" "}
										</div>
									</div>
								}
							/>
							<Tab style={{ display: "none" }}
								label={
									<div className="publish-logs">
										<div className="text-and-kpi">
											<div className="label poppins-normal-blue-14px"> {" "} My Forms
											</div>
											<div className="kpi">
												<div className="x1 poppins-normal-white-10px"> {" "}
													{myFormListData.filter((issue) => openFormStatus.includes(issue.status)).length}{" "}
												</div>
											</div>{" "}
										</div>
									</div>
								}
							/>
							<Tab label="Templates"></Tab> {/*  onChange={(event, newValue) => { setActiveTabState(newValue); }} */}
						</Tabs>
					</div>
					{activeTab !== 3 && (
						<div className="page_body">
							<div style={{ width: "100%" }}>
								<div className="page_body_right_head">
									<div className="page_body_right_head_left_side">
										<div style={{ display: "flex", alignSelf: "center", marginRight: "10px", borderRight: "1px solid #EAEAEA", }} >
											{selectedRowIds.length > 0 && (
												<div className="buttons" style={{ paddingBottom: "4px", cursor: "pointer" }} onClick={handleCloseIcon}>
													<img className="close" src="close.svg" alt="close" style={{ cursor: "pointer" }} />
												</div>
											)}
											<div style={{ marginRight: "10px" }}>
												<h2>{selectedRowIds.length} Selected</h2>
											</div>
										</div>
										{selectedRowIds.length > 0 && (
											<>
												{selectedRowIds.length == 1 &&
													<div style={{ border: "1px solid #EAEAEA" }} onClick={() => {
														setFormTypeForBuild(selectedRows[0].formType);
														openedProjectForm(selectedRows[0].id)
													}}>
														<Tooltip PopperProps={{ className: "version-action-tooltip" }} title="Edit" placement="top" arrow>
															<IconButton sx={{ borderRadius: 0 }}>
																<img src="/Pencil.svg" alt="share" />
															</IconButton>
														</Tooltip>
													</div>
												}
												<div style={{ border: "1px solid #EAEAEA" }} onClick={() => {
													//setExportChecklistFormPdf(true)
													let toExport = selectedRows;
													handleExportClick(selectedRows);
													//toast.info("Download started");
												}}>
													<Tooltip PopperProps={{ className: "version-action-tooltip" }} title="Export" placement="top" arrow>
														<IconButton sx={{ borderRadius: 0 }}>
															<img src="/download.svg" alt="share" />
														</IconButton>
													</Tooltip>
												</div>
												<div style={{ border: "1px solid #EAEAEA" }}>
													<Tooltip PopperProps={{ className: "version-action-tooltip" }} title="Archive" placement="top" arrow>
														<IconButton sx={{ borderRadius: 0 }}>
															<img src="/archiveFormIcon.svg" alt="share" />
														</IconButton>
													</Tooltip>
												</div>
												{selectedRows.length > 0 && (
													<div style={{ border: "1px solid #EAEAEA" }}>
														<Tooltip title="Share" placement="top" PopperProps={{ className: "version-action-tooltip" }} arrow >
															<IconButton
																sx={{ borderRadius: 0 }}
																onClick={() => {
																	const completedForms = selectedRows.filter(row => row.status === "Completed");
																	setShareItems(completedForms);
																}}
															>
																<img src="/share.svg" alt="share" />
															</IconButton>
														</Tooltip>
													</div>
												)}
												{(selectedRows?.filter((tem) => tem.status == 'Completed').length < 1) &&
													<div style={{ border: "1px solid #EAEAEA" }} onClick={() => { onClickOpenAction('Delete', 'header') }}>
														<Tooltip PopperProps={{ className: "version-action-tooltip" }} title="Delete" placement="top" arrow>
															<IconButton sx={{ borderRadius: 0 }}>
																<img src="/delete.svg" alt="share" />
															</IconButton>
														</Tooltip>
													</div>
												}
												<div style={{ border: "1px solid #EAEAEA" }} onClick={()=>{
													handleAssignDialogData();
												}}>
													<Tooltip PopperProps={{ className: "version-action-tooltip" }} title="Assign/Reassign" placement="top" arrow>
														<IconButton sx={{ borderRadius: 0 }}>
															<img style={{height: '17px', width: '16px'}} src="/user.svg" alt="share" />
														</IconButton>
													</Tooltip>
												</div>
											</>
										)}
									</div>
									<div className="page_body_right_head_right_side">
										<div>
											<TextField
												sx={{ "& .MuiInputBase-root": { color: "#505050", height: "37px", padding: "4px", width: "200px", } }}
												id="outlined-basic"
												variant="outlined"
												value={searchText}
												fullWidth
												className="Searchbar-field"
												autoComplete="off"
												placeholder="Search Form"
												InputProps={{
													endAdornment: (
														<InputAdornment position="start">
															{searchBoxFilter ? (
																<img
																	style={{ width: "25px", cursor: "pointer" }}
																	src="/cancel.svg"
																	alt="close icon"
																	onClick={() => {
																		searchListViewFunction("");
																		setSearchText("");
																	}}
																/>
															) : (
																<Search />
															)}
														</InputAdornment>
													),
												}}
												onChange={(e) => {
													//convert input text to lower case
													var lowerCase = e.target.value.toLowerCase();
													if (!lowerCase || lowerCase === null || lowerCase === "") {
														searchListViewFunction(lowerCase);
													}
													setSearchText(lowerCase);
												}}
												onKeyPress={(e) => {
													if (e.key === "Enter") {
														// Do code here
														e.preventDefault();
														var lowerCase = e.target.value.toLowerCase();
														//console.log('searchText ',searchText , 'lowerCase',lowerCase);
														searchListViewFunction(searchText);
													}
												}}
											/>
										</div>
										<div className={"grid"} style={{ width: "114px" }}>
											<img className="filter2" src="/download.svg" alt="filter2" />
											<div className="label-11 poppins-normal-abbey-14px">
												Export All
											</div>
										</div>
										<div className={(allFormsTotalFilterApplied > 0 && activeTabRef.current === 0) || (assignedFormsTotalFilterApplied > 0 && activeTabRef.current === 1) ? filterOptionsVisible ? "grid-11" : "grid-111" : filterOptionsVisible ? "grid-1" : "grid"}
											onClick={() => { showFilterOptions(!filterOptionsVisible); }} >
											<img className="filter2" src="/filter2 (1).svg" alt="filter2" />
											<div className="label-11 poppins-normal-abbey-14px">
												Filters{" "} {(allFormsTotalFilterApplied > 0 && activeTabRef.current === 0) ? (<>{" (" + allFormsTotalFilterApplied + ")"}</>)  : (assignedFormsTotalFilterApplied > 0 && activeTabRef.current === 1) && (<>{" (" + assignedFormsTotalFilterApplied + ")"}</>)}
											</div>
											{((allFormsTotalFilterApplied > 0 && activeTabRef.current === 0) || (assignedFormsTotalFilterApplied > 0 && activeTabRef.current === 1)) && !filterOptionsVisible &&(
												<div className={filterOptionsVisible ? "grid-1" : "grid-2"} onClick={handleClearAllFilters}>
													<div className="clear1">
														<img className="clear" src="close.svg" alt="close" />
													</div>
												</div>
											)}
										</div>
									</div>
								</div>
								{(searchBoxFilter) && (
									<div
										className="bottom_area"
										style={{
											paddingBottom: "16px",
											paddingLeft: "16px",
											fontSize: "14px",
											fontWeight: 400,
										}}
									>
										{searchBoxFilter && (
											<>
												{" "}
												{viewListData.length} Search Results for{" "}
												<b>"{searchText}"</b>
											</>
										)}
									</div>
								)}
								{filterOptionsVisible && (
									<Design>
										<div className="bottom_area" style={{ paddingBottom: "16px" }} >
											<div className="filters">
												<DropdownFilter
													dropLabel="Template"
													labelProp='Name'
													keyProp='Id'
													options={projectTemplateFilterOptions}
													activeFilterDropdown={activeFilterDropdown}
													setActiveFilterDropdown={setActiveFilterDropdown}
													activeFilters={activeTabRef.current === 0 ? allFormsProjectTemplateActiveFilters : assignedFormsProjectTemplateActiveFilters}
													onFilterChange={async (newValues) => {
														processTofilter_.current = true;
														let payload = {projectId : credential?.projectId , data : newValues};
														if(activeTabRef.current === 0){
															dispatch(setSavedAllFormsProjectTemplateActiveFilters(payload));
															setAllFormsProjectTemplateActiveFilters(newValues);
														}else{
															dispatch(setSavedAssignedFormsProjectTemplateActiveFilters(payload));
															setAssignedFormsProjectTemplateActiveFilters(newValues);
														}
													}}
												/>
												<DropdownFilter
													isValueArray={true}
													dropLabel="Template Type"
													options={templateOptions?.checkListOptions?.Form_Type__c?.valueList}
													activeFilterDropdown={activeFilterDropdown}
													setActiveFilterDropdown={setActiveFilterDropdown}
													activeFilters={activeTabRef.current === 0 ? allFormsTypeActiveFilters : assignedFormsTypeActiveFilters} 
													onFilterChange={async (newValues) => {
														processTofilter_.current = true;
														let payload = {projectId : credential?.projectId , data : newValues};
														if(activeTabRef.current === 0){
															dispatch(setSavedAllFormsTypeActiveFilters(payload));
															setAllFormsTypeActiveFilters(newValues);
														}else{
															dispatch(setSavedAssignedFormsTypeActiveFilters(payload));
															setAssignedFormsTypeActiveFilters(newValues);
														}
													}}
												/>
												<DropdownFilter
													isValueArray={true}
													dropLabel="Category"
													options={templateOptions?.checkListOptions?.Template_Category__c?.valueList}
													activeFilterDropdown={activeFilterDropdown}
													setActiveFilterDropdown={setActiveFilterDropdown}
													activeFilters={activeTabRef.current === 0 ? allFormsCategoryActiveFilters : assignedFormsCategoryActiveFilters}
													onFilterChange={async (newValues) => {
														processTofilter_.current = true;
														let payload = {projectId : credential?.projectId , data : newValues};
														if(activeTabRef.current === 0){
															dispatch(setSavedAllFormsCategoryActiveFilters(payload));
															setAllFormsCategoryActiveFilters(newValues);
														}else{
															dispatch(setSavedAssignedFormsCategoryActiveFilters(payload));
															setAssignedFormsCategoryActiveFilters(newValues);
														}
													}}
												/>
												<DropdownFilter
													dropLabel="Status"
													isValueArray={true}
													options={formOptions?.Status__c?.valueList}
													activeFilterDropdown={activeFilterDropdown}
													setActiveFilterDropdown={setActiveFilterDropdown}
													activeFilters={activeTabRef.current === 0 ? allFormsStatusActiveFilters : assignedFormsStatusActiveFilters}
													onFilterChange={async (newValues) => {
														processTofilter_.current = true;
														let payload = {projectId : credential?.projectId , data : newValues};
														if(activeTabRef.current === 0){
															dispatch(setSavedAllFormsStatusActiveFilters(payload));
															setAllFormsStatusActiveFilters(newValues);
														}else{
															dispatch(setSavedAssignedFormsStatusActiveFilters(payload));
															setAssignedFormsStatusActiveFilters(newValues);
														}
													}}
												/>
												{activeTab != 1  &&
													<DropdownFilter
														dropLabel="Assigned To"
														options={userFilterOptions}
														activeFilterDropdown={activeFilterDropdown}
														setActiveFilterDropdown={setActiveFilterDropdown}
														activeFilters={allFormsApprovedByActiveFilters}
														onFilterChange={async (newValues) => {
															processTofilter_.current = true;
															let payload = {projectId : credential?.projectId , data : newValues};
															dispatch(setSavedAllFormsApprovedByActiveFilters(payload));
															setAllFormsApprovedByActiveFilters(newValues);
														}}
													/>
												}
												<DropdownFilter
													dropLabel="Created By"
													options={userFilterOptions}
													activeFilterDropdown={activeFilterDropdown}
													setActiveFilterDropdown={setActiveFilterDropdown}
													activeFilters={activeTabRef.current === 0 ? allFormsCreatedByActiveFilters : assignedFormsCreatedByActiveFilters}
													onFilterChange={async (newValues) => {
														processTofilter_.current = true;
														let payload = {projectId : credential?.projectId , data : newValues};
														if(activeTabRef.current === 0){
															dispatch(setSavedAllFormsCreatedByActiveFilters(payload));
															setAllFormsCreatedByActiveFilters(newValues);
														}else{
															dispatch(setSavedAssignedFormsCreatedByActiveFilters(payload));
															setAssignedFormsCreatedByActiveFilters(newValues);
														}
													}}
												/>
												{(allFormsTotalFilterApplied > 0 || assignedFormsTotalFilterApplied > 0) && (
													<>
														<div style={{ cursor: "pointer" }} className="tertiary" onClick={handleClearAllFilters} >
															<img className="reset" src="reset.svg" alt="reset" />
															<div className="filters">Clear All</div>
														</div>
													</>
												)}
											</div>
										</div>
									</Design>
								)}
								<Box style={{ height: filterOptionsVisible ? searchBoxFilter ? "calc(100vh - 260px)" : "calc(100vh - 230px)" : searchBoxFilter ? "calc(100vh - 260px)" : "calc(100vh - 180px)", }} >
									{/*<Box style={{ height: filterOptionsVisible ? "calc(100vh - 230px)" : "calc(100vh - 180px)", }} >*/}
									{(showSpinner) && <Spinner />}
									<FormsDataTable
										tableId={'formTable'}
										tableHeight={(searchBoxFilter) && "calc(100vh - 317px)"}
										errorMessage={searchBoxFilter ? 'None of your forms matched this search' : ''}
										pagination={false}
										setRowsPerPage={setRowsPerPage}
										columns_react_table={FormsDataColumns}
										rowss={viewListData}
										openMenu={openMenu}
										handleClickMenu={handleClickMenu}
										selectedIds={selectedRowIds}
										listCount={0}
										searchBoxFilter={searchBoxFilter}
									/>
								</Box>
								{openMenu && selectedMenuRow && (
									<>
										<Stack direction="row" spacing={2}>
											<Paper sx={{ height: 320, maxWidth: "100%" }}>
												<Menu id="basic-menu" open={openMenu}
													onClose={handleCloseMenu}
													autoFocus
													anchorReference="anchorPosition"
													anchorPosition={
														anchorEl
														? { top: anchorEl.mouseY, left: anchorEl.mouseX }
														: undefined
													}
													transformOrigin={{
														horizontal: 'left',
														vertical: 'top',
													}}
													MenuListProps={{ "aria-labelledby": "basic-button" }}
													onContextMenu={(e)=>{
														e.preventDefault();
														e.stopPropagation();
														handleCloseMenu();
													}}>
													<div className="dropdown-list">
														<>
															<div className="text-with-icon-1" onClick={() => {
																setFormTypeForBuild(selectedMenuRow.formType);
																openedProjectForm(selectedMenuRow.id)
															}}>
																<img className="download-source" src="/Pencil.svg" alt="download source" />
																<div className="label-3 poppins-normal-abbey-14px">
																	{" "}
																	Edit {selectedMenuRow.formType} Form
																</div>
															</div>
															{selectedMenuRow.status != "Completed" &&
																<div className="text-with-icon-1" onClick={() => { onClickOpenAction('Delete', 'menu') }}>
																	<img className="download-source" src="/delete.svg" alt="download source" />
																	<div className="label-3 poppins-normal-abbey-14px">
																		{" "}
																		Delete
																	</div>
																</div>
															}
															<div className="text-with-icon-1" onClick={() => {
																	//setExportChecklistFormPdf(true);
																	let toExport = [selectedMenuRow];
																	handleExportClick(toExport);
																	handleCloseMenu();
																}}>
																<img className="download-source" src="/download.svg" alt="download source" />
																<div className="label-3 poppins-normal-abbey-14px">
																	{" "}
																	Export
																</div>
															</div>
															{selectedMenuRow.status == "Completed" &&
																<div className="text-with-icon-1" onClick={() => {
																	setShareItems([selectedMenuRow]);
																	handleCloseMenu();
																}}>
																	<img className="share" src="share.svg" alt="share" />{" "}
																	<div className="label-1 poppins-normal-abbey-14px">
																		{" "}
																		Share
																	</div>
																</div>
															}
														</>
													</div>
												</Menu>
											</Paper>
										</Stack>
									</>
								)}
							</div>
						</div>
					)}
					{activeTab == 3 && (
						<FormTemplateComponent
							_templateOptions={templateOptions}
							userData={userData}
							credential={credential}
							addCheckListButton={addCheckListButton}
							importTemplatesButton={importTemplatesButton}
							addPdfButton={addPdfButton}
							refreshFormTableList={refreshTableList}
							onClose={(tabValue)=>{
								onActiveTabChange(tabValue);
							}}
							projectFormTemplates={projectFormTemplates}
							userFilterOptions={templateUserFilterOptions}
							fetchTemplateList={fetchTemplateList}
							showTemplateSpinner={showTemplateSpinner}
						/>
					)}
					{openCreateForm &&
						<CreateProjectForm
						    dialogData={createFormDialogData}
							templateOptions={templateOptions}
							credential={credential}
							currentStep={currentStep}
							setCurrentStep={setCurrentStep}
							onCancel={(refresh, redirectId, formType) => {
								if (redirectId && redirectId.length === 18) {
									openedProjectForm(redirectId);
									setFormTypeForBuild(formType);
								} else {
									setOpenCreateForm(false);
								}
								if (refresh === true) {
									refreshTableList();
								}
							}}
							projectFormTemplates={projectFormTemplates}
							fetchTemplateList={fetchTemplateList}
							showTemplateSpinner={showTemplateSpinner}
						/>
					}
					{formTypeForBuild == "Checklist" &&
						<BuildChecklistForm
							projectFormId={projectFormId}
							templateList={templateOptions}
							credential={credential}
							onCancel={(refresh, redirectId) => {
								setFormTypeForBuild(null);
								refreshTableList();
							}}
							updateTableStateForLink={updateTableStateForLink}
						/>
					}
					{formTypeForBuild == "PDF" &&
						<BuildPdfForm
							projectFormId={projectFormId}
							templateList={templateOptions}
							credential={credential}
							onCancel={(refresh, redirectId) => {
								setFormTypeForBuild(null);
								refreshTableList();
							}}
							updateTableStateForLink={updateTableStateForLink}
						/>
					}
					{toShareItems?.length > 0 && (
						<ShareableLinkComponent
							setToastMessage={setToastMessage}
							shareListItems={toShareItems}
							onCancel={(links) => {
								updateTableStateForLink(links);
								setShareItems([]);
							}}
						/>
					)}
					{warningDialodData &&
						<ConfirmDialog
							onCancel={() => { setWarningDialodData(null); }}
							onAccept={() => {
								if (warningDialodData.source == "menu") {
									if (warningDialodData.operation === 'Delete') {
										onDeleteForm(warningDialodData.operation, warningDialodData.operationItems[0]);
									}
								} else if (warningDialodData.source == "header") {
									if (warningDialodData.operation === 'Delete') {
										deleteSelectedItems();
									}
								}
							}}
							DialogData={warningDialodData}
						/>
					}
					{exportProcesses?.length > 0 &&
						<>
							<ItemInQueueComponent
								heading='Exporting'
								queueItems={exportProcesses}
								onClose={()=>{
									setExportChecklistFormPdf(false);
									setExportProcesses([]);
								}}
							/>
							{exportChecklistFormPdf && exportProcesses.map((exportProcess) => (
								<ChecklistFormPreviewExport
									key={exportProcess.id}
									uiId={exportProcess.id}
									formIds={exportProcess.formIds}
									zipName={exportProcess.name}
									processed={exportProcess.processed}
									credential={credential}
									onClose={(uiId , exported) => {
										let que_obj = {id : uiId};
										que_obj.processed = true;
										que_obj.completed = exported;
										que_obj.failed = !que_obj.completed;
										setItemToBgProcess(que_obj);
									}}
								/>
								)
							)}
						</>
					}
					{assignDialog &&
						<AssignDialog
							onCancel={() => {
								setAssignDialog(null);
							}}
							onAccept={(assignValue) => {
								setAssignDialog(null);
								setSpinner(true);
								const reqBody = selectedRows.map(item => ({
										Id: item.id,
										Ball_in_Court__c: assignValue
									}));
								const formData = new FormData();
								formData.append("recLists", JSON.stringify(reqBody));
								 axios.post(`${fetchUrl}/recSave/list/Form__c`, formData)
								.then(async (res) => {
									if (res.data.status == 200) {
										refreshTableList();
									}
								}).catch((err) => {
									setSpinner(false);
								});
							}}
							picklistValue={[...(credential?.userList || [])].sort((a, b) => a.Name.localeCompare(b.Name))}
							dialogData={assignDialog}
						/>
					}
					{toastMessage &&
						<ToastComponent message={toastMessage} handleClose={() => setToastMessage(null)} />
					}
				</div>
			</Box>
		</Box>
	);
};
export const prepareProjectFormTableRow = (rec , cred) =>{
	let inUrl = getInternalRecordUrl(cred , 'Forms' , rec?.Id);
	const row_ = {
		inappurl : inUrl,
		createdBy: rec?.CreatedById ? rec?.CreatedBy?.Name : "-",
		createdById: rec?.CreatedById ? rec?.CreatedById : "-",
		createdDate: rec?.CreatedDate ? rec?.CreatedDate : "-",
		id: rec?.Id ? rec?.Id : "-", 
		key: rec?.Id ? rec?.Id : "-",
		numberSequence: rec?.Numbering_Sequence__c ? rec?.Numbering_Sequence__c.toString() : "",
		title: rec?.Name ? rec?.Name : "-",
		name: rec?.Name ? rec?.Name : "-",
		status: rec?.Status__c ? rec?.Status__c : "-",
		formDate: rec?.Date__c ? rec?.Date__c : "-",
		form: rec?.Form_Template__c && rec?.Form_Template__r.Name ? rec?.Form_Template__r.Name : "-",
		category: rec?.Form_Template__c && rec?.Form_Template__r.Template_Category__c ? rec?.Form_Template__r.Template_Category__c : "-",
		formType: rec?.Form_Template__c && rec?.Form_Template__r.Form_Type__c ? rec?.Form_Template__r.Form_Type__c : "-",
		assignedTo: rec?.Ball_in_Court__c && rec?.Ball_in_Court__r.Name ? rec?.Ball_in_Court__r.Name : "-",
		assignedTo_Id: rec?.Ball_in_Court__c ? rec?.Ball_in_Court__c : "-",
		rObj: JSON.parse(JSON.stringify(rec)),
		tooltip: rec?.Name ? rec?.Name : "-",
		type: 'form',
		Shared_Links__r: rec.Shared_Links__r, 
	};
	return row_;
};

export function prepareFormTemplateObj(row) {
    const tablePayload = {
      createdBy: row?.CreatedById ? row?.CreatedBy?.Name : "-",
      createdById: row?.CreatedById,
      createdDate: row?.CreatedDate ? row?.CreatedDate : "-",
      updatedBy: row?.LastModifiedById ? row?.LastModifiedBy?.Name : "-",
      updatedById: row?.CreatedById,
      lastUpdatedOn: row?.LastModifiedDate ? moment(row?.LastModifiedDate).format("MMM D, YYYY") : "-",
      id: row?.Id ? row?.Id : "-", key: row?.Id ? row?.Id : "-",
      title: row?.Name ? row?.Name : "-",
      type: row?.Form_Type__c ? row?.Form_Type__c : "-",
      category: row?.Template_Category__c ? row?.Template_Category__c : "",
      description: row?.Description__c ? row?.Description__c : "-",
      stage: row?.Status__c ? row?.Status__c : "-",
      location: "-",
      instance: row?.Published_To_Project_Count__c > 0 ? row?.Published_To_Project_Count__c : 0,
      itemCount: row?.Number_of_Active_Items__c > 0 ? row?.Number_of_Active_Items__c : 0,
      formCount: row?.Forms__r?.length > 0 ? row?.Forms__r?.length : 0,
      projectTemplate: row?.projectFormTemplate,
      ...row
    };
    if(row?.projectFormTemplate){
      tablePayload.status = row?.projectFormTemplate?.Status__c;
      tablePayload.projectTemplateId = row?.projectFormTemplate?.Id;
    }
    return tablePayload;
  };
export default FormsComponent;
