import React, { useState, useEffect, useRef } from "react";
import _, { find,cloneDeep, findIndex } from "lodash";
import { MS_DOC_FORMAT, APRYSE_L_KEY,makeRandomId } from "api" ;
import axios from "axios";
import { fetchUrl, webSocketListener } from "Urls";
import VersionsSelect from "./versionsSelect";
import { DeleteMarkupsDialog } from "components/DrawerComponent/DisciplineSettingDialog";
import moment from "moment";
import Draggable from "react-draggable";
import IssueIconActive from "../../assets/images/warning_active.svg";
import IssuePanelDialog from "./IssuePanel";
import xml2js from "xml2js";
import { ExportFileDialog } from "components/FolderTable/ExportFile";
import {prepareAmazonRecordRow, downloadPdfDocument } from "components/SheetsComponent";
import Spinner from "components/spinner";
import ToastComponent from "components/ToastComponent";
import DropdownSelect from "components/DropdownSelect";
import ZoomButtons from "components/ZoomButtons";
import DocumentNavigation from "components/DocumentNavigation";
import { Box, Button, Checkbox, IconButton, InputLabel, MenuItem,
        Select, Dialog, FormControl, List, ListItem, ListItemText, Drawer, Tooltip} from "@mui/material";
import WebViewer from "@pdftron/webviewer";
import SearchIcon from "../../assets/images/search.svg";
import CompareIcon from "../../assets/images/compare.svg";
import DownloadIcon from "../../assets/images/download.svg";
import ShareIcon from "../../assets/images/share.svg";
import MarkupIcon from "../../assets/images/layers.svg";
import IssueIcon from "../../assets/images/warning.svg";
import MarkupActiveIcon from "../../assets/images/markupactive.svg";
import PublicIcon from "../../assets/images/public.svg";
import PrivateIcon from "../../assets/images/private.svg";
import FilterIcon from "../../assets/images/filter.svg";
import SelectAllIcon from "../../assets/images/selectAll.svg";
import VisibilityIcon from "../../assets/images/visibility.svg";
import VisibilityOffIcon from "../../assets/images/visibility_off.svg";
import CloseIcon from "../../assets/images/close.svg";
import LeftArrowIcon from "../../assets/images/left.svg";
import ResetIcon from "../../assets/images/reset.svg";
import styled from "styled-components";
import ShareableLinkComponent from "components/ShareableLinkComponent";


export const issueToolName = 'AnnotationCreateIssue';
export const issueStatusToColorMap = {
  'Draft': {
    colorBox: { r: 52, g: 110, b: 245 },
    pillColor: "#346EF5",
    textColor: "black",
  },
  'Pending': {
    colorBox: { r: 249, g: 40, b: 40 },
    pillColor: "#F92828",
    textColor: "white",
  },
  'Amend Resolution': {
    colorBox: { r: 255, g: 170, b: 43 },
    pillColor: "#FFAA2B",
    textColor: "white",
  },
  'Answered': {
    colorBox: { r: 200, g: 43, b: 255 },
    pillColor: "#C82BFF",
    textColor: "white",
  },
  'Closed': {
    colorBox: { r: 10, g: 161, b: 16 },
    pillColor: "#0AA110",
    textColor: "black",
  },
  'Void': {
    colorBox: { r: 192, g: 192, b: 192 },
    pillColor: "#c0c0c0",
    textColor: "black",
  },
};

export const getXMLJSFromMakrupXML = async (markupXml) => {
  let retObj ;
  if(markupXml){
    await xml2js.parseStringPromise(markupXml).then(function (result) {
      //console.log("getXMLJSFromMakrupXML", JSON.stringify(result));
      retObj = result;
    }).catch(async function (err) {
      // Failed
      console.log("xml to json err :: ", err);
    });
  }
  return retObj;
};
export const getWrapperFromAnnotaitionXMLJSON = (ffff, cred) => {
  let retObj = { public_markup_counts: 0, private_markup_counts: 0, users_markup_counts: 0, markups: [], issues: [] , markupTypeList : [] , markupOwnerList : []};
  try{
    if (ffff?.xfdf?.annots?.length > 0) {
      ffff.xfdf.annots.map((markUpItem) =>
        markUpItem != null && Object.entries(markUpItem).map(([key, value]) =>
          value && value.map((anno_ac) =>
            anno_ac["trn-custom-data"] && anno_ac["trn-custom-data"].map((cus_data) => {
              var annotation;
              const cusData = JSON.parse(cus_data.$.bytes);
              if(cusData?.userId){
                if(cusData['trn-measurement-caption-options']){
                  cusData.messurementCaptionOptions = JSON.parse(cusData['trn-measurement-caption-options']);
                }
                if(cusData['trn-behavior-type']){
                  cusData.behaviorType = cusData['trn-behavior-type'];
                }
                let label = anno_ac.$.subject;
                let markupType = anno_ac.$.subject;
                //console.log('anno_ac.$.IT ::',anno_ac.$.IT);
                if(anno_ac.$.IT){
                  if(label != 'Callout' && label != "Free Hand Highlight"){
                    let as = ''
                    markupType = anno_ac.$.IT;
                    if(cusData.behaviorType === 'rectangle'){
                      markupType ='RectangularDimension';
                    }
                    //console.log('anno_ac.contents ::',anno_ac.contents);
                    if(anno_ac.contents && anno_ac.contents?.length > 0 ){
                      if(markupType == 'LineDimension'){
                        as += 'Distance - '
                      }else if(markupType == 'PolyLineDimension'){
                        as += 'Perimeter - '
                      }else if(markupType == 'PolygonDimension'){
                        as += 'Area -'
                      }else if(markupType == 'EllipseDimension'){
                        as += 'Ellipse Area - '
                      }else if(markupType == 'RectangularDimension'){
                        as += 'Rectangular Area - '
                      }
                      as += anno_ac.contents[0];
                      label = as;
                    }else if(markupType == 'LineArrow'){
                      as += 'Arrow';
                      label = as;
                      markupType = 'Arrow'
                    }
                  }
                 
                }
                annotation = {
                  Id: anno_ac.$.name, Subject: anno_ac.$.subject,
                  label:label,
                  markupType: markupType,
                  markupDetail: anno_ac.$,
                  customData: cusData
                };
                if (annotation.markupType != 'Issue') {
                  if (annotation.customData?.access == 'public') {
                    retObj.public_markup_counts += 1;
                    retObj.users_markup_counts += 1;
                    annotation.userCanView = true;
                  } else {
                    retObj.private_markup_counts += 1;
                    if (annotation.customData?.userId === cred?.userId) {
                      retObj.users_markup_counts += 1;
                      annotation.userCanView = true;
                    }
                  }
                  retObj.markups.push(annotation);
                  if (!retObj.markupTypeList.includes(annotation.markupType)) {
                    retObj.markupTypeList.push(annotation.markupType);
                  }
                  retObj.markupTypeList.sort();
                  if(annotation.customData?.userId){
                    const found_us = retObj.markupOwnerList.find(usss => usss.Id === annotation.customData?.userId);
                    if (!found_us) {
                      retObj.markupOwnerList.push({ Id: annotation.customData?.userId, Name: annotation.customData?.userFullName });
                    }
                    retObj.markupOwnerList.sort((a, b) => {
                      return a.Name.localeCompare(b.Name);
                    });
                  }
                } else {
                  retObj.issues.push(annotation);
                }
              }
              return annotation;
            })
          )
        )
      );
    }
  }catch (e){
    console.log('e :: ',e);
  }
  //console.log('retObj :: '+JSON.stringify(retObj));
  return retObj;
};
export function updateColorForIssueXML(xmlString, nameId, newColorHex) {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(xmlString, 'text/xml');
  const issues = xmlDoc.querySelectorAll(`issue[name="${nameId}"]`);
  if(issues?.length > 0){
    issues.forEach((issue) => {
      issue.setAttribute('interior-color', newColorHex);
    });
    const updatedXmlString = new XMLSerializer().serializeToString(xmlDoc);
    return updatedXmlString.replace(/\n\s*/g, '');//updatedXmlString;
  }
  return;
}
export function removeNodeFromXml(xmlString, nodeName) { // appearance
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(xmlString, 'text/xml');
  const toRemoveNodes = xmlDoc.getElementsByTagName(nodeName);
  let removed = false;
  while(toRemoveNodes.length > 0) {
    removed = true;
    toRemoveNodes[0].parentNode.removeChild(toRemoveNodes[0]);
  }
  if(removed){
    const updatedXmlString = new XMLSerializer().serializeToString(xmlDoc);
    return updatedXmlString.replace(/\n\s*/g, '');
  }
  return xmlString;
}
export function removeIssueMarkupFromXML(xmlString, nameId) {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(xmlString, 'text/xml');
  const issues = xmlDoc.querySelectorAll(`issue[name="${nameId}"]`);
  if(issues?.length > 0){
    issues.forEach((issue) => {
      issue.parentNode.removeChild(issue);
    });
    const updatedXmlString = new XMLSerializer().serializeToString(xmlDoc);
    return updatedXmlString.replace(/\n\s*/g, '');//updatedXmlString;
  }
  return;
}
export const exportAnnotationsCustomGlobal = async ( _instance )=>{
  let customAnnotations = _instance?.Core.annotationManager.getAnnotationsList().filter((ann) => {
    let returnv = ann.isInternal() !== true;
    if(!ann.getCustomData('userId')){
      returnv = false;
    }
    if(ann.getCustomData('source') !== 'save_annotation'){
      returnv = false;
    }
    return returnv;
  } );
  //console.log("customAnnotations ::", customAnnotations.length);
  /*await _instance?.Core.annotationManager.exportAnnotations().then(async function (xfdfString) {
    console.log("actual String ::", xfdfString);
    //return xfdfString;
  });*/
  return await _instance?.Core.annotationManager.exportAnnotations({annotList : customAnnotations,
    fields : true , links : true , widgets : true }).then(async function (xfdfString) {
    //console.log("customString ::", xfdfString);
    return xfdfString;
  });
};
export const annoIdField = "Annotation_Id__c";
export const WithoutTemplateSection = styled.div`
  .Popup.DocumentCropPopup {
    display: none !important;
  }
  
  .drawer {
    align-items: flex-start;
    border: 1px solid;
    border-color: #eaeaea;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 32px;
    left: 40px;
    position: absolute;
    top: 49px;
    width: 60px;
  }
  .drawer-rect {
    align-items: flex-start;
    border: 1px solid;
    border-color: #eaeaea;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 32px;
    left: 10px;
    position: absolute;
    top: 179px;
    width: 90px;
  }
    .drawer-measure {
    align-items: flex-start;
    border: 1px solid;
    border-color: #eaeaea;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 32px;
    left: -50px;
    position: absolute;
    top: 249px;
    width: 150px;
  }
  .measure-1 {
    background-color: #ffffff;
    border-radius: 2px;
    box-shadow: 0px 4px 8px #00000014;
    height: 32px;
    position: relative;
    width: 150px;
  }
  .rectangle {
    background-color: #ffffff;
    border-radius: 2px;
    box-shadow: 0px 4px 8px #00000014;
    height: 32px;
    position: relative;
    width: 60px;
  }
  .rectangle-1 {
    background-color: #ffffff;
    border-radius: 2px;
    box-shadow: 0px 4px 8px #00000014;
    height: 32px;
    position: relative;
    width: 90px;
  }

  .highlighter-wrapper-1 {
    &:hover {
      background: #f8f8f8;
    }
    cursor: pointer;
    align-items: center;
    border-radius: 2px;
    display: flex;
    height: 24px;
    justify-content: center;
    left: 62px;
    overflow: hidden;
    position: absolute;
    top: 4px;
    width: 24px;
  }
  .cloud {
    background-color: #ffffff;
    height: 16px;
    position: relative;
    width: 16px;
  }

  .subtract {
    height: 14px;
    left: 1px;
    position: absolute;
    top: 1px;
    width: 14px;
  }
  .sheet-view-navigation {
    align-items: center;
    background-color: #ffffff;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-color: #eaeaea;
    display: flex;
    justify-content: space-between;
    padding: 8.5px 20px;
    position: relative;
    width: 100%;
  }
  .sheets-selected-and-info {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 12px;
    position: relative;
  }
  .sheets-selected-and-info-item {
    color: #000000;
    font-weight: 400;
    line-height: normal;
    position: relative;
    width: fit-content;
  }
  .dropdown-list {
    align-items: flex-start;
    background-color: var(--neutralswhite);
    box-shadow: var(--dropdown);
    display: flex;
    flex-direction: column;
    left: 195px;
    position: absolute;
    top: 47px;
    width: 224px;
  }
  .line-24 {
    height: 24px;
    object-fit: cover;
    position: relative;
    width: 1px;
  }
  .current-sheet-number {
    color: #929292;
    font-family: 'Poppins';
    font-size: 14px;
    line-height: normal;
    position: relative;
    width: fit-content;
  }
  .frame-52 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    //gap: 16px;
    justify-content: flex-end;
    position: relative;
  }
  .frame-44 {
    padding-right: 20px;
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 16px;
    position: relative;
  }
  
  .tools {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 16px;
    justify-content: center;
    position: relative;
  }
  .frame-42 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 12px;
    justify-content: flex-end;
    position: relative;
  }
  .frame-43 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    position: relative;
  }
  .buttons {
    align-items: center;
    background-color: #ffffff;
    border: 1px solid;
    border-color: #d8d8d8;
    border-radius: 2px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    height: 37px;
    justify-content: center;
    overflow: hidden;
    padding: 8px 12px;
    position: relative;
  }
  .navigate {
    height: 16px;
    position: relative;
    width: 16px;
  }
  .buttons-1 {
    align-items: center;
    border-radius: 2px;
    display: flex;
    height: 24px;
    justify-content: center;
    overflow: hidden;
    position: relative;
    width: 24px;
  }
  .pan {
    height: 16px;
    position: relative;
    width: 16px;
  }
  .buttons-1.buttons-2 {
    background-color: #346ef5;
  }
  .buttons-3 {
    &:hover {
      background: #f8f8f8;
    }
    align-items: center;
    background-color: #ffffff;
    border: 1px solid;
    border-color: #505050;
    border-radius: 2px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    justify-content: center;
    overflow: hidden;
    padding: 9px 16px;
    position: relative;
  }
  .label {
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: fit-content;
  }
  .version_select-label{
    background-color: #e8efff;
    padding:4px
    border:1px solid;
    border-radius: 16px;
    color: rgba(52, 110, 245, 1);
  }
  .sheet-view-navigation {
    .sheets-selected-and-info.body-regular {
      .sheets-selected-and-info-item-1 {
        font-family: "Poppins-SemiBold", Helvetica;
        font-size: 14px;
        font-weight: 600;
      }
      .sheets-selected-and-info-item {
        .version_select {
          background-color: #e8efff;
          height: 26px;
          border-radius: 16px;
          color: rgba(52, 110, 245, 1);
          fieldset {
            border-color: rgba(52, 110, 245, 1);
          }
          .MuiInputBase-input {
            font-size: 12px;
            font-weight: 400;
          }
          svg {
            color: rgba(52, 110, 245, 1);
            height: 23px;
            width: 16px;
          }
        }
      }
    }
    .define-sheet-number-area.body-regular {
      cursor: pointer;
      padding: 5px
    }
  }
 
`;
//const furl2 = `https://horsepower-dev.s3.amazonaws.com//a0DDb00000GqVFxMAN/a0DDb00000GqVFyMAN/2%20Page%20Biinder.pdf?AWSAccessKeyId=AKIA6FO5IYHIJDDKUAVC&Expires=1691850263&Signature=a3yHZu%2BuQpYhid0eNc7%2BG5qdL9I%3D`;
//const furl2 = `https://horsepower-dev.s3.amazonaws.com//a0DDb00000GqVFxMAN/a0DDb00000GqVFyMAN/7%20Pages%20document.pdf`;
function ShowPdf({ pdfopenfiledata, ...props }) {
  const { credential,  updateTableState, handleClose, viewFolderFiles, callFrom , navigation, currentViewFiles , thumbnailMap , viewingIssue, updateTableStateForLink} = props;
  const cancelAction = useRef();
  const [isPdf, setIsPdfState] = useState(false);
  const isPdfRef = useRef(isPdf);
  const setIsPdf =(value)=>{
    isPdfRef.current = value;
    setIsPdfState(value);
  };
  const pdfAttachmentRef = useRef(pdfopenfiledata);
  const [defaultSelectedIssue, setDefaultSelectedIssue] = useState(viewingIssue);
  const [openfileIns, setOpenfileInsState] = useState(pdfAttachmentRef.current);
  const [setName, setSetName] = useState(openfileIns?.set);
  const [version, setVersion] = useState(openfileIns?.currentVersion > 0 ? openfileIns?.currentVersion : 0 );
  const [defaultFile, setDefaultFile] = useState(pdfopenfiledata);
  const [showZoomActions, setShowZoomAction] = useState(false);
  const [currentZoomLevel, setCurrentZoomLevel] = useState(1);
  const [instance, setInstanceState] = useState();
  const instanceRef = useRef(instance);
  const setInstance =(value)=>{
    instanceRef.current = value;
    setInstanceState(value);
  };
  const [pageSize, setPageSize] = useState();
  const [markupPanelOpen, setMarkupPanelOpen] = useState(false);
  const [pagesPanel, setPagesPanelState] = useState(false);
  const pagePanelRef = useRef(false);
  const [isMarkSelectionActive, setMarkSelectionActive] = useState(false);
  const [isVisibilityOn, setIsVisibilityOn] = useState(true);
  const [checkedItems, setCheckedItems] = useState([]);
  const [deleteMarkupsDialog, setDeleteMarkupsDialog] = useState(false);
  const [markupFilterPanel, setMarkupFilterPanel] = useState(false);
  const [isMarkupFiltred, setMarkupFiltered] = useState(false);
  const [inkAnnotation, setInkAnnotation] = useState(false);
  const [measurementAnnotations, setMeasurementAnnotations] = useState(false);
  const [panAnnotation, setPanAnnotation] = useState(false);
  const [rectAnnotation, setRectAnnotation] = useState(false);
  const [compareOpen, setCompareOpen] = useState(false);
  const [compareDialog, setCompareDialog] = useState(true);
  const [currentToolMode, setCurrentToolMode] = useState();
  const toolModeRef = useRef(null);
  const [toastMessage, setToastMessage] = useState();
  const [issuePanelOpen, setIssuePanelOpen] = useState(false);
  const [showIssueListPanel, setIssueListPanel] = useState(false);
  const [inkType, setInkType] = useState();
  const [rectType, setRectType] = useState();
  const [selectedMarkups, setSelectedMarkups] = useState([]);
  const [selectedMarkup, setSelectedMarkup] = useState();
  const [selectedIssueAnno, setSelectedIssueStamp] = useState();
  const [annotationManage, setAnnotationManage] = useState();
  const [exportDialog, setExportDialog] = useState(false);
  const [exportItems, setExportItems] = useState([]);
  const [toExportFiles, setToExportFiles] = useState();
  const [loading, setLoading] = useState(false);
  const [versionList, setVersionList] = useState([]);
  const [searchInPdf, setSearchInPdf] = useState(false);
  const [shareDocument, setShareDocument] = useState(false);


  const [markupTypeList, setMarkupTypeList] = useState([]);
  const [markupOwnerList, setMarkupOwnerList] = useState([]);

  const [mfUserList, setMfUserList] = useState();
  const [mfMarkupTypeList, setMfMarkupTypeList] = useState();
  const [showPublicMarkups, setShowPublicMarkups] = useState(false);
  const [showPrivateMarkups, setShowPrivateMarkups] = useState(false);

  const [annotationListJS, setAnnotationJSList] = useState();
  const [annotationDetailJS, setAnnotationDetailJS] = useState();
  let annotationDetailJSRef = useRef();
  const [annotationFilterListJS, setAnnotationJSFilterList] = useState(null);

  let xmlTojsonXML = useRef(null);
  let runAutoSave = useRef(false);
  let timeOutMilsec = useRef(1000);
  const [showViewer, setViewer] = useState(true);
  const viewer = useRef();

  const [waitingToReconnect, setWaitingToReconnect] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const clientIdRef = useRef(null);
  const clientRef = useRef(null);
  const serverImporting = useRef(false);
  useEffect(() => {
    setDefaultSelectedIssue(viewingIssue);
  },[viewingIssue])
  useEffect(() => {
    if(!clientIdRef.current){
      clientIdRef.current = makeRandomId(15) + makeRandomId(5);
    }
    if (waitingToReconnect) {
      return;
    }
    // Only set up the websocket once
    if (!clientRef.current) {
      let clientIdLocal =  clientIdRef.current;
      const client = clientRef.current ? clientRef.current : new WebSocket(webSocketListener);
      client.clientId = clientIdLocal;
      clientRef.current = client;
      window.client = client;
      client.onerror = (e) => {
         clientRef.current = null;
         console.error('web socket error', e)
      };
      client.onopen = () => {
        client.send(JSON.stringify({ type: 'setClientId', clientId: clientIdLocal , for : 'doc_update'}));
        setIsOpen(true);
      };
      client.onclose = () => {
        if (clientRef.current) {
          clientRef.current = null;
          // Connection failed
          console.log('ws closed by server');
        } else {
          // Cleanup initiated from app side, can return here, to not attempt a reconnect
          console.log('ws closed by app component unmount');
          return;
        }
        if (waitingToReconnect) {
          return;
        };
        // Parse event code and log
        setIsOpen(false);
        console.log('ws closed');
        setWaitingToReconnect(true);
        setTimeout(() => setWaitingToReconnect(false), 1000);
      };

      client.onmessage = async (event) => {
        //console.log("websocket call", event.data);
        if (event.data) {
          const msg = JSON.parse(event.data);
          if(msg?.documentId === pdfAttachmentRef.current.id && instanceRef.current){
            serverImporting.current = true;
            //console.log('inside anno change',msg.xfdfString);
            const {annotationManager} = instanceRef.current.Core;
            runAutoSave.current = false;
            const anlist = await annotationManager.getAnnotationsList();
            if(anlist?.length > 0){
              await annotationManager.deleteAnnotations(anlist , {imported : true});
              runAutoSave.current = false;
            }
            await handleServerAnnotationChange(pdfAttachmentRef.current.id , msg.xfdfString , instanceRef.current);
            const anliste = await annotationManager.getAnnotationsList();
            if(anliste?.length > 0){
              //console.log('annotations :: ',anliste);
              await annotationManager.drawAnnotationsFromList(anliste);
            }
            serverImporting.current = false;
            runAutoSave.current = true;
            getUpdatedMarkupDetail(pdfAttachmentRef.current , false );
          }
        }
      };
      clientRef.current = client;
      return () => {
        console.log('Cleanup');
        // Dereference, so it will set up next time
         if(clientRef.current){
          console.log('Cleanup',clientRef.current.clientId);
          if(client.readyState === WebSocket.OPEN){
            client.send(JSON.stringify({ type: 'terminate', clientId: client.clientId}));
            client.close();
          }
          clientRef.current = null;
        }
      }
    }
  }, [waitingToReconnect]);

  const handleServerAnnotationChange = async (docId , xml , _instance = instanceRef.current)=>{
    //console.log('pdfAttachmentRef.current.id',docId , pdfAttachmentRef.current.id);
    if(pdfAttachmentRef.current.id === docId && _instance){
      serverImporting.current = true;
      const {annotationManager} = _instance.Core;
      if (xml) {
        runAutoSave.current = false;
        await annotationManager.importAnnotations(xml);
        const markups = annotationManager.getAnnotationsList(); //.filter(annotation => annotation.Subject !== 'Issue')
        //console.log('markups ',markups.length);
        const toHide = [];
        const visibleTouser = markups.filter(async ann => {
          //console.log('ann.Subject :: ',ann.Subject , ann.isInternal());
          let canview = false;
          let customOwner = ann?.getCustomData('userId');
          if(ann.Author === 'Guest' && customOwner){
            ann.Author = customOwner;
            runAutoSave.current = false;
            await annotationManager.updateAnnotation(ann);
          }
          //console.log('ann.Subject :: ',ann.Subject);
          if (ann.Subject === 'Issue' || ann?.getCustomData('access') === 'public' || customOwner === credential?.userId) {
            if(ann.Subject === 'Issue'){
              var sfIssue = getSFIssueObjByAnnonateId(ann.Id);
              //console.log('import sfi :: ',sfIssue);
              if(sfIssue?.Status__c !== 'Draft' || sfIssue?.CreatedById === credential?.userId){
                canview = true;
              }
            }else{
              canview = true;
            }
          } 
          if(canview === false) {
            toHide.push(ann);
          }
          return canview;
        });
        //console.log('toHide ',toHide.length);
        if (toHide.length > 0) {
          setMarkupVisiblityByAnnotations(toHide, true, _instance);
        }
      }
    }
    serverImporting.current = false;
    runAutoSave.current = true;
    if(defaultSelectedIssue){
      setTimeout(()=>{
        let issue = getIssueObjByIssueId(defaultSelectedIssue);
        if(issue && issue[annoIdField]){
          selectAnnotationById(issue[annoIdField], instance);
        }
        setDefaultSelectedIssue(null);
      },500);
    }
  };
  useEffect(() => {
    handleInitPDFDocument();
    const keysToRemove = [];
    for (let i = 0; i < localStorage.length; i++) {
      const storeKey = localStorage.key(i);
      if (storeKey && storeKey.includes('default-toolData-')) {
        //console.log("Deleting storage key:", storeKey);
        keysToRemove.push(storeKey);
      }
    }
    keysToRemove.forEach((key) => localStorage.removeItem(key));
  }, []);
  React.useEffect(() => {
    setAnnotationDetailJS_(getAnnotationDetailsFormXMLJS(annotationListJS, credential));
  }, [annotationListJS]);
  React.useEffect(() => {
    //console.log('annotationDetailJS :: ', JSON.stringify(annotationDetailJS));
    filterMarkupPanelList();
  }, [annotationDetailJS]);
  const setOpenfileIns = (value) => {
    pdfAttachmentRef.current = value;
    setOpenfileInsState(value);
  };
  const setCurrentToolMode_ = (value) => {
    toolModeRef.current = value;
    setCurrentToolMode(value);
  };
  const setPagesPanel= (value) => {
    pagePanelRef.current = value;
    setPagesPanelState(value);
  };
  const setAnnotationDetailJS_ = (value) => {
    annotationDetailJSRef.current = value;
    setAnnotationDetailJS(value);
  };
  const getAnnotationDetailsFormXMLJS = (ffff, cred) => {
    let retObj = getWrapperFromAnnotaitionXMLJSON(ffff, cred);
    setMarkupTypeList(retObj.markupTypeList);
    setMarkupOwnerList(retObj.markupOwnerList);
    //console.log('retObj :: '+JSON.stringify(retObj));
    return retObj;
  };
  const filterMarkupPanelList = (values) => {
    const tempList = cloneDeep(annotationDetailJS);
    const filterListMarkup = [];
    var userCounts = 0;
    if (tempList?.markups?.length > 0) {
      userCounts = tempList.users_markup_counts;
      for (let markItem of tempList.markups) {
        if (markItem.userCanView) {
          let push = true;
          if (markItem.customData.access == 'private' && showPublicMarkups && !showPrivateMarkups) {
            push = false;
          }
          if (markItem.customData.access == 'public' && !showPublicMarkups && showPrivateMarkups) {
            push = false;
          }
          //console.log('mfUserList',mfUserList);
          if (mfUserList && mfUserList.length > 0 && push == true) {
            push = false;
            if (find(mfUserList ,{Id:markItem.customData.userId})) {
              push = true;
            }
          }
          if (mfMarkupTypeList && mfMarkupTypeList.length > 0 && push == true) {
            push = false;
            if (mfMarkupTypeList.includes(markItem.markupType)) {
              push = true;
            }
          }
          if (push) {
            filterListMarkup.push(markItem);
          }
        }
      }
    }

    setMarkupFiltered((userCounts != 0 && userCounts != filterListMarkup.length));
    //console.log('filtered list :: ' + filterListMarkup.length);
    setAnnotationJSFilterList(filterListMarkup);
    if (instance && markupFilterPanel) {
      //console.log('instance found');
      const toHide = [];
      const toViewAnnot = instance.Core.annotationManager.getAnnotationsList().filter(annotation => {
        if (annotation.Subject !== 'Issue') {
          if (filterListMarkup.length > 0) {
            let indF = filterListMarkup.find(ann => ann.Id == annotation.Id);
            //console.log('indF :: ',indF);
            if (indF) {
              return true;
            } else {
              toHide.push(annotation);
            }
          } else {
            toHide.push(annotation);
          }
        }
        return false;
      });
      //console.log('toViewAnnot :: ',toViewAnnot?.length);
      if (toViewAnnot.length > 0) {
        setMarkupVisiblityByAnnotations(toViewAnnot, false, instance);
      }
      //console.log('toHide :: ',toHide?.length);
      if (toHide.length > 0) {
        setMarkupVisiblityByAnnotations(toHide, true, instance);
      }
    }
  };
  const handleInkAnnotation = (type) => {
    //console.log("cdddee");
    if (type) {
      setInkType(type);
    }
    setRectAnnotation(false);
    setInkAnnotation(!inkAnnotation);
    setPanAnnotation(false);
    setMeasurementAnnotations(false);
  };
  const handleMeasurementAnnotation = (type) => {
    if (type) {
      setRectType(type);
    }
    //console.log("cdddee");
    setRectAnnotation(false);
    setInkAnnotation(false);
    setPanAnnotation(false);
    setMeasurementAnnotations(!measurementAnnotations);
  };
  const handlePanAnnotation = (Type) => {
    setPanAnnotation(!panAnnotation);
    setRectAnnotation(false);
    setInkAnnotation(false);
    setMeasurementAnnotations(false);
  };
  const handleRectAnnotation = (Type) => {
    if (Type) {
      setRectType(Type);
    }
    setPanAnnotation(false);
    setRectAnnotation(!rectAnnotation);
    setInkAnnotation(false);
    setMeasurementAnnotations(false);
  };
  const handleVisibiltyMarkup = () => {
    const visib = !isVisibilityOn;
    setIsVisibilityOn(visib);
    if (instance) {
      setMarkupVisiblity(!visib, instance);
    }
  };
  const handleFilterMarkupPanel = () => {
    setMarkupFilterPanel(!markupFilterPanel);
  };

  const handleResetMarkupFilters = () => {
    setMfUserList([]);
    setMfMarkupTypeList([]);
    setShowPublicMarkups(false);
    setShowPrivateMarkups(false);
  };
  const handleMarkupAccessFilterChange = (event, checkFor) => {
    if (checkFor == 'public') {
      setShowPublicMarkups(event.target.checked);
    } else if (checkFor == 'private') {
      setShowPrivateMarkups(event.target.checked);
    }
  };
  async function clickOnClose() {
    handleClose();
  }
  const toggleMarkupSelectionPanel = () => {
    setMarkSelectionActive(!isMarkSelectionActive);
    if (isMarkSelectionActive == true) {
      setCheckedItems([]);
    }
    //console.log("checkItems ::", checkedItems);
  };

  const handleSelectMarkup = (annId, isChecked) => {
    if (isChecked === true) {
      selectAnnotationById(annId, instance);
    } else {
      deselectAnnotationById(annId, instance);
    }
  };
  const getSFIssueObjByAnnonateId =(annId)=>{
    //console.log('annId' , annId);
    //console.log('pdfAttachmentRef?.issueList' , JSON.stringify(pdfAttachmentRef.current?.issueList));
    if (pdfAttachmentRef.current && pdfAttachmentRef.current?.issueList?.length > 0 && annId != null) {
      let tempList = JSON.parse(JSON.stringify(pdfAttachmentRef.current?.issueList));
      const sfIssueIdx = findIndex(tempList, { [annoIdField]: annId });
      if (sfIssueIdx > -1) {
        return tempList[sfIssueIdx];
      }
    }
  };
  const getIssueObjByIssueId = (issueId)=>{
    //console.log('annId' , annId);
    //console.log('pdfAttachmentRef?.issueList' , JSON.stringify(pdfAttachmentRef.current?.issueList));
    if (pdfAttachmentRef.current && pdfAttachmentRef.current?.issueList?.length > 0) {
      let tempList = JSON.parse(JSON.stringify(pdfAttachmentRef.current?.issueList));
      const sfIssueIdx = findIndex(tempList, { Id: issueId });
      if (sfIssueIdx > -1) {
        return tempList[sfIssueIdx];
      }
    }
  };
  async function handleIssueListChange(issueLists, processSave, _instance = instance) {
    let _row = JSON.parse(JSON.stringify(pdfAttachmentRef.current));
    _row.issueList = issueLists;
    _row.issue = _row.issueList.length;
    setOpenfileIns(_row);
    //console.log('handleIssueListChange pdfAttachmentRef :: ',JSON.stringify(pdfAttachmentRef.current));
    if (processSave === true) {
      setTimeout(async () => {
        await saveAnnotationsServer(_instance);
      }, 100);
    }
  }
  async function saveAnnotationsServer(_instance = instance, action, annotationId) {
    //console.log("instantJSON sendData"+ JSON.stringify(annotationJsonI));

      const annotIdTokeepHide = [];
      const { Annotations , annotationManager, documentViewer, Tools } = _instance.Core;
      const defaultMarkupTool = documentViewer.getTool(Tools.ToolNames.PAN);
      if(action && action === 'add' && annotationId && (toolModeRef.current === 'AnnotationCreateArrow' || toolModeRef.current === 'AnnotationCreateLine' || toolModeRef.current === 'AnnotationCreateFreeText' || toolModeRef.current === 'AnnotationCreateCallout' || toolModeRef.current === 'AnnotationCreateEllipse' || toolModeRef.current === 'AnnotationCreateRectangle' || toolModeRef.current === 'AnnotationCreateRectCloud' || toolModeRef.current === 'AnnotationCreateDistanceMeasurement' || toolModeRef.current === 'AnnotationCreatePerimeterMeasurement' || toolModeRef.current === 'AnnotationCreateRectangularAreaMeasurement' || toolModeRef.current === 'AnnotationCreateEllipseMeasurement' || toolModeRef.current === 'AnnotationCreateAreaMeasurement')){
        documentViewer.setToolMode(defaultMarkupTool);
        setCurrentToolMode_(defaultMarkupTool.name);
        selectAnnotationById(annotationId, _instance);
      }
    if (true) {
      const annots = await annotationManager.getAnnotationsList();
      const to_Delete = [];
      if (annots && annots.length > 0) {
        runAutoSave.current = false;
        for (const ann of annots) {
          if(ann.NoView === true){
            annotIdTokeepHide.push(ann.Id);
          }
          ann.NoView = false;
          if (ann.Subject === 'Issue') {
            let sfIs = getSFIssueObjByAnnonateId(ann.Id);
            if (sfIs && sfIs.Id) {
              let cBox = issueStatusToColorMap[sfIs?.Status__c] ? issueStatusToColorMap[sfIs?.Status__c].colorBox : issueStatusToColorMap['Draft'].colorBox;
              if (cBox) {
                ann.FillColor = new Annotations.Color(cBox.r, cBox.g, cBox.b, 0.8);
                await annotationManager.updateAnnotation(ann);
              }
            } else {
              to_Delete.push(ann);
            }
          } else {
            await annotationManager.updateAnnotation(ann);
          }
        }
        if (to_Delete.length > 0) {
          await annotationManager.deleteAnnotations(to_Delete);
        }
        runAutoSave.current = true;
      }
    }
    if (true) {
      let xfdfString = await exportAnnotationsCustom(_instance);
      let savedXml = xfdfString;
      xml2js.parseStringPromise(savedXml).then(function (result) {
        setAnnotationJSList(result);
      });
      const annotsToHide = await _instance.Core.annotationManager.getAnnotationsList().filter(annX =>{ let _hide = annotIdTokeepHide.includes(annX.Id);
                                                                                                      let acc = annX.getCustomData('access') ;
                                                                                                      let customUser = annX.getCustomData('userId') ;
                                                                                                      if((acc === 'private' && annX.Author !== credential?.userId && annX.NoView === false)){
                                                                                                        _hide = true;
                                                                                                      }
                                                                                                      return _hide;
                                                                                                    });
      //console.log('annotsToHide ',annotsToHide?.length);
      if(annotsToHide?.length > 0){
        runAutoSave.current = false;
        setMarkupVisiblityByAnnotations(annotsToHide, true, _instance , true);
        runAutoSave.current = true;
      }
      //console.log("savedXml >> ", savedXml);
      xml2js.parseStringPromise(savedXml).then(function (result) {
        //console.log('JS List :: ',JSON.stringify(result) );
        setAnnotationJSList(result);
      });
      //console.log('xfdfString :: ',savedXml);
      savedXml = removeNodeFromXml(savedXml , 'appearance');
      //console.log('xfdfString :: ',savedXml);
      let obj = { Id: pdfAttachmentRef.current.id, Markup_Json__c: savedXml };
      const reqBody = new FormData();
      reqBody.append("recObj", JSON.stringify(obj));
      await axios.post(`${fetchUrl}/recSave/Amazon_S3_Attachment__c?token=${credential?.token}&instanceUrl=${credential?.instanceUrl}`, reqBody)
      .then(async (res) => {
        if (res?.data?.status === 200) {
          let _row = JSON.parse(JSON.stringify(pdfAttachmentRef.current));
          _row.markupJSON = xfdfString;
          _row.Markup_Json__c = _row.markupJSON;
          _row.attObj.Markup_Json__c = _row.markupJSON;
          _row.markupCount = annotationDetailJSRef.current?.users_markup_counts ;
          setOpenfileIns(_row);
          if(updateTableState){
            updateTableState(pdfAttachmentRef.current);
          }
          // Parse xfdfString to separate multiple annotation changes to individual annotation change
          try{
            if(clientRef.current){
              clientRef.current.send(JSON.stringify({
                event:'send_update',
                documentId: pdfAttachmentRef.current.id ,
                xfdfString: xfdfString,
                orginClient : clientIdRef.current
              }));
            }
          }catch(ae){
            console.log('collab error ::',ae.message);
          }
        }
      }).catch((e)=>{
        console.log('markup save error',e);
      });
    }
  }
  const handleCloseExportDialog = () => {
    setExportItems([]);
    setExportDialog(false);
  };
  const preparePdfData = async (pdfData = pdfAttachmentRef.current , documentReload =true, updateTable) => {
    //console.log("pdfAttachmentRef >> " , JSON.stringify(pdfAttachmentRef.current));
    //console.log( "pdfData >> " , JSON.stringify(pdfData));
    if (pdfData) {
      let issueLists = [];
      let _row = JSON.parse(JSON.stringify(pdfData));
      if(_row?.issueList.length > 0 ){
        for(let isr of _row?.issueList){
          if(isr.Id){
            issueLists.push(isr);
          }
        }
      }
      _row.issueList = issueLists;
      _row.issue = _row.issueList.length;
      setOpenfileIns(_row);
      let savedXml = pdfData?.markupJSON;
      let brekDown;
      if (savedXml) {
        //console.log('savedXml ',savedXml);
        await xml2js.parseStringPromise(savedXml).then(function (result) {
          //console.log("parseStringPromise", JSON.stringify(result));
          setAnnotationJSList(result); 
          brekDown =getAnnotationDetailsFormXMLJS(result , credential)
          //console.log("result.annots ::", result.xfdf.annots);
          //console.log("stringy fy", JSON.stringify(result));
          xmlTojsonXML.current = savedXml;
        }).catch(async function (err) {
          // Failed
          console.log("xml to json err :: ", err);
        });
      }else{
        xmlTojsonXML.current = null;
        setAnnotationJSList(undefined);
      }
      if(updateTable === true){
        _row.markupJSON = savedXml;
        _row.Markup_Json__c = _row.markupJSON;
        _row.attObj.Markup_Json__c = _row.markupJSON;
        _row.markupCount = brekDown?.users_markup_counts ;
        //console.log('annotationDetailJSRef.current :: ',annotationDetailJSRef.current);
        setOpenfileIns(_row);
        if(updateTableState){
          updateTableState(pdfAttachmentRef.current);
        }
      }
      if(documentReload === true){
        setViewer(false);
        //setTimeout(()=>{
          loadDocument(pdfAttachmentRef.current.url);
        //},500);
      }else{
        if(instanceRef.current){
          //handleServerAnnotationChange(pdfAttachmentRef.current.id , xmlTojsonXML.current , instanceRef.current);
        }
      }
    }
  };
  const checkCurentUserEditPermission = (ann) => {
    let sfIssue = getSFIssueObjByAnnonateId(ann.Id);
    let defaultPermission = ann.Author === credential?.userId;
    // any ann with 50% opacity will also be editable regardless of the author
    let customAccess = ann.getCustomData('access');
    //console.log('customAccess :: ',customAccess);
    let customOwner = ann.getCustomData('userId');
    //console.log('customOwner :: ',customOwner);
    let customPermission = ((ann.Subject !== 'Issue' && (customOwner === credential?.userId))); // customAccess === 'public' ||
    //console.log('pre defaultPermission :: ',defaultPermission);
    if(ann.Subject === 'Issue'){
      if( customOwner === credential?.userId && ( !sfIssue || (sfIssue?.Status__c !== 'Void' && sfIssue?.Status__c !== 'Closed' ) ) ){
        customPermission = true;
      }else {
        defaultPermission = false
      }
    }
    //console.log('post customPermission :: ',customPermission);
    //console.log('customPermission :: ',customPermission);
    return defaultPermission || customPermission;
  };
  const exportAnnotationsCustom = async ( _instance = instance )=>{
    return await exportAnnotationsCustomGlobal(_instance);
  };

  const loadDocument = async (fileUrl) => {
    setViewer(true);
    //console.log('viewer_.curre nt :: ',viewer.current);
    //console.log('viewer_ ', viewer);
    if(instanceRef.current){
      /* const instance = instanceRef.current;
      await instance.UI.closeDocument().then(function() {
        console.log('Document is closed');
      }).catch(()=>{
        console.log('Document is error ');
      }); */
    }
    setTimeout(async ()=>{
      if (viewer.current) {
        let isPdf = false;
        let isMSDoc = false;
        let fe = pdfAttachmentRef.current.File_Extension__c?.toLowerCase();
        if(fe === '.pdf' || fe === 'pdf'){
          isPdf = true;
          isMSDoc = false;
        }else if(MS_DOC_FORMAT.includes(`.${fe}`) || MS_DOC_FORMAT.includes(fe)){
          isPdf = false;
          isMSDoc = true;
        }
        setIsPdf(isPdf);
        const disElement = [
          "leftPanelTabs",
          "zoomThumbOutButton",
          "thumbnailsSizeSlider",
          "toolbarGroup-Annotate",
          "toolbarGroup-Shapes",
          "toolbarGroup-Insert",
          "toolbarGroup-Edit",
          "toolbarGroup-FillAndSign",
          "toolbarGroup-Forms",
          "toolbarGroup-Redact",
          "linkButton",
          "annotationCommentButton",
          "thumbnailControl",
          "documentControl",
          "leftPanelResizeBar",
          "searchPanelResizeBar"
        ];
        if(instanceRef.current){
          const instance = instanceRef.current;
          instance.UI.loadDocument(fileUrl, {
            documentId: pdfAttachmentRef.current.id + version,
            filename: pdfAttachmentRef.current.File_Name__c
          })
        }else{
          setShowZoomAction(false);
          WebViewer({
              fullAPI: true,
              path: "/webviewer/public",
              initialDoc: fileUrl,
              disabledElements: disElement,
              licenseKey: APRYSE_L_KEY,
              annotationUser:credential?.userId,
              enableMeasurement: true,
              disableObjectURLBlobs: true,
              backendType: WebViewer.BackendTypes.ASM,
            },viewer.current
          ).then(async (instance) => {
            instance.UI.disableElements(disElement);
            //instance.UI.disableFeatures(instance.UI.Feature.LocalStorage);
            //console.log("instance", instance);
            //setShowZoomAction(true);
            setInstance(instance);
            const { documentViewer, annotationManager, Tools, Annotations} = instance.Core;
            const defaultMarkupTool = documentViewer.getTool(Tools.ToolNames.PAN);
            const scrollView = documentViewer.getScrollViewElement();
            const simulateControlScroll = (event) => {
              if (documentViewer.getToolMode().name === "Pan") {
                scrollView.style.overflow = 'hidden';
                if(!event.ctrlKey){
                  // Create a new wheel event with the Control key pressed
                  const newEvent = new WheelEvent('wheel', {
                    deltaX: event.deltaX,
                    deltaY: event.deltaY,
                    deltaZ: event.deltaZ,
                    deltaMode: event.deltaMode,
                    clientX: event.clientX,
                    clientY: event.clientY,
                    screenX: event.screenX,
                    screenY: event.screenY,
                    pageX: event.pageX,
                    pageY: event.pageY,
                    ctrlKey: true,  // Simulate Control key pressed
                    shiftKey: event.shiftKey,
                    altKey: event.altKey,
                    metaKey: event.metaKey,
                    bubbles: event.bubbles,
                    cancelable: event.cancelable,
                    composed: event.composed
                  });
                  scrollView.dispatchEvent(newEvent);
                }
              }else {
                scrollView.style.overflow = 'auto';
              }
            };
            // Use capturing phase to ensure we catch the event early
            scrollView.addEventListener('wheel', simulateControlScroll, { capture: true, passive: false });
            annotationManager.setPermissionCheckCallback((author, annotation) => {
              return checkCurentUserEditPermission(annotation);
            });
            setAnnotationManage(annotationManager);
            const iframeWindow = instance.UI.iframeWindow;
            const header = iframeWindow.document.querySelector('[data-element="header"]');
            header.style.display = 'none';
            const toolsHeader = iframeWindow.document.querySelector('[data-element="toolsHeader"]');
            toolsHeader.style.display = 'none';
            const IssueAnnotation  = await enableIssuePinToolForInstance(instance);
            const issueTool = documentViewer.getTool(issueToolName);
            const preDefinedIssueStyle = await getPreDefinedIssueStyle(instance);
            class RectCloudAnnotation extends Annotations.RectangleAnnotation {
              constructor() {
                super("square"); // provide the custom XFDF element name
                this.Subject = "Cloud";
                this.setBorderStyle("cloudy");
              }
            }
            RectCloudAnnotation.prototype.elementName = "square";
            annotationManager.registerAnnotationType(RectCloudAnnotation.prototype.elementName, RectCloudAnnotation);
            class RectCloudCreateTool extends Tools.GenericAnnotationCreateTool {
              constructor(documentViewer) {
                // TriangleAnnotation is the class (function) for our annotation we defined previously
                super(documentViewer, RectCloudAnnotation);
              }
            }
            const rectcloudTool = new RectCloudCreateTool(documentViewer);
            const rectcloudToolName = "AnnotationCreateRectCloud";
            instance.UI.registerTool(
              {
                toolName: rectcloudToolName,
                toolObject: rectcloudTool,
                buttonImage:
                  '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">' +
                  '<path d="M12 7.77L18.39 18H5.61L12 7.77M12 4L2 20h20L12 4z"/>' +
                  '<path fill="none" d="M0 0h24v24H0V0z"/>' +
                  "</svg>",
                buttonName: "rectCloudToolButton",
                tooltip: "Rect Cloud",
              },
              RectCloudAnnotation
            );
            documentViewer.getTool(issueToolName).setStyles(preDefinedIssueStyle);
            documentViewer.getTool(rectcloudToolName).setStyles({
                StrokeThickness: 4,
                StrokeColor: new Annotations.Color(255, 0, 0),
                FillColor: new Annotations.Color(0, 0, 0, 0),
              });
            documentViewer.getTool("AnnotationCreateFreeHandHighlight").setStyles({
                StrokeThickness: 8,
                StrokeColor: new Annotations.Color(255, 0, 0),
              });
            documentViewer.getTool("AnnotationCreateFreeHand").setStyles({
                StrokeThickness: 4,
                StrokeColor: new Annotations.Color(255, 0, 0),
              });
            documentViewer.getTool("AnnotationCreateArrow").setStyles({
                StrokeThickness: 4,
                StrokeColor: new Annotations.Color(255, 0, 0),
              });
            documentViewer.getTool("AnnotationCreateRectangle").setStyles({
                StrokeThickness: 4,
                StrokeColor: new Annotations.Color(255, 0, 0),
                FillColor: new Annotations.Color(0, 0, 0, 0),
              });
            documentViewer.getTool("AnnotationCreateLine").setStyles({
                StrokeThickness: 4,
                StrokeColor: new Annotations.Color(255, 0, 0),
              });
            documentViewer.getTool("AnnotationCreateEllipse").setStyles({
                StrokeThickness: 4,
                StrokeColor: new Annotations.Color(255, 0, 0),
                FillColor: new Annotations.Color(0, 0, 0, 0),
              });
            documentViewer.getTool("AnnotationCreateCallout").setStyles({
                StrokeThickness: 4,
                FontSize: 16,
                TextAlign: "center",
                StrokeColor: new Annotations.Color(255, 0, 0),
                FillColor: new Annotations.Color(0, 0, 0, 0),
              });
            documentViewer.getTool("AnnotationCreateFreeText").setStyles({
                StrokeThickness: 4,
                FontSize: 16,
                StrokeColor: new Annotations.Color(255, 0, 0),
                TextAlign: "center",
              });
            const multiSelectTool = documentViewer.getTool(Tools.ToolNames.EDIT);
            documentViewer.addEventListener("zoomUpdated", (zoom) => {
              setCurrentZoomLevel(zoom);
            });
            documentViewer.getTool("AnnotationCreateRectangularAreaMeasurement").setStyles({
              StrokeThickness: 6,
              StrokeColor: new Annotations.Color(255, 0, 0),
            });
            documentViewer.getTool("AnnotationCreateEllipseMeasurement").setStyles({
              StrokeThickness: 6,
              StrokeColor: new Annotations.Color(255, 0, 0),
            });
            documentViewer.getTool("AnnotationCreateAreaMeasurement").setStyles({
              StrokeThickness: 6,
              StrokeColor: new Annotations.Color(255, 0, 0),
            });
            documentViewer.getTool("AnnotationCreatePerimeterMeasurement").setStyles({
              StrokeThickness: 6,
              StrokeColor: new Annotations.Color(255, 0, 0),
            });
            documentViewer.getTool("AnnotationCreateDistanceMeasurement").setStyles({
              StrokeThickness: 6,
              StrokeColor: new Annotations.Color(255, 0, 0),
            });
            /* documentViewer.addEventListener("toolModeUpdated", (newToolObject , oldToolObject) => {
              console.log('newToolObject :: ',newToolObject);
              console.log('oldToolObject :: ',oldToolObject);
            });*/
            documentViewer.addEventListener("documentLoaded", async () => {
              //console.log('doc loded');
              setShowZoomAction(true);
              const docu = documentViewer.getDocument();
              const pageCount = docu.getPageCount();
              const leftPanelButton = iframeWindow.document.querySelector('[data-element="leftPanelButton"]');
              const leftPanel = iframeWindow.document.querySelector('[data-element="leftPanel"]');
              const thumbnailPanel = document.getElementById('thubmnail-panel');
              if (thumbnailPanel && leftPanel) {
                thumbnailPanel.addEventListener('click', () => {
                  if(pagePanelRef.current){
                    const pdfContainer = leftPanel.querySelectorAll('.container');
                    pdfContainer.forEach(element => {
                      element.style.height = "100px";
                    });
                  }
                })
              }
              if(callFrom  === "FilesComponent"){
                styleLibraryLeftPanel(leftPanel , pageCount , leftPanelButton);
              }
              async function styleLibraryLeftPanel(leftPanel , pageCount , leftPanelButton){
                const leftPanelContainer = leftPanel.querySelector('.left-panel-container');
                leftPanelContainer.style.padding = "0px";
                const leftPanelHeader = leftPanel.querySelector('.left-panel-header');
                leftPanelHeader.style.backgroundColor = "white";
                leftPanelHeader.style.margin = "0px";
                leftPanelHeader.style.padding = "0px";
                leftPanelHeader.style.borderBottom = "1px solid #EAEAEA";
                const thumPanelTitle = iframeWindow.document.createElement('div');
                thumPanelTitle.classList.add('thumb-panel-title');
                thumPanelTitle.style.width = "100%";
                const pdfContainer = iframeWindow.document.querySelectorAll('.container');
                pdfContainer.forEach(element => {element.style.height = "100px";});
                const thumPanelTitleContainer = iframeWindow.document.createElement('div');
                thumPanelTitleContainer.classList.add('thumb-panel-title-container');
                thumPanelTitleContainer.style.display = "flex";
                thumPanelTitleContainer.style.gap = "90px";
                thumPanelTitleContainer.style.padding = "12px 16px";
                thumPanelTitleContainer.style.borderBottom = "1px solid #EAEAEA";
                thumPanelTitleContainer.style.width = "100%";
                const titContainerInnerDiv = iframeWindow.document.createElement('div');
                titContainerInnerDiv.classList.add('thumb-panel-title-container-text');
                titContainerInnerDiv.style.display = "flex";
                titContainerInnerDiv.style.gap = "90px";
                titContainerInnerDiv.style.padding = "12px 16px";
                titContainerInnerDiv.style.width = "100%";
                titContainerInnerDiv.textContent =  `${pageCount} Pages`;
                titContainerInnerDiv.color = '#505050';
                const leftIconContainer = iframeWindow.document.createElement('div');
                const closeIconImg = iframeWindow.document.createElement('img');
                closeIconImg.alt = 'Image Description';
                closeIconImg.src = '/close.svg'; 
                closeIconImg.style.cursor = "pointer";
                closeIconImg.addEventListener('mouseover', function() {
                  closeIconImg.style.backgroundColor = '#F8F8F8'; 
                });
                closeIconImg.addEventListener('mouseout', function() {
                  closeIconImg.style.backgroundColor = 'initial'; // Reset to the default background color
                });
                closeIconImg.addEventListener('click', function() {setPagesPanel(false);
                  leftPanelButton.click();
                });
                thumPanelTitleContainer.textContent = 'Pages';
                thumPanelTitleContainer.style.fontWeight = 600;
                thumPanelTitleContainer.style.fontSize = "14px";
                thumPanelTitleContainer.style.color = "black";
                leftPanelHeader.appendChild(thumPanelTitle);
                thumPanelTitle.appendChild(thumPanelTitleContainer);
                thumPanelTitle.appendChild(titContainerInnerDiv);
                thumPanelTitleContainer.appendChild(leftIconContainer);
                thumPanelTitleContainer.appendChild(closeIconImg);
                leftPanel.style.backgroundColor = '#F8F8F8';
                leftPanel.style.height = '100vh';
                leftPanel.style.borderLeft = '1px solid #D8D8D8';
                leftPanel.style.boxShadow = '2px 0px 3px #ccc';
              }
              documentViewer.setToolMode(defaultMarkupTool);
              scrollView.style.overflow = 'auto';
              setCurrentToolMode_(defaultMarkupTool.name);
              setCurrentZoomLevel(documentViewer.getZoomLevel());
              if (xmlTojsonXML?.current) {
                await handleServerAnnotationChange(pdfAttachmentRef.current.id , xmlTojsonXML?.current , instance);
              }
              runAutoSave.current = true;
              const zOutEle = document.getElementById('zoom-out-button');
              if(zOutEle){
                if(!zOutEle.dataset?.clickBound || zOutEle.dataset.clickBound != 'yes'){
                  zOutEle.dataset.clickBound = 'yes';
                  zOutEle.addEventListener('click', () => {
                    let preLevel = documentViewer.getZoomLevel();
                    if(preLevel > 0.1){
                      documentViewer.zoomTo(documentViewer.getZoomLevel() - 0.25);
                    }else{
                      documentViewer.zoomTo(0.1);
                    }
                  });
                }
              }
              const zInEle = document.getElementById('zoom-in-button');
              if(zInEle){
                if(!zInEle.dataset?.clickBound || zInEle.dataset.clickBound != 'yes'){
                  zInEle.dataset.clickBound = 'yes';
                  zInEle.addEventListener('click', () => {
                    documentViewer.zoomTo(documentViewer.getZoomLevel() + 0.25);
                  });
                }
              }
              const doc = documentViewer.getDocument();
              const firstPage = doc.getPageInfo(1);
              //console.log('Width:', firstPage);
              setPageSize(firstPage);
              const issueAnno = document.getElementById("issue-annotation");
              if (issueAnno) {
                issueAnno.addEventListener("click", () => {
                  scrollView.style.overflow = 'auto';
                  if(issueTool.name !== documentViewer.getToolMode().name){
                    documentViewer.setToolMode(issueTool);
                  }else{
                    documentViewer.setToolMode(defaultMarkupTool);
                  }
                  setCurrentToolMode_(documentViewer.getToolMode().name);
                });
              }
              const addNewIssue = document.getElementById("add-new-issue");
              if (addNewIssue) {
                addNewIssue.addEventListener("click", () => {
                  deselectAnnotation(instance);
                  const X = documentViewer.getPageWidth(1) / 2;
                  const Y = documentViewer.getPageHeight(1) / 2;
                  const annot = new IssueAnnotation({PageNumber: 1});
                  annot.X = X;
                  annot.Y = Y;
                  annot.StrokeThickness = preDefinedIssueStyle.StrokeThickness;
                  annot.FillColor = preDefinedIssueStyle.FillColor;
                  annot.StrokeColor = preDefinedIssueStyle.StrokeColor;
                  annot.Style = preDefinedIssueStyle.Style;
                  annotationManager.addAnnotation(annot);
                  // Always redraw annotation
                  annotationManager.redrawAnnotation(annot);
                });
              }
              const textAnnotation = documentViewer.getTool(Tools.ToolNames.FREETEXT);
              const textAnno = document.getElementById("text-annotation");
              if (textAnno) {
                textAnno.addEventListener("click", () => {
                  scrollView.style.overflow = 'auto';
                  if(textAnnotation.name !== documentViewer.getToolMode().name){
                    documentViewer.setToolMode(textAnnotation);
                  }else{
                    documentViewer.setToolMode(defaultMarkupTool);
                  }
                  setCurrentToolMode_(documentViewer.getToolMode().name);
                });
              }
              const CalloutAnnotation = documentViewer.getTool(Tools.ToolNames.CALLOUT);
              const CalloutAnno = document.getElementById("callout-annotation");
              if (CalloutAnno) {
                CalloutAnno.addEventListener("click", () => {
                  scrollView.style.overflow = 'auto';
                  if(CalloutAnnotation.name !== documentViewer.getToolMode().name){
                    documentViewer.setToolMode(CalloutAnnotation);
                  }else{
                    documentViewer.setToolMode(defaultMarkupTool);
                  }
                  setCurrentToolMode_(documentViewer.getToolMode().name);
                });
              }
    
              //const cloudAnnotation = documentViewer.getTool(Tools.ToolNames.POLYGON_CLOUD);
              const cloudAnno = document.getElementById("POLYGON_CLOUD");
              if (cloudAnno) {
                cloudAnno.addEventListener("click", () => {
                  scrollView.style.overflow = 'auto';
                  if(rectcloudTool.name !== documentViewer.getToolMode().name){
                    documentViewer.setToolMode(rectcloudTool);
                  }else{
                    documentViewer.setToolMode(defaultMarkupTool);
                  }
                  setCurrentToolMode_(documentViewer.getToolMode().name);
                });
              }
              const arrowAnnotation = documentViewer.getTool(Tools.ToolNames.ARROW);
              const arrowAnno = document.getElementById("arrow-annotation");
              if (arrowAnno) {
                arrowAnno.addEventListener("click", () => {
                  scrollView.style.overflow = 'auto';
                  if(arrowAnnotation.name !== documentViewer.getToolMode().name){
                    documentViewer.setToolMode(arrowAnnotation);
                  }else{
                    documentViewer.setToolMode(defaultMarkupTool);
                  }
                  setCurrentToolMode_(documentViewer.getToolMode().name);
                });
              }
              const MeasureAnnotation = document.getElementById("MeasureAnnotation");
              if (MeasureAnnotation) {
                MeasureAnnotation.addEventListener("click", () => {
                  scrollView.style.overflow = 'auto';
                  setTimeout(() => {
                    const distanceMeasureAnnotation = documentViewer.getTool(Tools.ToolNames.DISTANCE_MEASUREMENT);
                    const measureAnno = document.getElementById("distanceMeasure-annotation");
                    if (measureAnno) {
                      measureAnno.addEventListener("click", () => {
                        console.log("call");
                        scrollView.style.overflow = 'auto';
                        if (distanceMeasureAnnotation.name !== documentViewer.getToolMode().name) {
                          documentViewer.setToolMode(distanceMeasureAnnotation);
                        } else {
                          documentViewer.setToolMode(defaultMarkupTool);
                        }
                        setCurrentToolMode_(documentViewer.getToolMode().name);
                      });
                    }
                    const perimeterAnnotation = documentViewer.getTool(Tools.ToolNames.PERIMETER_MEASUREMENT);
                    const perimeterAnno = document.getElementById("perimeterMeasure-annotation");
                    if (perimeterAnno) {
                      perimeterAnno.addEventListener("click", () => {
                        scrollView.style.overflow = 'auto';
                        if (perimeterAnnotation.name !== documentViewer.getToolMode().name) {
                          documentViewer.setToolMode(perimeterAnnotation);
                        } else {
                          documentViewer.setToolMode(defaultMarkupTool);
                        }
                        setCurrentToolMode_(documentViewer.getToolMode().name);
                      });
                    }
                    const areaAnnotation = documentViewer.getTool(Tools.ToolNames.AREA_MEASUREMENT);
                    const areaAnno = document.getElementById("areaMeasure-annotation");
                    if (areaAnno) {
                      areaAnno.addEventListener("click", () => {
                        scrollView.style.overflow = 'auto';
                        if (areaAnnotation.name !== documentViewer.getToolMode().name) {
                          documentViewer.setToolMode(areaAnnotation);
                        } else {
                          documentViewer.setToolMode(defaultMarkupTool);
                        }
                        setCurrentToolMode_(documentViewer.getToolMode().name);
                      });
                    }
                    const ellipseMeasureAnno = document.getElementById("ellipseMeasure-annotation");
                    if (ellipseMeasureAnno) {
                      ellipseMeasureAnno.addEventListener("click", () => {
                        scrollView.style.overflow = 'auto';
                        const circleMeasureTool = iframeWindow.document.querySelector('[data-element="ellipseAreaToolGroupButton"]');
                        if (circleMeasureTool) {
                          const dataElementValue = circleMeasureTool.querySelector('[data-element="ellipseAreaToolGroupButton"]');
                          if (documentViewer.getToolMode().name != 'AnnotationCreateEllipseMeasurement') {
                            dataElementValue.click();
                          } else {
                            documentViewer.setToolMode(defaultMarkupTool);
                          }
                          setCurrentToolMode_(documentViewer.getToolMode().name);
                        }
                      });
                    }
                    const rectangulaMeasureAnno = document.getElementById("rectangulaMeasure-annotation");
                    if (rectangulaMeasureAnno) {
                      rectangulaMeasureAnno.addEventListener("click", () => {
                        scrollView.style.overflow = 'auto';
                        const reactMeasureTool = iframeWindow.document.querySelector('[data-element="rectangleAreaToolGroupButton"]');
                        if (reactMeasureTool) {
                          const dataElementValue = reactMeasureTool.querySelector('[data-element="rectangleAreaToolGroupButton"]');
                          if (documentViewer.getToolMode().name != 'AnnotationCreateRectangularAreaMeasurement') {
                            dataElementValue.click()
                          } else {
                            documentViewer.setToolMode(defaultMarkupTool);
                          }
                          setCurrentToolMode_(documentViewer.getToolMode().name);
                        }
                      });
                    }
                  }, 100)
                })
              }
    
              const PanAnnotation = document.getElementById("PanAnnotation");
              if (PanAnnotation) {
                PanAnnotation.addEventListener("click", () => {
                  scrollView.style.overflow = 'auto';
                  setTimeout(() => {
                    console.log("This will run after 1 second!");
                    const panAnnotation = documentViewer.getTool(Tools.ToolNames.PAN);
                    const panAnno = document.getElementById("PAN");
                    if (panAnno) {
                      panAnno.addEventListener("click", () => {
                        if(panAnnotation.name !== documentViewer.getToolMode().name){
                          documentViewer.setToolMode(panAnnotation);
                        }else{
                          documentViewer.setToolMode(defaultMarkupTool);
                        }
                        setCurrentToolMode_(documentViewer.getToolMode().name);
                      });
                    }
                    const selectAnno = document.getElementById("MultiSelect");
                    if (selectAnno) {
                      selectAnno.addEventListener("click", () => {
                        scrollView.style.overflow = 'auto';
                        if(multiSelectTool.name !== documentViewer.getToolMode().name){
                          documentViewer.setToolMode(multiSelectTool);
                        }else{
                          documentViewer.setToolMode(defaultMarkupTool);
                        }
                        setCurrentToolMode_(documentViewer.getToolMode().name);
                      });
                    }
                  }, 100);
                });
              }
              const InkAnnotation = document.getElementById("InkAnnotation");
              if (InkAnnotation) {
                InkAnnotation.addEventListener("click", () => {
                  scrollView.style.overflow = 'auto';
                  setTimeout(() => {
                    const freehandAnnotation = documentViewer.getTool(Tools.ToolNames.FREEHAND);
                    const freehandAnno = document.getElementById("FREEHAND");
                    if (freehandAnno) {
                      freehandAnno.addEventListener("click", () => {
                        if(freehandAnnotation.name !== documentViewer.getToolMode().name){
                          documentViewer.setToolMode(freehandAnnotation);
                        }else{
                          documentViewer.setToolMode(defaultMarkupTool);
                        }
                        setCurrentToolMode_(documentViewer.getToolMode().name);;
                      });
                    }
                    const freehandheiAnnotation = documentViewer.getTool(Tools.ToolNames.FREEHAND_HIGHLIGHT);
                    const freehandheiAnno = document.getElementById("FREEHAND_HIGHLIGHT");
                    if (freehandheiAnno) {
                      freehandheiAnno.addEventListener("click", () => {
                        scrollView.style.overflow = 'auto';
                        if(freehandheiAnnotation.name !== documentViewer.getToolMode().name){
                          documentViewer.setToolMode(freehandheiAnnotation);
                        }else{
                          documentViewer.setToolMode(defaultMarkupTool);
                        }
                        setCurrentToolMode_(documentViewer.getToolMode().name);
                      });
                    }
                  }, 100);
                });
              }
              const RectAnnotation = document.getElementById("RectAnnotation");
              if (RectAnnotation) {
                RectAnnotation.addEventListener("click", () => {
                  scrollView.style.overflow = 'auto';
                  setTimeout(() => {
                    console.log("This will run after 1 second!");
                    const lineAnnotation = documentViewer.getTool(Tools.ToolNames.LINE);
                    const lineAnno = document.getElementById("LINE");
                    if (lineAnno) {
                      lineAnno.addEventListener("click", () => {
                        if(lineAnnotation.name !== documentViewer.getToolMode().name){
                          documentViewer.setToolMode(lineAnnotation);
                        }else{
                          documentViewer.setToolMode(defaultMarkupTool);
                        }
                        setCurrentToolMode_(documentViewer.getToolMode().name);
                      });
                    }
                    const circleAnnotation = documentViewer.getTool(Tools.ToolNames.ELLIPSE);
                    const circleAnno = document.getElementById("CIRCLE");
                    if (circleAnno) {
                      circleAnno.addEventListener("click", () => {
                        scrollView.style.overflow = 'auto';
                        if(circleAnnotation.name !== documentViewer.getToolMode().name){
                          documentViewer.setToolMode(circleAnnotation);
                        }else{
                          documentViewer.setToolMode(defaultMarkupTool);
                        }
                        setCurrentToolMode_(documentViewer.getToolMode().name);
                      });
                    }
                    const squareAnnotation = documentViewer.getTool(Tools.ToolNames.RECTANGLE);
                    const squareAnno = document.getElementById("SQUARE");
                    if (squareAnno) {
                      squareAnno.addEventListener("click", () => {
                        scrollView.style.overflow = 'auto';
                        if(squareAnnotation.name !== documentViewer.getToolMode().name){
                          documentViewer.setToolMode(squareAnnotation);
                        }else{
                          documentViewer.setToolMode(defaultMarkupTool);
                        }
                        setCurrentToolMode_(documentViewer.getToolMode().name);
                      });
                    }
                  }, 100);
                });
              }
              annotationManager.addEventListener("annotationChanged", async (annotations, action, { imported }) => {
                if(serverImporting.current){
                  return;
                }
                //console.log('annotationChanged action ::', action, imported);
                if(imported===true){
                  runAutoSave.current = false;
                  //console.log('ann.Subject :: ',annotations?.length ,annotations[0].Subject , annotations[0].isInternal(),annotations[0]);
                  await annotationManager.deleteAnnotations(annotations , {imported : true});
                  runAutoSave.current = true;
                  return;
                }
                if (annotations[0]) {
                  const ann = annotations[0];
                  let pushIfIssue = false;
                  let isIssueAnn = false;
                  //console.log("Annotations[0]", annotations[0]);
                  if(!(imported===true)){
                    //console.log("ann ::", ann);
                    if (action === 'add') {
                      ann.setCustomData("userId", credential?.userId);
                      ann.setCustomData("userFullName", credential?.userFullName);
                      ann.setCustomData("source", "save_annotation");
                      if (ann.Subject == 'Issue') {
                        ann.setWidth(28);
                        ann.setHeight(28);
                        ann.setCustomData("access", "public");
                      } else {
                        ann.setCustomData("access", "private");
                      }
                      ann.setCustomData("date", new Date());
                      selectAnnotationById(ann.Id, instance);
                    } else if (action == 'delete') {
                      let tempList = JSON.parse(JSON.stringify(pdfAttachmentRef.current?.issueList));
                      //console.log(`tempList :: ${JSON.stringify(tempList)}`);
                      let runAutosav = false;
                      for (const anN of annotations) {
                        if (anN.Subject === 'Issue') {
                          isIssueAnn = true;
                          let sfIssue = getSFIssueObjByAnnonateId(anN.Id);
                          const fInd = findIndex(tempList, { [annoIdField]: anN.Id });
                          let delTrue = false;
                          if (sfIssue?.Id) {
                            //console.log(`Delete Issue__c/${sfIssue?.Id}`);
                            await axios.delete(`${fetchUrl}/delete/Issue__c/${sfIssue?.Id}?token=${credential.token}&instanceUrl=${credential.instanceUrl}`)
                              .then((res) => {
                                //console.log("count:::: " + JSON.stringify(res));
                                if (res.status == 200) {
                                  delTrue = true;
                                  runAutosav = true;
                                }
                              }).catch((err) => {
                                console.log('Issue__c Delete Error::', err);
                              });
                          } else {
                            delTrue = true;
                          }
                          if (delTrue && fInd > -1) {
                            tempList.splice(fInd, 1);
                          }
                        }
                      }
                      if (isIssueAnn) {
                        handleIssueListChange(tempList, runAutosav, instance);
                      }
                    } else if (action == 'modify') {
                      for (const anN of annotations) {
                        if (anN.Subject == 'Issue') {
                          isIssueAnn = true;
                          let sfIssue = getSFIssueObjByAnnonateId(anN.Id);
                          if (sfIssue?.Id) {
                            pushIfIssue = true;
                            break;
                          }
                        }
                      }
                      createCustomPopups(ann);
                    }
                  }
                  //console.log("customData ::", ann);
                  let xfdfString = await exportAnnotationsCustom(instance);
                  //console.log("xfdfString ::", xfdfString);
                  let savedXml = xfdfString;
                  xml2js.parseStringPromise(savedXml).then(function (result) {
                    setAnnotationJSList(result);
                  });
                  if(!(imported === true)){
                    if (runAutoSave.current && (ann.Subject != 'Issue' || pushIfIssue)) {
                      console.log('auto save');
                      saveAnnotationsServer(instance, action, ann.Id);
                    }
                  }
                }
              });
              annotationManager.addEventListener("annotationSelected", async (annotations, action) => {
                //console.log('annotationSelected action :: ', action);
                /*if(pagesPanel && callFrom === "FilesComponent"){
                  leftPanelButton.click();
                }*/
                const selecAnnotaions = annotationManager.getSelectedAnnotations();
                //console.log('selecAnnotaions :: '+selecAnnotaions?.length);
                const issues = selecAnnotaions.filter((item) => item.Subject == 'Issue');
                var markups = selecAnnotaions.filter((item) => item.Subject !== 'Issue');
                var markupsToDeselect = [];
                //console.log('selec markup :: '+markups?.length);
                var markDeselected = false;
                if (action === "selected") {
                  var customAnno = annotations[0];
                  //console.log("customAnno ::", customAnno);
                  const isMultiselect = annotations?.length > 1;
                  //console.log("selected first", annotations[0].Subject);
                  //console.log("selected last", annotations[annotations.length-1].Subject);
                  if (isMultiselect) {
                    let issueFound = false;
                    for (const ann of annotations) {
                      if (ann.Subject == 'Issue') {
                        issueFound = true;
                        customAnno = ann;
                        break;
                      }
                    }
                    if (issueFound) {
                      markupsToDeselect = markups;
                      if (issues.length > 1) {
                        let issuetoDelselect = issues?.filter((item) => item.Id !== customAnno.Id);
                        if (issuetoDelselect?.length > 0) {
                          for (let iss of issuetoDelselect) {
                            markupsToDeselect.push(iss);
                          }
                        }
                      }
                    }
                  } else {
                    if (customAnno.Subject == 'Issue') {
                      markupsToDeselect = markups;
                      if (issues.length > 1) {
                        let issuetoDelselect = issues?.filter((item) => item.Id !== customAnno.Id);
                        if (issuetoDelselect?.length > 0) {
                          for (let iss of issuetoDelselect) {
                            markupsToDeselect.push(iss);
                          }
                        }
                      }
                    } else {
                      markupsToDeselect = issues;
                    }
                  }
                  console.log(customAnno.Subject,'markupsToDeselect ::',markupsToDeselect?.length);
                  _setSelectedAnnotation(customAnno, customAnno.Subject);
                  if (customAnno) {
                    createCustomPopups(customAnno , markupsToDeselect);
                  }
                } else {
                  //setSelectedMarkup(undefined);
                  _setSelectedAnnotation(undefined, undefined);
                }
                setSelectedMarkups(markups);
              });
              async function createCustomPopups(customAnno , markupsToDeselect){
                setTimeout(async () => {
                  const inTime = annotationManager.getSelectedAnnotations();
                  const currentIds = [];
                  for(const tAnn of inTime){
                    const isInToDeSelect = markupsToDeselect?.find(item => item.Id === tAnn.Id);
                    if(!isInToDeSelect){
                      currentIds.push(tAnn.Id);
                    }
                  }
                  const docContainer = await getContainerElement();
                  if (docContainer) {
                    const styleButton = iframeWindow.document.querySelector('[data-element="annotationStyleEditButton"]');
                    const deleteButton = iframeWindow.document.querySelector('[data-element="annotationDeleteButton"]');
                    if (styleButton) {
                      styleButton.style.display = 'none';
                    }
                    if (deleteButton) {
                      deleteButton.style.display = 'none';
                    }
                    //console.log("run",currentIds);
                    const container = docContainer.querySelector(".container");
                    if(container){
                      container.style.background = "#505050";
                      //container.style.width = "292px";
                      container.style.height = "70px";
                      const child_container = container.querySelector(".child-flex-container");
                      if (child_container) {
                        container.removeChild(child_container);
                        //console.log('removed :: child-flex-container');
                      }
                      if(customAnno.Subject == 'Issue' && documentViewer.getToolMode().name == issueToolName){
                        documentViewer.setToolMode(defaultMarkupTool);
                        setCurrentToolMode_(documentViewer.getToolMode().name);
                        selectAnnotationById(customAnno.Id, instance);
                        return;
                      }
      
                      // Create a container div for flex layout
                      const flexContainer = document.createElement("div");
                      flexContainer.classList.add("child-flex-container");
                      //console.log('created :: child-flex-container');
                      flexContainer.style.display = "flex";
                      flexContainer.style.padding = "2px";
      
                      // Define an array of content for the divs with different class names
                      const divContent = [];
                      if(customAnno.Subject !== 'Issue' && currentIds?.length == 1){
                        let havePermission = checkCurentUserEditPermission(customAnno);
                        if(havePermission){
                          divContent.push({
                            imageUrl: "/PencilMarkup.svg",
                            altText: "Pencil Icon",
                            text: "Edit",
                            className: "div-class-1", // Add a unique class name
                            clickHandler: (event) => {
                              const editStyle = iframeWindow.document.querySelector('[data-element="annotationStyleEditButton"]');
                              if (editStyle) {
                                editStyle.click();
                              }
                            },
                          });
                        }
                      }
                      if(customAnno.Subject !== 'Issue'){
                        let havePermission = checkCurentUserEditPermission(customAnno);
                        if(havePermission){
                          let nextAccess = getNewAccess(customAnno.getCustomData('access'));
                          let actionObj = getNextAction(nextAccess);
                          divContent.push({
                            divId: 'markup_access_pop_button',
                            imageUrl: actionObj.imageUrl,
                            altText: actionObj.altText,
                            text: actionObj.text,
                            className: "div-class-2", // Add a unique class name
                            clickHandler: async (event) => {
                              await updateMarkupCustomSecurityByIds(currentIds, null, instance);
                              nextAccess = getNewAccess(nextAccess);
                              actionObj = getNextAction(nextAccess);
                              var parentDiv = iframeWindow.document.getElementById('markup_access_pop_button');
                              if(parentDiv){
                                // Get the child elements within the parent div
                                var iEle = parentDiv.querySelector('img');
                                if(iEle){
                                  iEle.src = actionObj.imageUrl;
                                  iEle.alt = actionObj.altText;
                                }
                                var pEle = parentDiv.querySelector('p');
                                if(pEle){
                                  pEle.textContent = actionObj.text;
                                }
                              }
                            },
                          });
                        }
                        function getNextAction(_nextAccess){
                          return {
                            imageUrl: currentIds?.length == 1 ? `/${_nextAccess}Markup.svg` : '/privateMarkup.svg',
                            altText: currentIds?.length == 1 ? 'Make '+_nextAccess : 'Make Public/ Private',
                            text: currentIds?.length == 1 ? 'Make '+_nextAccess : 'Make Public/ Private'};
                        }
                      }
                      let havePermission = checkCurentUserEditPermission(customAnno);
                      if(havePermission){
                        divContent.push({
                          imageUrl: "/deleteMarkup.svg",
                          altText: "delete Icon",
                          text: "Delete",
                          className: "div-class-3", // Add a unique class name
                          clickHandler: (event) => {
                            const deleteButton = iframeWindow.document.querySelector('[data-element="annotationDeleteButton"]');
                            if (deleteButton) {
                              deleteButton.click();
                            }
                          },
                        });
                      }
                      if(divContent?.length){
                        // Loop through the content array and create div elements
                        divContent.forEach((content, index) => {
                          // Create a new div element
                          const newDiv = document.createElement("div");
                          newDiv.dataset.annotationId = customAnno.Id;
                          if(content.divId){
                            newDiv.id = content.divId;
                          }
                          newDiv.style.color = "white";
                          newDiv.style.paddingLeft = content.className == "div-class-2" ? "13px" : content.className == "div-class-3" ? "30px" : "35px";
                          newDiv.style.paddingTop = "8px";
                          newDiv.style.width = "94px";
                          newDiv.style.cursor = "pointer";
                          // Set the class name based on the className property
                          newDiv.className = content.className;
                          newDiv.classList.add("div-hover");
                          newDiv.addEventListener("click", content.clickHandler);
                          newDiv.addEventListener("mouseover", mouseOver, false);
                          newDiv.addEventListener("mouseout", mouseOut, false);
                          function mouseOver() {
                            newDiv.style.backgroundColor = "#929292";
                          }
                          function mouseOut() {
                            newDiv.style.backgroundColor = "#505050";
                          }
                          // Create an img element
                          const imgElement = document.createElement("img");
                          imgElement.dataset.annotationId = customAnno.Id;
                          imgElement.src = content.imageUrl;
                          imgElement.alt = content.altText;
                          imgElement.style.paddingLeft = content.className == "div-class-2" ? "25px" : content.className == "div-class-3" ? "10px" : "5px";
                          // Create a paragraph element for text
                          const textElement = document.createElement("p");
                          textElement.dataset.annotationId = customAnno.Id;
                          textElement.textContent = content.text;
                          // Append the image and text elements to the new div
                          newDiv.appendChild(imgElement);
                          newDiv.appendChild(textElement);
                          // Append the new div to the flex container
                          flexContainer.appendChild(newDiv);
                        });
        
                        // Append the flex container to the parent div
                        container.appendChild(flexContainer);
                        //console.log(customAnno.Subject,'f markupsToDeselect :: ',markupsToDeselect?.length);
                        if (markupsToDeselect?.length > 0) {
                          annotationManager.deselectAnnotations(markupsToDeselect);
                        }
                      }
                      timeOutMilsec.current = 100;
                    }
                  }
                  async function getContainerElement(){
                    var docCon = iframeWindow.document.querySelector('[data-element="annotationPopup"]');
                    var count = 0;
                    while (!docCon && count < 200) {
                      docCon = iframeWindow.document.querySelector('[data-element="annotationPopup"]');
                      if (docCon) {
                        break;
                      }
                      await delay(300);
                      count++;
                    }
                    return docCon;
                  }
                  async function delay(ms) {
                    return new Promise(resolve => setTimeout(()=>{
                      //console.log('delay '+count);
                      resolve();
                    }, ms));
                  }
                }, timeOutMilsec.current);
              }
            });
            setInstance(instance);
          }).catch(err=>{
            console.log('webviewer error :: ',err);
          });
        }
      }
    },100);
  };
  const handleVersionChange = (e) => {
    setVersion(e.target.value);
    const filterList = versionList.find((version) => version.SF_Display_Version_Num == e.target.value);
    if (filterList) {
      setViewer(false);
      setSetName(filterList.set);
      setDefaultFile(filterList);
      setTimeout(()=>{
        loadDocument(filterList.url);
      },500);
    }
  };
  const handleInitPDFDocument = async (pdfData = pdfAttachmentRef.current) => {
    //setOpenfileIns(pdfData);
    if(cancelAction.current){
      //console.log('found cancel');
      cancelAction.current();
    }
    const { token, cancel } = axios.CancelToken.source();
    cancelAction.current = cancel;
    setViewer(false);
    getUpdatedMarkupDetail(pdfData , true, token);
    getVersionList(pdfData , token);
  };
  const getUpdatedMarkupDetail = async (pdfData = pdfAttachmentRef.current , documentReload = true , cancelToken) => {
    let foundAttach = false;
    let config;
    if(cancelToken){
      config = {cancelToken : cancelToken};
    }
    let canceled = false;
    let pdfLoaded = false;
    if(documentReload === true && pdfData?.url?.length > 30){
      try{
        preparePdfData(pdfData , documentReload , false );
        pdfLoaded = true;
      }catch (e){
        console.log('error in prepare',e,foundAttach);
      }
    }
    axios.get(`${fetchUrl}/attachment/${pdfData.id}`,config).then(async (res) => {
      if (res?.status === 200) {
        if(res.data.attachment){
          try{
            let att = await prepareAmazonRecordRow(res.data.attachment , credential);
            if(documentReload === true && cancelToken){
              //console.log('get att.currentVersion :: ',att.currentVersion);
              setVersion(att.currentVersion);
              setSetName(att.set);
            }
            preparePdfData(att , pdfLoaded === false && documentReload ? true : false ,  true);
          }catch (e){
            console.log('error in prepare',e);
          }
        }
      }
    }).catch(err =>{
      console.log('get UpdatedMarkupDetail :: ',err);
      if (axios.isCancel(err)) {
        // Handle the cancellation
        canceled = true;
        console.log('Markup Request canceled by user',pdfData.name);
      }
    });
    /* if(foundAttach === false && canceled == false){
      try{
        preparePdfData(pdfData , documentReload , false );
      }catch (e){
        console.log('error in prepare',e,foundAttach);
      }
    } */
  };
  const getVersionList = async (pdfData = pdfAttachmentRef.current, cancelToken) => {
    //console.log("pdfopenfiledat a.folderId ::", pdfAttachmentRef.current.folderId);
    //console.log("pdfopenfiledat a.id ::", pdfAttachmentRef.current.id);
    let config;
    if(cancelToken){
      config = {cancelToken : cancelToken};
    }
    await axios.get(`${fetchUrl}/version/list/${pdfData.folderId}/${pdfData.id}`,config).then((res) => {
      if (res?.status === 200) {
        let versionList = res.data.versionList;
        versionList.forEach((e, i) => {
          e.id = pdfData?.id;
        });
        const filterList = versionList.find((version) => version.SF_Display_Version_Num === pdfData.currentVersion);
        setDefaultFile(filterList);
        setVersionList(versionList);
      }
    }).catch(err =>{
      console.log('getVersionList :: ',err);
      if (axios.isCancel(err)) {
        // Handle the cancellation
        console.log('Version Request canceled by user',pdfData.name);
      }
    });
  };
  const handleToggleDrawerPanel = (section, controlled) => {
    //console.log('section , controlled  :: ', section, controlled);
    //console.log('handleToggleDrawerPanel instance ', instance);
    let leftPanelButton ;
    if (instance) { 
      leftPanelButton = instance.UI.iframeWindow.document.querySelector('[data-element="leftPanelButton"]');
    }
    if (section === "markup") {
      //console.log('markupPanelOpen :: ',markupPanelOpen);
      setMarkupPanelOpen(!markupPanelOpen);
      setIssueListPanel(false);
      setIssuePanelOpen(false);
      if (pagePanelRef.current && leftPanelButton) {
        leftPanelButton.click();
        setPagesPanel(false);
      }

    } else if (section === "issue") {
      if (controlled == true) {
        setIssueListPanel(!showIssueListPanel);
        if (issuePanelOpen === false) {
          setIssuePanelOpen(!issuePanelOpen);
        }
      } else {
        setIssuePanelOpen(!issuePanelOpen);
      }
      setMarkupPanelOpen(false);
      if (pagePanelRef.current && leftPanelButton) {
        leftPanelButton.click();
        setPagesPanel(false);
      }
    } else if (section === "pages") {
      if (leftPanelButton) {
        leftPanelButton.click();
        setPagesPanel(!pagePanelRef.current);
      }
      setMarkupPanelOpen(false);
      setIssueListPanel(false);
      setIssuePanelOpen(false);
    }
  };
  async function selectAnnotationById(annId, _instance = instanceRef.current) {
    const sel_ann = _instance.Core.annotationManager.getAnnotationsList().find(annotation => annotation.Id === annId);
    //console.log('select annotation :: ',sel_ann , annId);
    if (sel_ann) {
      if (sel_ann.Subject == 'Issue') {
        deselectAnnotation(_instance);
      }
      _instance.Core.annotationManager.selectAnnotation(sel_ann);
    }
  }
  async function setMarkupVisiblity(visiv, _instance = instanceRef.current, filterListMarkup = annotationFilterListJS) {
    //console.log('setMarkupVisiblity_ :: ',filterListMarkup?.length , visiv);
    const annoList = _instance.Core.annotationManager.getAnnotationsList().filter(annotation => {
      let pushTocheck = annotation.Subject !== 'Issue';
      if (visiv == false) {
        pushTocheck = false;
        if (filterListMarkup?.length > 0) {
          pushTocheck = false;
          let indF = filterListMarkup.find(ann => ann.Id == annotation.Id);
          //console.log('indF :: ',indF);
          if (indF) {
            pushTocheck = true;
          }
        }
      }
      return pushTocheck;
    });
    //console.log('select annotation :: ',sel_ann , annId);
    if (annoList) {
      setMarkupVisiblityByAnnotations(annoList, visiv, _instance);
    }
  }
  async function setMarkupVisiblityByAnnotations(annoList, visiv, _instance = instanceRef.current , stopDeselect) {
    if (annoList) {
      if (annoList?.length > 0) {
        runAutoSave.current = false;
        for (const an_ of annoList) {
          an_.NoView = visiv;
          await _instance.Core.annotationManager.updateAnnotation(an_);
        }
        if( !(stopDeselect === true) ){
          deselectAnnotation(_instance);
        }
        runAutoSave.current = true;
      }
    }
  }
  async function selectAnnotationByIdRow(annId, _instance = instanceRef.current) {
    //console.log("annId ::", annId);
    const sel_ann = _instance.Core.annotationManager.getAnnotationsList().find(annotation => annotation.Id === annId);
    //console.log('select annotation :: ',sel_ann , annId);
    if (sel_ann) {
      if (sel_ann.Subject == 'Issue') {
        deselectAnnotation(_instance);
      }
      _instance.Core.annotationManager.deselectAllAnnotations();
      _instance.Core.annotationManager.selectAnnotation(sel_ann);
    }
  }
  async function updateMarkupCustomSecurityByIds(markupIds, access, _instance = instanceRef.current) {
    const sel_ann = _instance.Core.annotationManager.getAnnotationsList().filter(annotation => markupIds.includes(annotation.Id));
    //console.log('select annotation :: ',sel_ann);
    if (sel_ann?.length > 0) {
      await updateMarkupCustomSecurityList(sel_ann, access, _instance);
    }
  }
  async function updateMarkupCustomSecurityList(annoList, access, _instance = instanceRef.current) {
    if (annoList?.length > 0) {
      for (const an_ of annoList) {
        let havePermission = checkCurentUserEditPermission(an_);
        if(havePermission){
          runAutoSave.current = false;
          let o_acc = an_.getCustomData('access');
          let new_acc = access;
          if (!access) {
            new_acc = getNewAccess(o_acc);
            an_.setCustomData('access', access);
          }
          an_.setCustomData('access', new_acc);
          runAutoSave.current = true;
          await _instance.Core.annotationManager.updateAnnotation(an_);
          await saveAnnotationsServer(_instance);
        }
      }
    }
  }
  function getNewAccess(o_acc) {
    return (o_acc === 'public' ? 'private' : (o_acc === 'private' ? 'public' : 'private'));
  }
  async function deleteAnnotationById(annId, _instance = instanceRef.current) {
    const sel_ann = _instance.Core.annotationManager.getAnnotationsList().find(annotation => annotation.Id === annId);
    //console.log('select annotation :: ',sel_ann , annId);
    if (sel_ann) {
      await deleteAnnotationList([sel_ann], _instance);
    }
  }
  async function deleteAnnotationList(annoList, _instance = instanceRef.current) {
    await _instance.Core.annotationManager.deleteAnnotations(annoList);
  }
  async function deselectAnnotationById(annId, _instance = instanceRef.current) {
    const sel_ann = _instance.Core.annotationManager.getAnnotationsList().find(annotation => annotation.Id === annId);
    console.log('de select annotation :: ',sel_ann.Subject );
    if (sel_ann) {
      if (sel_ann.Subject == 'Issue') {
        // delete associated issue with it
        deselectAnnotation(_instance);
      } else {
        _instance.Core.annotationManager.deselectAnnotation(sel_ann);
      }
    }
  }
  async function deselectAnnotation(_instance = instanceRef.current) {
    _setSelectedAnnotation(undefined);
    _instance.Core.annotationManager.deselectAllAnnotations();
  }
  async function handleChaneZoomValue(value, _instance = instanceRef.current) {
    _instance.Core.documentViewer.zoomTo(value / 100);
  }
  function _setSelectedAnnotation(ann, type) {
    setSelectedMarkup(undefined);
    setSelectedIssueStamp(undefined);
    if (type == 'Issue') {
      setSelectedIssueStamp(ann);
      handleToggleDrawerPanel('issue');
      //setIssueListPanel(false);
    } else if (type) {
      setSelectedMarkup(ann);
      //handleToggleDrawerPanel('markup');
    }
  }
  function handleSearchInPdf(ann, type) {
    let pdfSearchButton;
    pdfSearchButton = instance.UI.iframeWindow.document.querySelector('[data-element="searchButton"]');
    pdfSearchButton.click();
    setSearchInPdf(!searchInPdf);

  }
  const MarkupItemDetail = ({ annId, options, primary, handleSelectAnn }) => {
    //console.log("options ::", options);
    const secondary1 = JSON.parse(options);
    //console.log('secondary1.date ::',secondary1.date);
    return (
      <>
        <ListItemText
          onClick={() => {
            handleSelectAnn(annId)
          }}
          primary={primary}
          secondary={`By ${secondary1.userFullName} ${moment(secondary1.date?.includes('T') ? secondary1.date.replace('T', ' ') : secondary1.date).format("MMM DD YYYY")}`}
        />
        <Tooltip title={secondary1.access == 'public' ? "Public" : "Private"} >
          <IconButton onClick={async ()=>{
            let nextAcces = secondary1.access === 'public' ? "private" : "public";
            await updateMarkupCustomSecurityByIds([annId] , nextAcces , instance);
            selectAnnotationByIdRow(annId , instance);
          }} >
            {secondary1.access == 'public' ? (
              <img src={PublicIcon} alt="public_icon" />
            ) : (
              <img src={PrivateIcon} alt="private_icon" />
            )}
          </IconButton>
        </Tooltip>
      </>
    );
  };
  
  return (
    <Dialog open={true} fullScreen>
      <WithoutTemplateSection className="crop_pdf_container">
        <div className="sheet-view-navigation">
          <div className="sheets-selected-and-info body-regular">
            <p className="sheets-selected-and-info-item-1">{openfileIns?.name}</p>
            <div className="current-sheet-number">{callFrom === "SheetsComponent" && setName}</div>
            <div className="sheets-selected-and-info-item">
              <FormControl sx={{ width: "auto" }}>
                <Select
                  className="version_select"
                  MenuProps={{ classes: { paper: "version_menu_props_class" } }}
                  value={version}
                  renderValue={() => { return 'Version ' + version + `${openfileIns?.currentVersion === version ?  "(current)" : ""} `}}
                  onChange={(e) => handleVersionChange(e)}
                >
                  {openfileIns && openfileIns.Document_File_Versions__r?.records?.length > 0 &&
                    openfileIns.Document_File_Versions__r?.records
                    .sort((a, b) => b.Numbering_Sequence__c - a.Numbering_Sequence__c)
                    .map((item) => (
                      <MenuItem key={item.Numbering_Sequence__c} value={item.Numbering_Sequence__c}>
                        <Box sx={{ display: "block" }}>
                          <div className="version_head">
                            Version {item.Numbering_Sequence__c}
                            {item.Numbering_Sequence__c === openfileIns.currentVersion && " (current)"}
                          </div>
                          <div className="version_name">{item.Set__r?.Name}</div>
                          <div className="version_date">{moment(openfileIns.Folder__r && openfileIns.Folder__r.Name == "Shop Drawings" ? item?.CreatedDate : item.Set__r?.Issuance_Date__c).format("MMM DD, YYYY")} / {item.CreatedBy.Name}</div>
                        </Box>
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="frame-52">
            <div className="frame-44">
              <div className="define-sheet-number-area body-regular" onClick={handleSearchInPdf} style={{backgroundColor: searchInPdf && "#E8EFFF"}}>
                <Tooltip title="Search" placement="bottom">
                  <img src={SearchIcon} alt="search_icon" />
                </Tooltip>
              </div>
              {isPdf &&
                <div onClick={() => {
                  setCompareOpen(true);
                  if (!defaultFile) {
                    setDefaultFile(pdfopenfiledata);
                  }
                }} className="define-sheet-number-area body-regular"  style={{backgroundColor: compareOpen && "#E8EFFF"}}>
                  <Tooltip title="Compare" placement="bottom">
                    <img src={CompareIcon} alt="compare_icon" />
                  </Tooltip>
                </div>
              }
              <div className="define-sheet-number-area body-regular" onClick={() => {
                  let file = cloneDeep(openfileIns);
                  file.currentVersion = version;
                  file.url = defaultFile.url;
                  setExportItems([file]);
                  setExportDialog(true)
                }} style={{backgroundColor: exportDialog && "#E8EFFF"}}>
                <Tooltip title="Download" placement="bottom">
                  <img src={DownloadIcon} alt="download_icon" />
                </Tooltip>
              </div>
              <div className="define-sheet-number-area body-regular" style={{backgroundColor: shareDocument && "#E8EFFF"}} onClick={()=> {
                setShareDocument(true);
              }}>
                <Tooltip title="Share" placement="bottom">
                  <img src={ShareIcon} alt="share_icon" />
                </Tooltip>
              </div>
            </div>
            <div className="frame-42">
                <Button className="custom_button apply_button" onClick={() => { clickOnClose(); }} >
                  Close
                </Button>
            </div>
          </div>
        </div>
        <Box sx={{ display: "flex" }}>
          <div className="siderbar_drawer_sheets">
            { callFrom == "FilesComponent" && 
              <Button id="thubmnail-panel-custom" className="markup_button_style" style = {{borderBottom : "1px solid #EAEAEA"}} onClick={() => handleToggleDrawerPanel("pages")} >
                <img style={{width: !pagesPanel && "15px"}} src={pagesPanel ? "/pages.svg" : "/pages-grey.svg"} alt="markup_icon" />
                <span style={{ color: !pagesPanel ? "rgba(80, 80, 80, 1)" : "rgba(52, 110, 245, 1)",}} >
                  Pages
                </span>
              </Button>
            }
            {isPdf &&
              <Button className="markup_button_style" onClick={() => handleToggleDrawerPanel("markup")} >
                <img src={!markupPanelOpen ? MarkupIcon : MarkupActiveIcon} alt="markup_icon" />
                <span style={{ color: !markupPanelOpen ? "rgba(80, 80, 80, 1)" : "rgba(52, 110, 245, 1)", }} >
                  Markups
                </span>
              </Button>
            }
            {isPdf &&
              <Button className="markup_button_style" sx={{ borderBottom: "1px solid #EAEAEA" }} onClick={() => { handleToggleDrawerPanel("issue", true); }} >
                <img src={issuePanelOpen && showIssueListPanel ? IssueIconActive : IssueIcon} alt="issue_icon" />
                <span style={{ color: issuePanelOpen && showIssueListPanel ? "rgba(52, 110, 245, 1)" : "rgba(80, 80, 80, 1)" }}>
                  Issues
                </span>
              </Button>
            }
            {issuePanelOpen &&
              <IssuePanelDialog
                className='nonono'
                attachId={openfileIns?.attObj?.Id}
                credential={credential}
                sfIssueList={openfileIns?.issueList}
                issueAnnotation={selectedIssueAnno}
                issuePanelOpen={issuePanelOpen}
                handleIssueChange={handleIssueListChange}
                handleSelectAnn={selectAnnotationById}
                handleDeSelectAnn={(annId) => { deselectAnnotation(instance) }}
                showListPanel={showIssueListPanel}
                setListPanel={setIssueListPanel}
                setToastMessage={setToastMessage}
                onClickAddNewIssue={() => {
                  const addNewIssue = document.getElementById("add-new-issue"); //add-new-issue
                  if (addNewIssue) {
                    addNewIssue.click();
                  }
                }}
              />
            }
            {markupPanelOpen &&
              <Drawer className={(markupPanelOpen) ? "drawer_Open" : "drawer_closed"}
                anchor="left" open={true} variant="persistent" ModalProps={{ BackdropProps: { invisible: true, } }}>
                {!markupFilterPanel &&
                  <div>
                    <div className="markups_head">
                      <Box className="markup_title">Markups</Box>
                      <IconButton sx={{ borderRadius: 0 }}
                        onClick={() => {
                          handleToggleDrawerPanel('markup');
                        }}
                      >
                        <img src={CloseIcon} alt="close_icon" />
                      </IconButton>
                    </div>
                    <div className={!isVisibilityOn ? "markup_list_off" : "markup_list"} >
                      <div className="markups_subhead">
                        {isMarkSelectionActive ? (
                          <Box style={{ color: "#505050" }}>
                            {selectedMarkups?.length} Selected
                          </Box>
                        ) : (
                          <Box style={{ color: "#505050" }}>{annotationFilterListJS?.length > 0 ? annotationFilterListJS?.length : 0} Items</Box>
                        )}
                        <div>
                          <Tooltip title="Selection">
                            <IconButton sx={{ background: isMarkSelectionActive && "#E8EFFF", borderRadius: 0 }} onClick={toggleMarkupSelectionPanel} >
                              <img src={SelectAllIcon} alt="select_all_icon" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={isVisibilityOn ? "Hide" : "Unhide"}>
                            <IconButton sx={{ borderRadius: 0 }} onClick={handleVisibiltyMarkup} >
                              {isVisibilityOn ? (
                                <img src={VisibilityIcon} alt="visibility_icon" />
                              ) : (
                                <img src={VisibilityOffIcon} alt="visibility_off_icon" />
                              )}
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Filter">
                            <IconButton sx={{ borderRadius: 0 }} onClick={() => handleFilterMarkupPanel()} >
                              <img src={FilterIcon} alt="filter_icon" />
                              {isMarkupFiltred &&
                                <div className='ellipse'></div>
                              }
                            </IconButton>
                          </Tooltip>
                        </div>
                      </div>
                      <List style={{height : '80vh'}}>
                        {annotationFilterListJS?.length > 0 &&
                          annotationFilterListJS.map((markUpItem, index) => {
                            const _ann0 = (_annId) => {
                              let anno = selectedMarkups?.findIndex((item) => item.Id == markUpItem.Id);
                              return anno > -1 ? true : false;
                            }
                            return <ListItem className={_ann0(markUpItem.Id) ? "checkbox_selected" : ''} key={markUpItem.id + ' ' + markUpItem.Id}>
                              {isMarkSelectionActive && (
                                <Checkbox sx={{ color: "#D8D8D8", "&.Mui-checked": { color: "#346EF5", }, }}
                                  style={{ padding: "0px", marginRight: "10px", }}
                                  checked={_ann0(markUpItem.Id)}
                                  value={markUpItem.Id}
                                  onChange={(e) =>
                                    handleSelectMarkup(markUpItem.Id, e.target.checked)
                                  }
                                />
                              )}
                              <img alt="markup_icon" style={{width : '30px'}}
                                src={ markUpItem.markupType == "Rectangle" ? "box tool.svg"
                                : markUpItem.markupType == "Free Hand" ? "Pencil.svg"
                                : markUpItem.markupType == "Free Hand Highlight" ? "highlighter.svg"
                                : markUpItem.markupType == "Free Text" ? "text.svg"
                                : markUpItem.markupType == "Callout" ? "callout.svg"
                                : markUpItem.markupType == "Cloud" ? "cloud.svg"
                                : markUpItem.markupType == "Ellipse" ? "circle tool.svg"
                                : markUpItem.markupType == "Line" ? "line tool.svg"
                                : markUpItem.markupType == "Arrow" ? "arrow tool.svg"
                                : markUpItem.markupType == "LineDimension" ? "/distanceMeasurement.svg"
                                : markUpItem.markupType == "PolyLineDimension" ? "/perimeterMeasure.svg"
                                : markUpItem.markupType == "PolygonDimension" ? "/areaMeasure.svg"
                                : markUpItem.markupType == "EllipseDimension" ? "/circleMeasureTool.svg"
                                : markUpItem.markupType == "RectangularDimension" ? "/rectangleMeasureTool.svg"
                                : ""}
                              />
                              <MarkupItemDetail annId={markUpItem.Id}
                                primary={markUpItem.label} options={JSON.stringify(markUpItem.customData)} handleSelectAnn={selectAnnotationByIdRow} />
                            </ListItem>
                          })
                        }
                      </List>
                    </div>
                    {isMarkSelectionActive && selectedMarkups?.length > 0 && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          gap: "15px",
                          borderTop: "1px solid #EAEAEA",
                          paddingTop: "5px",
                          opacity: selectedMarkups.length ? "" : 0.5,
                        }}
                      >
                        <div className="mouseHover">
                          <Tooltip title="Make Public" placement="bottom">
                            <IconButton sx={{ borderRadius: 0 }} onClick={() => {
                              updateMarkupCustomSecurityList(selectedMarkups, 'public', instance);
                            }}>
                              <img src="public (1).svg" alt="Public icon" />
                            </IconButton>
                          </Tooltip>
                        </div>
                        <div className="mouseHover">
                          <Tooltip title="Make Private" placement="bottom">
                            <IconButton sx={{ borderRadius: 0 }} onClick={() => {
                              updateMarkupCustomSecurityList(selectedMarkups, 'private', instance);
                            }}>
                              <img src={PrivateIcon} alt="private icon" />
                            </IconButton>
                          </Tooltip>
                        </div>
                        <div className="mouseHover">
                          <Tooltip title="Delete" placement="bottom">
                            <IconButton sx={{ borderRadius: 0 }} onClick={() => {
                              setDeleteMarkupsDialog(true);
                            }}>
                              <img src="delete.svg" alt="delete icon" />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </div>
                    )}
                  </div>
                }
                {markupFilterPanel &&
                  <>
                    <div>
                      <div className="markups_head">
                        <Box className="filter_title">
                          <IconButton sx={{ borderRadius: 0 }} onClick={() => handleFilterMarkupPanel()} >
                            <img style = {{height: "21px", width: "25px"}} src="/navigate previous.svg" alt="left_arrow_icon" />
                          </IconButton>
                          <Box className="markup_title" style={{ paddingTop: "8px" }} >
                            Filter Markups
                          </Box>
                        </Box>
                        <Box className="filter_icons">
                          <Tooltip title="Reset Filter" placement="bottom">
                            <IconButton sx={{ borderRadius: 0 }} onClick={() => handleResetMarkupFilters()} >
                              <img src={ResetIcon} alt="icons" />
                            </IconButton>
                          </Tooltip>
                          <IconButton sx={{ borderRadius: 0 }} onClick={() => handleFilterMarkupPanel()} >
                            <img src={CloseIcon} alt="close_icon" />
                          </IconButton>
                        </Box>
                      </div>
                      <div className="private_markup">
                        <Checkbox sx={{ color: "#D8D8D8", "&.Mui-checked": { color: "#346EF5", }, }}
                          style={{ padding: "0px" }} checked={showPrivateMarkups}
                          onChange={(e) => handleMarkupAccessFilterChange(e, 'private')}
                        />
                        <span style={{ paddingLeft: "7px", color: "#505050", fontSize: "14px", fontWeight: 400, }} >
                          Private Markups
                        </span>
                      </div>
                      <div className="public_markup">
                        <Checkbox sx={{ color: "#D8D8D8", "&.Mui-checked": { color: "#346EF5", }, }}
                          style={{ padding: "0px" }} checked={showPublicMarkups}
                          onChange={(e) => handleMarkupAccessFilterChange(e, 'public')}
                        />
                        <span style={{ paddingLeft: "7px", color: "#505050", fontSize: "14px", fontWeight: 400, }} >
                          Public Markups
                        </span>
                      </div>
                      <div className="form_group">
                        <InputLabel>Created By</InputLabel>
                        <DropdownSelect options={markupOwnerList} onChange={(newValues) => {
                          //console.log('newValues :: ', newValues);
                          setMfUserList(newValues);
                        }} keyProp={'Id'} labelProp={'Name'} activeSelections={mfUserList} multiselect />
                        {/*<Select style={{ color: "#929292", }} className="created_by"
                            value={mfUserList} multiple onChange={(eve) => {
                              const { target: { value } } = eve;
                              const newValues = typeof value === 'string' ? value.split(',') : value;
                              console.log('newValues :: ',newValues);
                              setMfUserList(newValues);
                            }}>
                            {markupOwnerList?.length > 0 &&
                              markupOwnerList.map((user, ind) => (
                                <MenuItem key={user.Id} value={user.Id}>{user.Name}</MenuItem>
                              ))
                            }
                          </Select>*/}
                      </div>
                      <div className="form_group">
                        <InputLabel>Markup Type</InputLabel>
                        <DropdownSelect options={markupTypeList} onChange={(newValues) => {
                          //console.log('newValues :: ',newValues);
                          setMfMarkupTypeList(newValues);
                        }} isValueArray={true} activeSelections={mfMarkupTypeList} multiselect />
                      </div>
                      <Box className="action_button">
                        <Button className="custom_button cancel_button" onClick={() => handleFilterMarkupPanel()} >
                          Cancel
                        </Button>
                        <Button className="custom_button apply_button"  onClick={() => {
                          handleFilterMarkupPanel();
                          filterMarkupPanelList();
                        }}>
                          Apply Filters
                        </Button>
                      </Box>
                    </div>
                  </>}
              </Drawer>
            }
          </div>
          {showZoomActions && openfileIns.version === version && (
            <>
              <ZoomButtons zoomLevel={currentZoomLevel} onChangeZoom={(value)=> {handleChaneZoomValue(value, instance)}}/>
              {isPdf &&
                <div className="sheet-file-view-3" style={{ right: searchInPdf ? "400px" : '190px', width: '0px' }}> {/* style={selectedIssueAnno?.Id ? { right : '470px' } : {}} */}
                  <div className="frame-wrapper" style={{ left: searchInPdf && "40px", height: 'auto' }}>
                    <div className="frame-3">
                      <div
                        className="tool-button"
                        style={{
                          backgroundColor: panAnnotation
                            ? "#E8EFFF"
                            : currentToolMode == "Pan" ? "#E8EFFF" : currentToolMode == "AnnotationEdit" ? "#E8EFFF" : "",
                        }}
                      >
                        <Tooltip
                          title={
                            panAnnotation
                              ? ""
                              : currentToolMode == "Pan"
                                ? "Pan"
                                : "Multi Select"
                          }
                          placement="left"
                        >
                          <div className="overlap-group-2">
                            <div
                              id="PanAnnotation"
                              onClick={handlePanAnnotation}
                              className="img-wrapper"
                            >
                              <img
                                className="img"
                                alt="Single select"
                                src={
                                  currentToolMode == "AnnotationEdit"
                                    ? "/single select.svg"
                                    : "/pan.svg"
                                }
                              />
                            </div>
                            <img
                              className="arrow-drop-down"
                              alt="Arrow drop down"
                              src="arrow_drop_down.svg"
                            />
                          </div>
                        </Tooltip>
                      </div>
                      <img
                        className="line"
                        alt="Line"
                        src="dividerIcon.svg"
                      />
                      <div
                        className="tool-button"
                        style={{
                          backgroundColor: inkAnnotation
                            ? "#E8EFFF"
                            : currentToolMode == "AnnotationCreateFreeHandHighlight"
                              ? "#E8EFFF"
                              : currentToolMode == "AnnotationCreateFreeHand" && "#E8EFFF",
                        }}
                      >
                        <Tooltip
                          title={
                            inkAnnotation
                              ? ""
                              : currentToolMode == "AnnotationCreateFreeHandHighlight"
                                ? "Highlight"
                                : currentToolMode == "AnnotationCreateFreeHand"
                                  ? "Freehand"
                                  : "Freehand"
                          }
                          placement="left"
                        >
                          <div className="overlap-group-2">
                            <div
                              id="InkAnnotation"
                              onClick={handleInkAnnotation}
                              className="img-wrapper"
                            >
                              <img
                                className="img"
                                alt="Pencil"
                                src={
                                  currentToolMode == "AnnotationCreateFreeHandHighlight"
                                    ? "highlighter.svg"
                                    : currentToolMode == "AnnotationCreateFreeHand"
                                      ? "Pencil.svg"
                                      : "Pencil.svg"
                                }
                              />
                            </div>
                            <img
                              className="arrow-drop-down"
                              alt="Arrow drop down"
                              src="arrow_drop_down.svg"
                            />
                          </div>
                        </Tooltip>
                      </div>
                      <Tooltip title="Text" placement="left">
                        <div id="text-annotation" className="buttons-an"
                          style={{ backgroundColor: currentToolMode == "AnnotationCreateFreeText" && "#E8EFFF" }}>
                          <img className="img" alt="Text" src="text.svg" />
                        </div>
                      </Tooltip>
                      <Tooltip title="Callout" placement="left">
                        <div id="callout-annotation" className="buttons-an"
                          style={{ backgroundColor: currentToolMode == "AnnotationCreateCallout" && "#E8EFFF" }}>
                          <img className="img" alt="Callout" src="callout.svg" />
                        </div>
                      </Tooltip>
                      <Tooltip title="Cloud" placement="left">
                        <div id="POLYGON_CLOUD" className="buttons-an" style={{ backgroundColor: currentToolMode == "AnnotationCreateRectCloud" && "#E8EFFF", }} >
                          <div className="cloud">
                            <img className="subtract" alt="Subtract" src="cloud.svg" />
                          </div>
                        </div>
                      </Tooltip>
                      <div className="tool-button"
                        style={{
                          backgroundColor: rectAnnotation ? "#E8EFFF" :
                            currentToolMode === "AnnotationCreateRectangle" ? "#E8EFFF" :
                              currentToolMode === "AnnotationCreateLine" ? "#E8EFFF" :
                                currentToolMode === "AnnotationCreateEllipse" && "#E8EFFF",
                        }}>
                        <Tooltip placement="left"
                          title={rectAnnotation ? "" :
                            currentToolMode === "AnnotationCreateRectangle" ? "Rectangle" :
                              currentToolMode === "AnnotationCreateLine" ? "Line" :
                                currentToolMode === "AnnotationCreateEllipse" ? "Circle" : "Rectangle"
                          }>
                          <div className="overlap-group-2">
                            <div id="RectAnnotation" onClick={handleRectAnnotation} className="img-wrapper" >
                              <img className="img" alt="Square"
                                src={currentToolMode === "AnnotationCreateRectangle" ? "box tool.svg" :
                                  currentToolMode === "AnnotationCreateLine" ? "line tool.svg" :
                                    currentToolMode === "AnnotationCreateEllipse" ? "circle tool.svg" : "box tool.svg"}
                              />
                            </div>
                            <img className="arrow-drop-down" alt="Arrow drop down" src="arrow_drop_down.svg" />
                          </div>
                        </Tooltip>
                      </div>
                      <Tooltip title="Arrow" placement="left">
                        <div id="arrow-annotation" className="buttons-an"
                          style={{ backgroundColor: currentToolMode === "AnnotationCreateArrow" && "#E8EFFF" }}>
                          <img className="img" alt="Arrow tool" src="arrow tool.svg" />
                        </div>
                      </Tooltip>
                      <img
                        className="line"
                        alt="Line"
                        src="dividerIcon.svg"
                      />
                      <Tooltip title={measurementAnnotations ? "" :
                        currentToolMode === "AnnotationCreateDistanceMeasurement" ? "Distance" :
                          currentToolMode === "AnnotationCreatePerimeterMeasurement" ? "Perimeter" :
                            currentToolMode === "AnnotationCreateAreaMeasurement" ? "Area" :
                              currentToolMode === "AnnotationCreateEllipseMeasurement" ? "Ellipse Area" : "Rectangular Area"
                      } placement="left">
                        <div id='MeasureAnnotation' className="buttons-an" onClick={handleMeasurementAnnotation}
                          style={{
                            backgroundColor: measurementAnnotations ? "#E8EFFF" :
                              currentToolMode === "AnnotationCreateDistanceMeasurement" ? "#E8EFFF" :
                                currentToolMode === "AnnotationCreatePerimeterMeasurement" ? "#E8EFFF" :
                                  currentToolMode === "AnnotationCreateAreaMeasurement" ? "#E8EFFF" :
                                    currentToolMode === "AnnotationCreateEllipseMeasurement" ? "#E8EFFF" :
                                      currentToolMode === "AnnotationCreateRectangularAreaMeasurement" ? "#E8EFFF" : ""
                          }}>
                          <img
                            className="img"
                            alt="Ruler"
                            src={currentToolMode === "AnnotationCreateDistanceMeasurement" ? "/distanceMeasurement.svg" :
                              currentToolMode === "AnnotationCreatePerimeterMeasurement" ? "/perimeterMeasure.svg" :
                                currentToolMode === "AnnotationCreateAreaMeasurement" ? "/areaMeasure.svg" :
                                  currentToolMode === "AnnotationCreateEllipseMeasurement" ? "circleMeasureTool.svg" : "/rectangleMeasureTool.svg"}
                          />
                        </div>
                      </Tooltip>
                      <Tooltip title="Issue" placement="left">
                        <div>
                          <div id="add-new-issue" style={{ display: 'none' }}></div>
                          <div id="issue-annotation" className="buttons-an" style={{
                            backgroundColor: currentToolMode == "AnnotationCreateIssue" && "#E8EFFF",
                          }}>
                            <img
                              className="img"
                              alt="Add issue"
                              src="addIssue.svg"
                            />
                          </div>
                        </div>
                      </Tooltip>
                      {/* <img
                        className="line"
                        alt="Line"
                        src="https://anima-uploads.s3.amazonaws.com/projects/64ad0840ca8a2bb608c26088/releases/64d1db41854be05d50e2e7c9/img/line-29.svg"
                      /> */}
                      {/* <Tooltip title="Color" placement="left">
                        <div className="buttons-an">
                          <img
                            className="img"
                            alt="Color selector"
                            src="https://anima-uploads.s3.amazonaws.com/projects/64ad0840ca8a2bb608c26088/releases/64e33ae27d1705404f2bd212/img/color-selector.svg"
                          />
                        </div>
                      </Tooltip> */}
                      {/* <img
                        className="line"
                        alt="Line"
                        src="https://anima-uploads.s3.amazonaws.com/projects/64ad0840ca8a2bb608c26088/releases/64d1db41854be05d50e2e7c9/img/line-29.svg"
                      /> */}
                      {/* <Tooltip title="Undo" placement="left">
                      <div className="buttons-an">
                        <img
                          className="img"
                          alt="Undo"
                          src="/undo.svg"
                        />
                      </div>
                    </Tooltip>
                    <Tooltip title="Redo" placement="left">
                      <div className="buttons-an">
                        <img
                          className="img"
                          alt="Redo"
                          src="/redo.svg"
                        />
                      </div>
                    </Tooltip> */}
                    </div>
                  </div>
                  {inkAnnotation && (
                    <div className="drawer">
                      <div className="rectangle" />
                      <Tooltip title="Freehand" placement="top">
                        <div
                          onClick={() => handleInkAnnotation("FREEHAND")}
                          id="FREEHAND"
                          className="buttons-4a"
                          style={{
                            backgroundColor:
                              currentToolMode == "AnnotationCreateFreeHand" && "#E8EFFF",
                          }}
                        >
                          <img
                            className="img"
                            alt="Pencil"
                            src="Pencil.svg"
                          />
                        </div>
                      </Tooltip>
                      <Tooltip title="Highlight" placement="top">
                        <div
                          onClick={() => handleInkAnnotation("FREEHAND_HIGHLIGHT")}
                          id="FREEHAND_HIGHLIGHT"
                          className="highlighter-wrapper"
                          style={{
                            backgroundColor:
                              currentToolMode == "AnnotationCreateFreeHandHighlight" &&
                              "#E8EFFF",
                          }}
                        >
                          <img
                            className="img"
                            alt="Highlighter"
                            src="highlighter.svg"
                          />
                        </div>
                      </Tooltip>
                    </div>
                  )}
                  {panAnnotation && (
                    <div className="drawer-pan">
                      <div className="rectangle" />
                      <Tooltip title="Pan" placement="top">
                        <div
                          onClick={() => setPanAnnotation(false)}
                          id="PAN"
                          className="buttons-4a"
                          style={{
                            backgroundColor: currentToolMode == "Pan" && "#E8EFFF",
                          }}
                        >
                          <img className="img" alt="Pencil" src="pan.svg" />
                        </div>
                      </Tooltip>
                      <Tooltip title="Multi Select" placement="top">
                        <div id="MultiSelect" className="highlighter-wrapper"
                          style={{
                            backgroundColor: currentToolMode == "AnnotationEdit" && "#E8EFFF",
                          }}
                          onClick={() => setPanAnnotation(false)}
                        >
                          <img
                            className="img"
                            alt="Highlighter"
                            src="/single select.svg"
                          />
                        </div>
                      </Tooltip>
                    </div>
                  )}
                  {rectAnnotation && (
                    <div className="drawer-rect">
                      <div className="rectangle-1" />
                      <Tooltip title="Rectangle" placement="top">
                        <div
                          onClick={() => handleRectAnnotation("SQUARE")}
                          id="SQUARE"
                          className="highlighter-wrapper-1"
                          style={{
                            backgroundColor:
                              currentToolMode == "AnnotationCreateRectangle" && "#E8EFFF",
                          }}
                        >
                          <img
                            className="img"
                            alt="Highlighter"
                            src="box tool.svg"
                          />
                        </div>
                      </Tooltip>
                      <Tooltip title="Line" placement="top">
                        <div
                          onClick={() => handleRectAnnotation("LINE")}
                          id="LINE"
                          className="buttons-4a"
                          style={{
                            backgroundColor:
                              currentToolMode == "AnnotationCreateLine" && "#E8EFFF",
                          }}
                        >
                          <img className="img" alt="Pencil" src="/line tool.svg" />
                        </div>
                      </Tooltip>
                      <Tooltip title="Circle" placement="top">
                        <div
                          onClick={() => handleRectAnnotation("CIRCLE")}
                          id="CIRCLE"
                          className="highlighter-wrapper"
                          style={{
                            backgroundColor:
                              currentToolMode == "AnnotationCreateEllipse" && "#E8EFFF",
                          }}
                        >
                          <img
                            className="img"
                            alt="Highlighter"
                            src="circle tool.svg"
                          />
                        </div>
                      </Tooltip>
                    </div>
                  )}
                  {measurementAnnotations && (
                    <div className="drawer-measure">
                      <div className="measure-1">
                        <Tooltip title="Distance" placement="top">
                          <div
                            onClick={() => handleMeasurementAnnotation("FREEHAND")}
                            id="distanceMeasure-annotation"
                            className="buttons-4a"
                            style={{
                              backgroundColor:
                                currentToolMode == "AnnotationCreateDistanceMeasurement" && "#E8EFFF",
                            }}
                          >
                            <img
                              className="img"
                              alt="Pencil"
                              src="/distanceMeasurement.svg"
                            />
                          </div>
                        </Tooltip>
                        <Tooltip title="Perimeter" placement="top">
                          <div
                            onClick={() => handleMeasurementAnnotation("FREEHAND_HIGHLIGHT")}
                            id="perimeterMeasure-annotation"
                            className="highlighter-wrapper"
                            style={{
                              backgroundColor:
                                currentToolMode == "AnnotationCreatePerimeterMeasurement" &&
                                "#E8EFFF",
                            }}
                          >
                            <img
                              className="img"
                              alt="Highlighter"
                              src="/perimeterMeasure.svg"
                            />
                          </div>
                        </Tooltip>
                        <Tooltip title="Area" placement="top">
                          <div
                            onClick={() => handleMeasurementAnnotation("FREEHAND_HIGHLIGHT")}
                            id="areaMeasure-annotation"
                            className="highlighter-wrapper-1"
                            style={{
                              backgroundColor:
                                currentToolMode == "AnnotationCreateAreaMeasurement" &&
                                "#E8EFFF",
                            }}
                          >
                            <img
                              className="img"
                              alt="Highlighter"
                              src="/areaMeasure.svg"
                            />
                          </div>
                        </Tooltip>
                        <Tooltip title="Ellipse Area" placement="top">
                          <div
                            onClick={() => handleMeasurementAnnotation("FREEHAND_HIGHLIGHT")}
                            id="ellipseMeasure-annotation"
                            className="highlighter-wrapper-1"
                            style={{
                              left: '92px',
                              backgroundColor:
                                currentToolMode == "AnnotationCreateEllipseMeasurement" &&
                                "#E8EFFF",
                            }}
                          >
                            <img
                              className="img"
                              alt="Highlighter"
                              src="/circleMeasureTool.svg"
                            />
                          </div>
                        </Tooltip>
                        <Tooltip title="Rectangular Area" placement="top">
                          <div
                            onClick={() => handleMeasurementAnnotation("FREEHAND_HIGHLIGHT")}
                            id="rectangulaMeasure-annotation"
                            className="highlighter-wrapper-1"
                            style={{
                              left: '122px',
                              backgroundColor:
                                currentToolMode == "AnnotationCreateRectangularAreaMeasurement" &&
                                "#E8EFFF",
                            }}
                          >
                            <img
                              className="img"
                              alt="Highlighter"
                              src="/rectangleMeasureTool.svg"
                            />
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                  )}
                </div>
              }
            </>
          )}
          {navigation &&
            <>
              <DocumentNavigation
                isSync={(issuePanelOpen || markupPanelOpen) ? true : false}
                currentDoc={openfileIns}
                currentViewFiles={currentViewFiles}
                thumbnailMap={thumbnailMap}
                onSelect={(newDoc)=>{
                  setOpenfileIns(newDoc);
                  handleInitPDFDocument(newDoc);
                }}
              />
            </>
          }
          {compareOpen && defaultFile && (
            <VersionsSelect
              callFrom={callFrom}
              instance={instance}
              versionList={versionList}
              credential={credential}
              viewFolderFiles={viewFolderFiles}
              defaultFile={defaultFile}
              pdfopenfiledata={openfileIns}
              compareOpen={compareOpen}
              setCompareOpen={setCompareOpen}
              compareDialog={compareDialog}
              setCompareDialog={setCompareDialog}
            />
          )}
          {(showViewer || true ) && 
            <div
              id="extractPDFcontainer"
              className="webviewer"
              ref={viewer}
              style={{ width: "100%", height: "94vh" }}
            />
          }
          {loading &&
            <Spinner />
          }
        </Box>
      </WithoutTemplateSection>
      {deleteMarkupsDialog &&
        <DeleteMarkupsDialog
          onDelete={() => {
            let markupIds = [];
            for (let an_mark of selectedMarkups) {
              markupIds.push(an_mark.Id);
            }
            deleteAnnotationList(selectedMarkups, instance);
            setDeleteMarkupsDialog(false);
          }}
          markupCount={selectedMarkups?.length}
          deleteMarkupsDialog={deleteMarkupsDialog}
          setDeleteMarkupsDialog={setDeleteMarkupsDialog}
        />
      }
      {exportDialog && (
        <ExportFileDialog
          callFrom='ShowPdf'
          open={true}
          selectedFiles={exportItems}
          handleClose={handleCloseExportDialog}
          setLoading={setLoading}
          onAccept={(_selectedFiles, exportWithMarkup)=> {
            if(instance && _selectedFiles?.length === 1){
              downloadPdfDocument(_selectedFiles , _selectedFiles[0]?.File_Name__c,exportWithMarkup, instance).then(failed=>{
                let succ = !failed;
              });
            }
            handleCloseExportDialog();
          }}
        />
      )}
      {shareDocument && (
          <ShareableLinkComponent
            setToastMessage={setToastMessage}
            shareListItems={[openfileIns]}
            onCancel={(links) => {
              updateTableStateForLink(links);
              setShareDocument(false);
            }}
          />
        )}
      {currentToolMode == "AnnotationCreateIssue" &&
        <Draggable defaultPosition={{ x: 809, y: -814 }}>
          <div style={{ backgroundColor: "#505050", color: "#FFFFFF", width: "275px", padding: "5px 10px", fontSize: "14px", fontWeight: 400 }}>Please click an area to add an issue</div>
        </Draggable>
      }
      {toastMessage &&
        <ToastComponent message={toastMessage} handleClose={()=>setToastMessage(null)}/>
      }
    </Dialog>
  );
}
export default ShowPdf;
export const enableIssuePinToolForInstance = async (_instance)=>{
  const { documentViewer, annotationManager, Tools, Annotations, Math} = _instance.Core;
    class IssueAnnotation extends Annotations.EllipseAnnotation {
      constructor() {
        super('issue');
        // Customize properties, such as fill color, stroke color, subject, etc.
        this.Subject = 'Issue';
        this.Width = 28;
        this.Height = 28;
        this.NoResize = true; 
        this.NoZoom = true; 
      }
    }
    IssueAnnotation.SerializationType = Annotations.CustomAnnotation.SerializationTypes.CUSTOM;
    IssueAnnotation.prototype.elementName = 'issue';
    annotationManager.registerAnnotationType(IssueAnnotation.prototype.elementName, IssueAnnotation);
    class IssueCreateTool extends Tools.GenericAnnotationCreateTool {
      constructor(documentViewer) {
        super(documentViewer, IssueAnnotation);
      }
    };
    const issueTool = new IssueCreateTool(documentViewer);
    _instance.UI.registerTool({
      toolName: issueToolName,
      toolObject: issueTool,
      buttonImage: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
                      <path d="M12 7.77L18.39 18H5.61L12 7.77M12 4L2 20h20L12 4z"/>
                      <path fill="none" d="M0 0h24v24H0V0z"/>
                    </svg>`,
      buttonName: 'issueToolButton',
      tooltip: 'Issue'
    }, IssueAnnotation);
  return IssueAnnotation;
}
export const getPreDefinedIssueStyle = async (_instance)=>{
  const { Annotations} = _instance.Core;
  const preDefinedIssueStyle_ = {
    StrokeThickness: 3,
    FillColor: new Annotations.Color(issueStatusToColorMap['Draft'].colorBox.r, issueStatusToColorMap['Draft'].colorBox.g, issueStatusToColorMap['Draft'].colorBox.b, 0.8),
    StrokeColor: new Annotations.Color(253, 254, 254, 0.8),
    Style: 'Solid',
    NoResize: true
  };
  return preDefinedIssueStyle_;
}
