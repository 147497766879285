import Tooltip from "@mui/material/Tooltip";

const LocationBreadcrumbs = ({ breadcrumbs, onSelection }) => {

    return (
        <Tooltip
            title={
                <div style={{ display: "flex", gap: "5px", flexWrap: "wrap", cursor: "pointer", fontSize: '14px' }}>
                    {breadcrumbs.map((folder, index) => (
                        <span key={index} style={{ display: "flex", alignItems: "center" }}>
                            <span
                                className="location-breadcrumbs-folder text_no-wrap"
                                onClick={() => {
                                        onSelection(index);
                                }}
                            >
                                {folder}
                            </span>
                            {index < breadcrumbs.length - 1 && (
                                <span style={{ margin: "0 5px" }}>{">"}</span>
                            )}
                        </span>
                    ))}
                </div>
            }
            placement="left"
            disableInteractive={false}
            componentsProps={{
                tooltip: {
                    sx: {
                        width: 'auto',
                        maxWidth: 'unset',
                        maxHeight: 'unset',
                        height: '50px',
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        overflow: 'auto'
                    },
                },
            }}
        >
            <div style={{ cursor: "pointer", color: '#505050', fontSize: '13px' }}>
                {breadcrumbs[breadcrumbs.length - 1] || "No Location"}
            </div>
        </Tooltip>
    );
};

export default LocationBreadcrumbs;
