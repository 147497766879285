import React, { useState, useEffect } from "react";
import folderIcon from "assets/images/folder.svg";
import pdfRed from "assets/images/pdfRed.svg";
import {
  Box,
  CardContent,
  Grid,
  Checkbox,
  Card,
  CardMedia,
  CardActions,
  IconButton,
  Tooltip,
  Select,
  MenuItem
} from "@mui/material";

import { GridViewStyle } from "./folderTablestyle";

function GridViewComponent({ viewTableData , handleClickMenu, selectedIds ,openMenu,onRowClickFunc, onRowSelectionChange, callFrom, attachmentThumbnail, gridHeight}) {
  const [isCheck, setIsCheck] = useState(selectedIds);
  const [fileItems , setFileItems] = useState([]);
  const [folderItems , setFolderItems] = useState([]);
  useEffect(() => {
    splitTableGridData();
  }, [viewTableData]);
  useEffect(() => {
    setIsCheck(selectedIds);
  }, [selectedIds]);
  const splitTableGridData = () => {
    let folders =[];
    let files =[];
    if(viewTableData?.length > 0){
      for(let item of viewTableData){
        if(item.type == 'folder'){
          folders.push(item);
        }else{
          files.push(item);
        }
      }
    }
    setFolderItems(folders);
    setFileItems(files);
  };


  return (
    <GridViewStyle style={{height: gridHeight}}>
      <Box className="main_gridview" sx={{ flexGrow: 1 }}>
        {folderItems?.length > 0 &&
          <>
            <div style={{ color: "black", fontSize: "14px", fontWeight: 600, paddingLeft: "20px", paddingBottom: "12px", paddingTop: "12px" }}>
              Folder
            </div>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 15 }}>
              {folderItems?.map((val, index) => {
                return (
                  <Grid
                    key={val?.id} 
                   xs={2} sm={3} md={3}
                   style={{padding: '7px'}}
                    >
                  <div key={val?.id} className={isCheck.includes(val?.id) ? "folder-list-select" : "folder-list"} stlye={{ cursor: "poter" }}>
                    <div style={{ display: "flex", width: "170px" }}>
                      <div style={{ paddingRight: "12px" }}>
                        <Checkbox
                          className="checkbox-style"
                          name={val?.name}
                          id={val?.id}
                          key={val?.id}
                          style={{ padding: "0px" }}
                          checked={isCheck.includes(val?.id)}
                          onChange={(e) =>{
                            if(onRowSelectionChange){
                              onRowSelectionChange(val?.id, e.target.checked);
                            }
                          }}
                        />
                      </div>
                      <div style={{ overflow: 'hidden', cursor : 'pointer' , textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                        onClick={()=>{
                          if(onRowClickFunc){
                            onRowClickFunc({row : val});
                          }
                        }}>
                        <img style={{ paddingRight: "12px" }} src={folderIcon} alt="folderIcon" />
                        <span dangerouslySetInnerHTML={{ __html: val.name_mark ? val.name_mark : val.name }} />
                      </div>
                    </div>
                    <div style={{ cursor: "pointer" }}
                      onClick={(e) => handleClickMenu(e, { row: val })}>
                      <img src="/more sub nav.svg" alt="ddfd" />
                    </div>
                  </div>
                  </Grid>
                );
              })}
            </Grid>
          </>
        }
        {fileItems && fileItems.length > 0 &&
          <>
            {callFrom == "FilesComponent" &&
              <div style={{ color: "black", fontSize: "14px", fontWeight: 600, paddingLeft: "20px", paddingBottom: "12px", paddingTop: "12px" }}>Files</div>
            }
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 15 }} >
              {fileItems.length > 0 && fileItems?.map((val, index) => (
                val.type === 'file' &&
                    <Grid
                    key={val?.id} 
                   xs={2} sm={3} md={3}
                   style={{padding: '7px'}}
                    >
                    <Card key= {val?.id} className={isCheck.includes(val?.id) ? "cardColor" : callFrom == "FilesComponent" ? "cardColor-2" : "cardColor-1"}
                      sx={{height: callFrom == "FilesComponent" ? "179px" : "auto", opacity: "unset"}}>
                      <Box className={isCheck.includes(val?.id) ? "tr2" : callFrom == "FilesComponent" ? "on_hover_Class-2" : "on_hover_Class"}>
                        <div style={{ marginTop: "4px", marginLeft: "8px" }}>
                          <Checkbox
                            className="checkbox-style"
                            style={{ marginTop: "16px", marginLeft: "18px", color: "#929292", padding: "0px", backgroundColor: "white", opacity: "unset", cursor: 'pointer' }}
                            name={val?.name}
                            id={val?.id}
                            key={val?.id}
                            checked={isCheck.includes(val?.id)}
                            onChange={(e) =>{
                              if(onRowSelectionChange){
                                onRowSelectionChange(val?.id, e.target.checked);
                              }
                            }}
                          />
                        </div>
                      </Box>
                      <div
                        onClick={() => {
                          if (onRowClickFunc) {
                            onRowClickFunc({ row: val });
                          }
                        }}
                        style={{ margin: "12px 12px 0px 12px", height: "126px" }}>
                        <img style={{ height: '100%', width: '100%' }} src={callFrom == "SheetsComponent" ? val.thumbnail ? val.thumbnail : (attachmentThumbnail && attachmentThumbnail[val.id] ? attachmentThumbnail[val.id] : "/thumbnail.png") : val?.iconPath} alt="thumbnail loading....." />
                      </div>
                      <CardContent  style={{padding: '12px', paddingBottom: '6px', cursor: 'pointer'}} onClick={()=>{
                              if(onRowClickFunc){
                                onRowClickFunc({row : val});
                              }
                            }}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                          <div style={{width: '80%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', color: callFrom == "FilesComponent" ? "#505050" : "black", fontWeight: 600 }}> 
                            {callFrom == "FilesComponent" && <img style={{ paddingRight: "7px" }} src={val?.iconPath} alt="" />}
                            <span style={{cursor: "pointer"}} dangerouslySetInnerHTML={{ __html: val.name_mark ? val.name_mark : val.name }}/>
                          </div>
                          <div>
                            <div
                              onClick={(e) => handleClickMenu(e, { row: val })}
                              size="small"
                              sx={{ ml: 2 }}
                              aria-controls={openMenu ? "account-menu" : undefined}
                              aria-haspopup="true"
                              aria-expanded={openMenu ? "true" : undefined}
                            >
                              <img src="/more sub nav.svg" alt="ddfd" />
                            </div>
                          </div>
                        </div>
                        {callFrom == "SheetsComponent" &&
                          <div style={{color: "#505050", whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
                            <span dangerouslySetInnerHTML={{ __html: val.title_mark ? val.title_mark : val.title }} />
                          </div>
                        }
                      </CardContent>
                      {callFrom == "SheetsComponent" &&
                        <CardActions style={{ display: 'flex', justifyContent: 'space-between', padding: '12px', paddingTop: "0px"}}>
                          {val?.type === "file" ? (
                            <>
                              <div className="version">
                                <div className="version-1 body-small">
                                  Version {val.version}
                                </div>
                              </div>
                              <div style={{display: 'flex', gap: '8px'}}>
                                <Tooltip
                                  title="3 Markups"
                                  placement="bottom"
                                  PopperProps={{ className: "version-action-tooltip" }}
                                  arrow
                                >
                                  <div
                                  >
                                    <img src='/markup.svg' alt="download" />
                                  </div>
                                </Tooltip>
                                <Tooltip
                                  title="3 Issues"
                                  placement="bottom"
                                  PopperProps={{ className: "version-action-tooltip" }}
                                  arrow
                                >
                                  <div
                                  >
                                    <img src='/error.svg' alt="download" />
                                  </div>
                                </Tooltip>
                              </div>
                            </>
                          ) : (
                            <span></span>
                          )}
                        </CardActions>
                      }
                    </Card>
                    </Grid>
              )
              )}
            </Grid>
          </>
        }
      </Box>
    </GridViewStyle>
  );
}

export default GridViewComponent;